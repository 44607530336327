import moment from "moment";

export const columns = ({
  dispatch,
  downloadCognitiveDataStart,
  pagenumber,
  setDownloadAssementModal,
  setCognitiveId
}) => {
  const columnValues = [
    {
      name: "SL No.",
      selector: (row, index) =>
        pagenumber === 1
          ? index + 1
          : index === 9
          ? `${pagenumber}0`
          : `${pagenumber - 1}${index + 1}`,
      width: "80px",
    },
    // {
    //   name: "ID",
    //   selector: (row) => row.id,
    //   width: "80px",
    // },
    {
      name: "Assessment Name",
      selector: (row) => row.name,
      center: true,
      width: "250px",
    },
    {
      name: "Domain Name",
      selector: (row) => row.domain_name,
      center: true,
      width: "150px",
    },
    {
      name: "Group",
      selector: (row) =>
        row.group_name === 0
          ? "All"
          : row.group_name === 1
          ? "3-8"
          : row.group_name === 2
          ? "9-12"
          : " ",
      center: true,
      width: "80px",
    },
    {
      name: "Number of Students attempted",
      selector: (row) => row.student_attempt,
      center: true,
      width: "250px",
    },
    {
      name: "No, of students completed assessment",
      selector: (row) => row.student_completed,
      center: true,
      width: "250px",
    },
    {
      name: "Date Uploaded",
      selector: (row) => moment(row.date_uploaded).format("MM-DD-YYYY"),
      center: true,
    },
    {
      name: "Download Data",
      selector: (row) => {
        return (
          <>
            <button
              className="border-0 py-2 px-5 bg-opacity-10 table-btn"
              style={{ backgroundColor: "#607f962e", borderRadius: "50px" }}
              // onClick={() => dispatch(downloadCognitiveDataStart(row.id))}
              onClick={()=>{
                setDownloadAssementModal(true);
                setCognitiveId(row.id)
              }}
            >
              Download
            </button>
          </>
        );
      },
      center: true,
    },
  ];

  return columnValues;
};
