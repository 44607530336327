import React from "react";
import InputError from "../InputError/InputError";
import { Form } from "react-bootstrap";

const InputGroup = ({
  label,
  type,
  name,
  placeholder,
  onChange,
  error,
  ref,
  ...rest
}) => {
  return (
    <Form.Group className="my-5">
      <Form.Label className="font-size-3 mb-1 font-weight-bold text-black flex-grow-1" ref={ref}>
        {label}
      </Form.Label>
      <Form.Control
        {...rest}
        className="bg-light border rounded h-auto pb-2 pt-2 font-size-3 text-black"
        type={type}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
      />
      <InputError error={error} />
    </Form.Group>
  );
};

export default InputGroup;
