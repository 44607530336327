import React from "react";
import DataTable from "react-data-table-component";
import CustomLoader from "../CustomLoader/index";
import { AiOutlineSortAscending } from "react-icons/ai";

const CustomTable = ({ columns, data, loader }) => {
  const customStyles = {
    headCells: {
      style: {
        background: "#F2F5F7",
      },
    },
  };

  return (
    <>
      <DataTable
        className="rounded-0"
        responsive
        columns={columns}
        data={data}
        customStyles={customStyles}
        progressPending={loader}
        progressComponent={<CustomLoader />}
        persistTableHead={true}
        fixedHeader={true}
        sortIcon={<AiOutlineSortAscending />}
      />
    </>
  );
};

export default React.memo(CustomTable);
