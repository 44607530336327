import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { STUDENT_UPLOAD_EXCEL_START } from "../../actions";
import { studentUploadSuccess, UploadError } from "./action";
import { API_PATH_LOCAL } from "../../../utils/apisPath";

// GET ALL ADMIN USERS
const uploadStudentAsync = async (payload) =>
  await axios
    .post(API_PATH_LOCAL + `/uploadExcel`, payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data.error;
    });

function* fetchUploadStudentDetails(payload) {
  try {
    const users = yield call(uploadStudentAsync, payload.payload);
    if (users.code === 200) {
      yield put(studentUploadSuccess(users));
    } else {
      yield put(
        UploadError(
          users?.message?.length > 20 ? "Something Went Wrong" : users.message
        )
      );
    }
  } catch (error) {
    yield put(UploadError("Something Went Wrong"));
  }
}

export function* uploadStudentDetails() {
  yield takeEvery(STUDENT_UPLOAD_EXCEL_START, fetchUploadStudentDetails);
}
export default function* rootSaga() {
  yield all([fork(uploadStudentDetails)]);
}
