import {
  LOGIN_ADMIN_USER_START,
  LOGIN_ADMIN_USER_SUCCESS,
  LOGIN_ADMIN_USER_ERROR,
  INDIVIDUAL_ADMIN_DETAILS,
  INDIVIDUAL_ADMIN_DETAILS_SUCCESS,
  ADMIN_LOGOUT,
} from "../actions";

export const adminLoginStart = (data) => ({
  type: LOGIN_ADMIN_USER_START,
  payload: data,
});

export const adminLoginSuccess = (data) => ({
  type: LOGIN_ADMIN_USER_SUCCESS,
  payload: data,
});

export const getIndividualAdminAction = (data) => ({
  type: INDIVIDUAL_ADMIN_DETAILS,
  payload: data,
});

export const getIndividualAdminSuccess = (data) => ({
  type: INDIVIDUAL_ADMIN_DETAILS_SUCCESS,
  payload: data,
});

export const adminLoginError = (error) => ({
  type: LOGIN_ADMIN_USER_ERROR,
  payload: error,
});

export const adminLogout = (data) => ({
  type: ADMIN_LOGOUT,
  payload: data,
});
