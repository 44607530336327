export const _dashboard_details = [
  {
    id: 1,
    title: "Cognitive Assessments",
    numAndText: [
      {
        num: "06",
        text: "Total Domains",
      },
      {
        num: "556",
        text: "Users attempted assignment once",
      },
      {
        num: "7,088",
        text: "Users attempted assignments regularly",
      },
      {
        num: "7,088",
        text: "User before training ",
      },
      {
        num: "7,088",
        text: "Users attempted assignments after training",
      },
    ],
  },
  {
    id: 2,
    title: "Academic Assessments",
    numAndText: [
      {
        num: "34",
        text: "Total Assessments",
      },
      {
        num: "556",
        text: "Users attempted assignment once",
      },
      {
        num: "7,088",
        text: "Users attempted assignments regularly",
      },
      {
        num: "7,088",
        text: "Total Activity ",
      },
    ],
  },
  {
    id: 3,
    title: "Cognitive Training",
    numAndText: [
      {
        num: "34",
        text: "Total Training Modules  ",
      },
      {
        num: "556",
        text: "Users Completed Training",
      },
      {
        num: "7,088",
        text: "Users Training In Progress",
        percent: "56%",
      },
    ],
  },
];

//Badges In student
export const _badges_details = [
  "Badge Name 1",
  "Badge Name 2",
  "Badge Name 3",
  "Badge Name 4",
  "Badge Name 5",
  "Badge Name 6",
];
