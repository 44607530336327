import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { LOGIN_ADMIN_USER_START, INDIVIDUAL_ADMIN_DETAILS } from "../actions";
import {
  adminLoginSuccess,
  getIndividualAdminSuccess,
  adminLoginError,
} from "./actions";
import { API_PATH_LOCAL } from "../../utils/apisPath";

// LOGIN ADMIN USER
const adminLoginAsync = async (payload) =>
  await axios
    .post(API_PATH_LOCAL + `/admin-login`, payload.payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data.error;
    });

function* fetchAddAdminDetails(payload) {
  try {
    const loginuser = yield call(adminLoginAsync, payload);
    yield put(adminLoginSuccess(loginuser));
  } catch (error) {
    yield put(adminLoginError(error));
  }
}

// INDIVIDUAL ADMIN USER
const getIndivAdminDetailsAsync = async (payload) =>
  await axios
    .get(API_PATH_LOCAL + `/admin-users/${payload.id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data.error;
    });

function* fetchIndivAdminDetails({ payload }) {
  try {
    const admin = yield call(getIndivAdminDetailsAsync, payload);
    yield put(getIndividualAdminSuccess(admin));
  } catch (error) {
    yield put(getAdminError(error));
  }
}

export function* loginAdminDetails() {
  yield takeEvery(LOGIN_ADMIN_USER_START, fetchAddAdminDetails);
}

export function* IndivAdminDetails() {
  yield takeEvery(INDIVIDUAL_ADMIN_DETAILS, fetchIndivAdminDetails);
}

export default function* rootSaga() {
  yield all([fork(loginAdminDetails)]);
  yield all([fork(IndivAdminDetails)]);
}
