import React from "react";

const CounterCard = ({ cardDetails }) => {
  return (
    <>
      <div className="counter-card-div d-flex align-items-center justify-content-between">
        <div className="position-relative">
          <div
            className="card-small-circle d-flex align-items-center justify-content-center"
            style={{ background: `${cardDetails.bgColor}` }}
          ></div>
          <div className="icon-main">
            <img src={cardDetails.icon} alt="" />
          </div>
        </div>

        <div className="d-flex flex-column ml-2 w-70">
          <div className="counter-number">{cardDetails.count}</div>
          <div className="counter-card-text">{cardDetails.text}</div>
        </div>
      </div>
    </>
  );
};

export default React.memo(CounterCard);
