import IconBarChart from "../assets/Image/CognitiveAssessments/icon-bar-chart.svg";
import IconGreenFeather from "../assets/Image/CognitiveAssessments/icon-green-feather.svg";
import IconRedFeather from "../assets/Image/CognitiveAssessments/icon-red-feather.svg";

export const USER_DEPARTMENT = [
  { value: "SuperAdmin", label: "Super Admin" },
  { value: "HelpDesk", label: "Help Desk" },
  { value: "Scientist", label: "Scientist" },
];

export const USER_STATUS = [
  { value: "1", label: "Active" },
  { value: "0", label: "InActive" },
];

export const CONG_ASS_DATA = (params) => [
  {
    text: "Total Domains",
    icon: ` ${IconBarChart}`,
    count: params.total_domain,
    bgColor: "#2F49D1",
  },
  {
    text: "Total Assessments",
    icon: ` ${IconBarChart}`,
    count: params.total_assessments,
    bgColor: "#2F49D1",
  },
  {
    text: "Users before training",
    icon: ` ${IconGreenFeather}`,
    count: "N/A",

    bgColor: "#4BDE97",
  },
  {
    text: "Users after training",
    icon: ` ${IconRedFeather}`,
    count: "N/A",

    bgColor: "#C4205C",
  },
];

export const COUNTER_CARD_DATA = [
  {
    id: 1,
    count: "06",
    text: "Total Domains",
    icon: ` ${IconBarChart}`,
    bgColor: "#2F49D1",
  },
  {
    id: 1,
    count: "33",
    text: "Total Assessments",
    icon: ` ${IconBarChart}`,
    bgColor: "#2F49D1",
  },
  {
    id: 2,
    count: "3,445",
    text: "Users before training",
    icon: ` ${IconGreenFeather}`,
    bgColor: "#4BDE97",
  },
  {
    id: 3,
    count: "34,445,345",
    text: "Users after training",
    icon: ` ${IconRedFeather}`,
    bgColor: "#C4205C",
  },
];