import {
    STUDENT_RESET_PASSWORD_START,
    STUDENT_RESET_PASSWORD_SUCCESS,
    STUDENT_RESET_PASSWORD_ERROR,
    STUDENT_RESET_PASSWORD_EMPTY
} from "../actions";

export const sendStudentPwdMailAction = (data) => ({
    type: STUDENT_RESET_PASSWORD_START,
    payload: data,
});

export const sendStudentPwdMailSuccess = (data) => ({
    type: STUDENT_RESET_PASSWORD_SUCCESS,
    payload: data,
});

export const sendStudentPwdMailError = (message) => ({
    type: STUDENT_RESET_PASSWORD_ERROR,
    payload: message,
});

export const studentResetPasswordEmpty = (data) => ({
    type: STUDENT_RESET_PASSWORD_EMPTY,
    payload: data,
});