import {
  ADMIN_USER,
  ADMIN_USER_SUCCESS,
  ADD_ADMIN_USER,
  ADD_ADMIN_USER_SUCCESS,
  EDIT_ADMIN_USER,
  EDIT_ADMIN_USER_SUCCESS,
  ADMIN_USER_ERROR,
  ADMIN_EMPTY,
  CREATE_NEW_PASSWORD_START,
  CREATE_NEW_PASSWORD_SUCCESS,
} from "../actions";

// GET ADMIN USERS
export const getAdminAction = (data) => ({
  type: ADMIN_USER,
  payload: data,
});
export const getAdminSuccess = (data) => ({
  type: ADMIN_USER_SUCCESS,
  payload: data,
});

// ADD ADMIN USERS
export const addAdminAction = (data) => ({
  type: ADD_ADMIN_USER,
  payload: data,
});
export const addAdminSuccess = (data) => ({
  type: ADD_ADMIN_USER_SUCCESS,
  payload: data,
});

// EDIT ADMIN USERS
export const editAdminAction = (data) => ({
  type: EDIT_ADMIN_USER,
  payload: data,
});
export const editAdminSuccess = (data) => ({
  type: EDIT_ADMIN_USER_SUCCESS,
  payload: data,
});

// CREATE PASSWORD
export const createAdminPassStart = (data) => ({
  type: CREATE_NEW_PASSWORD_START,
  payload: data,
});

export const createAdminPassSuccess = (data) => ({
  type: CREATE_NEW_PASSWORD_SUCCESS,
  payload: data,
});

// ALL ADMIN ERROR
export const AdminErrorAction = (message) => ({
  type: ADMIN_USER_ERROR,
  payload: message,
});

// EMPTY ADMIN
export const adminEmptySuccess = () => ({
  type: ADMIN_EMPTY,
  payload: [],
});
