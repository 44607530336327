import {
  GET_SCHOOL_SUBADMIN_START,
  GET_SCHOOL_SUBADMIN_SUCCESS,
  ADD_SCHOOL_SUBADMIN_START,
  ADD_SCHOOL_SUBADMIN_SUCCESS,
  EDIT_SCHOOL_SUBADMIN_START,
  EDIT_SCHOOL_SUBADMIN_SUCCESS,
  SCHOOLS_SUBADMIN_ERROR,
  SCHOOL_SUBADMIN_EMPTY,
} from "../actions";

const INIT_STATE = {
  subadminerror: "",
  subadminloader: false,
  subadmin: [],
  add_subadmin: [],
  edit_subadmin: [],
};

const schoolAdminSession = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SCHOOL_SUBADMIN_START:
    case ADD_SCHOOL_SUBADMIN_START:
    case EDIT_SCHOOL_SUBADMIN_START:
      return {
        ...state,
        subadminloader: true,
      };
    case GET_SCHOOL_SUBADMIN_SUCCESS:
      return {
        ...state,
        subadminloader: false,
        subadmin: action.payload,
      };
    case ADD_SCHOOL_SUBADMIN_SUCCESS:
      return {
        ...state,
        subadminloader: false,
        add_subadmin: action.payload,
      };
    case EDIT_SCHOOL_SUBADMIN_SUCCESS:
      return {
        ...state,
        subadminloader: false,
        edit_subadmin: action.payload,
      };
    case SCHOOLS_SUBADMIN_ERROR:
      return {
        ...state,
        subadminloader: false,
        subadminerror: action.payload,
      };
    case SCHOOL_SUBADMIN_EMPTY:
      return {
        subadminerror: "",
        subadminsubadminloader: false,
        add_subadmin: [],
        edit_subadmin: [],
      };
    default:
      return { ...state };
  }
};

export default schoolAdminSession;
