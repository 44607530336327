import Home from "../../assets/Image/Sidebar/Home.svg";
import Document from "../../assets/Image/Sidebar/Document.svg";
import OneUser from "../../assets/Image/Sidebar/OneUser.svg";
import Paper from "../../assets/Image/Sidebar/Paper.svg";
import Subtract from "../../assets/Image/Sidebar/Subtract.svg";
import Category from "../../assets/Image/Sidebar/Category.svg";
import ThreeUser from "../../assets/Image/Sidebar/ThreeUser.svg";

export const linkdashboard = {
  Dashboard: "/app",
  "Cognitive Assessments": "/app/cognitive-assessments",
  "Academic Assessments": "/app/academic-assessments",
  "Cognitive Training": "/app/cognitive-training",
  "Manage Sub-Admins": "/app/manage-sub-admins",
  Schools: "/app/schools",
  // "User Stats": "/app/use-stats",
  Students: "/app/students",
  "School Sub-admins": "/app/school-sub-admins",
  "School Dashboard": "/app/school-dashboard",
  "Manage Students": "/app/students",
  "School SubAdmin": "/app/school-subadmins",
};

export const dynamicIcon = {
  Dashboard: Home,
  "Cognitive Assessments": Document,
  "Academic Assessments": Paper,
  "Cognitive Training": Category,
  "Manage Sub-Admins": ThreeUser,
  Schools: Subtract,
  // "User Stats": OneUser,
  Students: OneUser,
  "School Sub-admins": OneUser,
  "School Dashboard": OneUser,
  "Manage Students": OneUser,
  "School SubAdmin": OneUser,
};

export const RolesMenus = {
  SuperAdmin: [
    "Dashboard",
    "Cognitive Assessments",
    "Academic Assessments",
    "Cognitive Training",
    "Manage Sub-Admins",
    "Students",
    "Schools",
  ],
  HelpDesk: ["Cognitive Assessments", "Cognitive Training", "Students"],
  Scientist: ["Cognitive Assessments", "Students"],
  School: ["School Dashboard", "Manage Students", "School SubAdmin"],
  SchoolAdmin: ["School Dashboard", "Manage Students"],
};
