import React from "react";

const Index = ({ className, handleClick, text, type, disabled }) => {
  return (
    <>
      <button
        className={className || ""}
        onClick={handleClick}
        type={type}
        disabled={disabled}
      >
        {text}
      </button>
    </>
  );
};

export default React.memo(Index);
