import * as yup from "yup";

export const schoolInitialValue = {
  school_name: "",
  school_code: "",
  password: "",
  board: "",
  city: "",
  state: "",
  email: "",
  phone: "",
};

// SCHOOL VALIDATION
export const schoolValidationSchema = yup.object({
  school_name: yup.string().required("Field is required"),
  school_code: yup.number().required("Field is required"),
  board: yup.string().required("Field is required"),
  city: yup.string().required("Field is required"),
  state: yup.string().required("Field is required"),
  email: yup
    .string()
    .email("Invalid Email Address")
    .required("Field is required"),
  phone: yup
    .string()
    .required("Field is required")
    .min(10, "Must be Minimum 8 digits"),
  password: yup
    .string()
    .required("Field is required")
    .min(8, "Must be Minimum 8 characters"),
});

export const parseAddSchool = (values) => {
  return {
    name: values.school_name,
    school_code: Number(values.school_code),
    board: values.board,
    city: values.city,
    state: values.state,
    email: values.email,
    phone: Number(values.phone),
    password: values.password,
  };
};
export const parseUpdateSchool = (values, data) => {
  return {
    id: data.id,
    data: {
      name: values.school_name,
      school_code: Number(values.school_code),
      board: values.board,
      city: values.city,
      state: values.state,
      email: values.email,
      phone: Number(values.phone),
    },
  };
};
