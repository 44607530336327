import React, { useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { studentFeedbackUpdateStart } from "../../redux/actions";
import { feedback_validationSchema } from "../../utils/validationSchema";
import Cut from "../../assets/Image/ManageSubAdmins/cut.svg";

const Feedback = ({ loader, hide, userid }) => {
  const dispatch = useDispatch();

  const initialValues = {
    feedback: "",
  };

  const onSubmit = (values) => {
    const payload = {
      userid: userid.userID,
      data: {
        feedback: values.feedback,
      },
    };
    dispatch(studentFeedbackUpdateStart(payload));
  };

  useEffect(() => {
    formik.setFieldValue("feedback", userid.userfeedback);
  }, [userid.userID]);

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema: feedback_validationSchema,
  });

  return (
    <>
      <div className="p-10 ">
        <div className="d-flex justify-content-end">
          <div onClick={hide} style={{ cursor: "pointer" }}>
            <img src={Cut} alt="close_icon" />
          </div>
        </div>

        <div
          className=" text-center font-weight-bolder"
          style={{ fontSize: "24px" }}
        >
          Student Feedback
        </div>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group className="my-5">
            <Form.Label className="font-size-3 mb-1 font-weight-bold text-black flex-grow-1">
              Feedback
            </Form.Label>
            <Form.Control
              as="textarea"
              className="bg-light border rounded h-auto pb-2 pt-2 font-size-3 text-black"
              name="feedback"
              placeholder="Feedback"
              rows={4}
              {...formik.getFieldProps("feedback")}
              onChange={formik.handleChange}
            />
            {formik.touched.feedback && formik.errors.feedback && (
              <small className="text-danger">{formik.errors.feedback}</small>
            )}
          </Form.Group>
          <Button
            className="bg-primary border-0 py-4 text-light fw-bolder rounded w-100 mt-7"
            type="submit"
          >
            {loader ? "Loading..." : "Add"}
          </Button>
        </Form>
      </div>
    </>
  );
};

export default Feedback;
