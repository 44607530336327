import moment from "moment";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { countProfileCompletion } from "../../utils/functions/commonFunctions";
import { EditIcon } from "../../components/Global/ImageGlobal";
import { Tooltip } from "react-bootstrap";
import { studentFeedbackUpdateStart } from "../../redux/actions";

export const entering = (e) => {
  e.children[0].style.borderTopColor = "#000";
  e.children[1].style.backgroundColor = "#607f96";
};

const tooltip = (
  <Tooltip id="tooltip" style={{ ".tooltip-arrow": "#f2f2f2" }}>
    <span style={{ padding: "5px" }}>
      You didn't have a permission to use this feature
    </span>
  </Tooltip>
);

export const columns = ({
  navigate,
  dispatch,
  sendStudentPwdMailAction,
  studentFeedback,
  downloadStudentDataStart,
  downloadTrainingDataStart,
  // markInActive,
  department,
  pagenumber,
  setDownloadAssementModal,
  setDownloadTrainingModal,
  setStudentUserId,
}) => {
  const columnValues = [
    {
      name: "SL No.",
      selector: (row, index) =>
        pagenumber === 1
          ? index + 1
          : index === 9
          ? `${pagenumber}0`
          : `${pagenumber - 1}${index + 1}`,
      width: "80px",
    },
    {
      name: "Student ID",
      selector: (row) => row.id,
      width: "100px",
    },
    {
      name: "Student Name",
      cell: (row) => {
        return (
          <button
            className="border-0 py-2 px-7 bg-opacity-10 rounded-3 table-btn"
            style={{
              whiteSpace: "nowrap",
              backgroundColor: "#607f962e",
              borderRadius: "50px",
            }}
            onClick={() => navigate(`/app/students/${row.id}`)}
          >
            {row.name}
          </button>
        );
      },
      style: {
        cursor: "pointer",
      },
      center: true,
      width: "150px",
    },
    {
      name: "Phone Number",
      selector: (row) => row.phone,
      center: true,
      width: "150px",
    },
    {
      name: "Email-ID",
      selector: (row) => row.email,
      center: true,
      width: "300px",
    },
    {
      name: "Profile Completion %",
      selector: (row) => countProfileCompletion(row) + " " + "%",
      center: true,
      width: "200px",
    },
    {
      name: "Last Logged In",
      selector: (row) =>
        row.last_login_details
          ? moment(row.last_login_details).format("MM-DD-YYYY")
          : "N/A",
      center: true,
      width: "150px",
    },
    {
      name: "Generate Reset Password",
      cell: (row) => {
        return (
          <span
            style={{
              cursor: "pointer",
              backgroundColor: "#607f962e",
              borderRadius: "50px",
            }}
            onClick={() => {
              dispatch(sendStudentPwdMailAction({ email: row.email }));
              // navigator.clipboard.writeText(
              //   `https://beta.cogmentor.com/resetpassword?userId=${row.id}`
              // );
            }}
            className="border-0 py-2 px-5 bg-opacity-10 table-btn"
          >
            Generate
          </span>
        );
      },
      center: true,
      width: "200px",
    },
    {
      name: "Feedback",
      selector: (row) => {
        return (
          <>
            <button
              className="border-0 py-2 px-5 bg-opacity-10 table-btn"
              style={{ backgroundColor: "#607f962e", borderRadius: "50px" }}
              onClick={() => studentFeedback(row)}
            >
              Feedback
            </button>
          </>
        );
      },
      center: true,
      width: "150px",
    },
    {
      name: "Download Data",
      selector: (row) => {
        return (
          <button
            className="border-0 py-2 px-5 bg-opacity-10 table-btn"
            style={{ backgroundColor: "#607f962e", borderRadius: "50px" }}
            // onClick={() => dispatch(downloadStudentDataStart(row.id))}
            onClick={() => {
              setDownloadAssementModal(true);
              setStudentUserId(row.id);
            }}
          >
            Download
          </button>
        );
      },
      center: true,
      width: "150px",
    },
    {
      name: "Download Training Data",
      selector: (row) => {
        return (
          <button
            className="border-0 py-2 px-5 bg-opacity-10 table-btn"
            style={{ backgroundColor: "#607f962e", borderRadius: "50px" }}
            // onClick={() => dispatch(downloadStudentDataStart(row.id))}
            onClick={() => {
              setDownloadTrainingModal(true);
              setStudentUserId(row.id);
            }}
          >
            Download
          </button>
        );
      },
      center: true,
      width: "150px",
    },
    {
      name: "Status",
      selector: (row) => {
        return (
          <>
            {department === 1 || department === 2 ? (
              <button
                className="border-0 py-2 px-5 bg-opacity-10 table-btn"
                style={{ backgroundColor: "#607f962e", borderRadius: "50px" }}
                onClick={() => {
                  const payload = {
                    userid: row.id,
                    data: {
                      status: row.status === 1 ? 0 : 1,
                    },
                  };
                  dispatch(studentFeedbackUpdateStart(payload));
                }}
              >
                {row.status === 1 ? "Active" : "Inactive"}
              </button>
            ) : (
              <OverlayTrigger
                className="d-flex flex-column align-items-center justify-content-center h-100"
                placement={"bottom"}
                overlay={tooltip}
                onEntering={entering}
              >
                <button
                  className="border-0 py-2 px-5 bg-opacity-10"
                  style={{ backgroundColor: "#607f962e", borderRadius: "50px" }}
                >
                  {row.status === 1 ? "Active" : "Inactive"}
                </button>
              </OverlayTrigger>
            )}
          </>
        );
      },
      center: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <div className="d-flex align-items-center">
            {department === 1 || department === 2 ? (
              <div
                className="mr-5 custom-click"
                onClick={() => navigate(`/app/add-new-student/${row.id}`)}
              >
                <img src={EditIcon} alt="" />
              </div>
            ) : (
              <OverlayTrigger
                className="d-flex flex-column align-items-center justify-content-center h-100"
                placement={"bottom"}
                overlay={tooltip}
                onEntering={entering}
              >
                <div className="mr-5 custom-click">
                  <img src={EditIcon} alt="" />
                </div>
              </OverlayTrigger>
            )}
            {/* {department === 1 ? (
              <div className="custom-click">
                <img
                  src={DeleteIcon}
                  alt=""
                  onClick={() => {
                    // setStudentDeleteID(row.id);
                    // setDeleteModalOpen(true);
                    markInActive(row);
                  }}
                />
               
              </div>
            ) : (
              <OverlayTrigger
                className="d-flex flex-column align-items-center justify-content-center h-100"
                placement={"bottom"}
                overlay={tooltip}
                onEntering={entering}
              >
                <div className="custom-click">
                  <img src={DeleteIcon} alt="" />
                </div>
              </OverlayTrigger>
            )} */}
          </div>
        </>
      ),
      center: true,
    },
  ];

  return columnValues;
};
