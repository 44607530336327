import React from "react";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BackArrow from "../../../assets/Image/CognitiveAssessments/AddNewActivity/BackArrow.svg";

function AddNewActivity() {
  const navigate = useNavigate();
  return (
    <div className="AddNewActivity-main-div p-7 ">
      <div className="back-icon ">
        <img
          src={BackArrow}
          alt=""
          onClick={() => navigate("/app/cognitive-assessments")}
          className="custom-click"
        />
      </div>

      <div className="d-flex justify-content-between align-items-center mr-15 mb-7">
        <div>
          <div className="new-activity-text">New Activity</div>
        </div>

        <div>
          <button className="first-button mr-7">Cancel</button>
          <button className="second-button">Save</button>
        </div>
      </div>

      {/* -----------Edit Details card------------- */}
      <div className="edit-details-card mr-15 mb-15">
        <div className="title font-weight-bolder">Edit Details</div>
        <Row className="mt-5 justify-content-center">
          <Col lg={6} xl={4} className="mt-7 mt-xl-0">
            <Form.Group>
              <div className="d-flex align-items-center">
                <Form.Label className="font-size-3 mb-1 font-weight-bold text-black flex-grow-1">
                  Title
                </Form.Label>
              </div>
              <Form.Control
                required
                className="bg-light border rounded h-auto pb-2 pt-2 font-size-3 text-black"
                type="text"
                placeholder="Write Here..."
              />
            </Form.Group>
            <Form.Group className="mt-5 ">
              <div className="d-flex align-items-center">
                <Form.Label className="font-size-3 mb-1 font-weight-bold text-black flex-grow-1">
                  Description
                </Form.Label>
              </div>
              <Form.Control
                as="textarea"
                required
                className="bg-light border rounded  pb-2 pt-2 font-size-3 text-black"
                rows={7}
                placeholder="Write Here..."
              />
            </Form.Group>
          </Col>
          <Col lg={6} xl={4} className=" mt-7 mt-xl-0">
            <Form.Group>
              <div className="d-flex align-items-center">
                <Form.Label className="font-size-3 mb-1 font-weight-bold text-black flex-grow-1">
                  Age Group
                </Form.Label>
              </div>
              <Form.Control
                required
                className="bg-light border rounded h-auto pb-2 pt-2 font-size-3 text-black"
                type="text"
                placeholder="Write Here..."
              />
            </Form.Group>
            <Form.Group className="mt-5 ">
              <div className="d-flex align-items-center">
                <Form.Label className="font-size-3 mb-1 font-weight-bold text-black flex-grow-1">
                  Baseline Value
                </Form.Label>
              </div>
              <Form.Control
                required
                className="bg-light border rounded h-auto pb-2 pt-2 font-size-3 text-black"
                type="text"
                placeholder="Write Here..."
              />
            </Form.Group>
            <Form.Group className="mt-5 ">
              <div className="d-flex align-items-center">
                <Form.Label className="font-size-3 mb-1 font-weight-bold text-black flex-grow-1">
                  Seek Approval
                </Form.Label>
              </div>
              <Form.Control
                as="select"
                className="rounded bg-light border h-auto pb-4 pt-4 font-size-3 pr-2"
              >
                <option value="general" selected disabled>
                  Select Sub-admin
                </option>
                <option value="general">Sub-admin</option>
                <option value="obc"> Sub-admin</option>
              </Form.Control>
            </Form.Group>
            <Form.Group className="mt-5 ">
              <div className="d-flex align-items-center">
                <Form.Label className="font-size-3 mb-1 font-weight-bold text-black flex-grow-1">
                  Uploaded By
                </Form.Label>
              </div>
              <Form.Control
                as="select"
                className="rounded bg-light border h-auto pb-4 pt-4 font-size-3 pr-2"
              >
                <option value="general" selected disabled>
                  Select Sub-admin
                </option>
                <option value="general">Sub-admin</option>
                <option value="obc"> Sub-admin</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col lg={6} xl={4} className="text-center mt-7 mt-xl-0">
            1 1
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default AddNewActivity;
