import { combineReducers } from "redux";
import cognitiveAssesSession from "./cognitive-assessments/reducer";
import adminDetailSession from "./admins/reducers";
import adminLoginSession from "./login/reducers";
import studentDetailSession from "./studentDetails/reducer";
import studentResetPasswordSession from "./studentResetPassword/reducers";
import schoolSessions from "./School/reducers";
import schoolAdminSession from "./SchoolSubAdmin/reducer";
import uploadDetailSession from "./UploadViaExcel/StudentUpload/reducer";
import commonSession from "./common/reducer"; // COMMON PASSWORD GENERATOR
import badgesSession from "./badges/reducers";

const reducers = combineReducers({
  cognitiveAssesSession,
  adminDetailSession,
  adminLoginSession,
  studentDetailSession,
  studentResetPasswordSession,
  schoolSessions,
  schoolAdminSession,
  uploadDetailSession,
  commonSession,
  badgesSession,
});

export default reducers;
