import React, { useEffect, useState, lazy, useRef } from "react";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import {
  addStudentStart,
  getStudentByIdStart,
  studentEmpty,
  studentUpdateStart,
} from "../../../redux/studentDetails/actions";
import { LoaderSpin, BackArrow } from "../../../components/Global/ImageGlobal";
import { addStudentValidationSchema } from "./utils";
import {
  initialValues,
  _class_values,
  _board_values,
  _student_category,
  _core_subjects,
  _study_medium,
  _relationship,
  _parent_gender,
  _native_language,
  _mentor_type,
} from "./constant";
import { options } from "../../../utils/functions/commonFunctions";
import { emptyParentObject } from "../../../utils/AddStudent/EmptyParent";
import CustomButton from "../../../components/Common/CustomButton";
import { Capitalize } from "../../../utils/Capatialize";
import { v4 as uuidv4 } from "uuid";
import { InputTextGroup } from "../../../components/Students";
import { isSchoolCode } from "../../../utils/isLogin";

export default function AddNewStudent() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const schoolCode = isSchoolCode();

  const [userGender, setUserGender] = useState("");
  const [text, setText] = useState({
    save: "Save",
    title: "Add a new student",
  });
  const [studentDetail, setStudentDetail] = useState([]);
  // const [FieldRequireError, setFieldRequireError] = useState("");

  const { studentLoader, studentById, new_users, studentUpdate, studenterror } =
    useSelector((state) => state.studentDetailSession);

  // NEW VARIANT PARENT
  const [collectParent, setCollectParent] = useState([]); // COLLECT ALL PARENT DATA
  const [ParentButton, setParentButton] = useState(false);
  const [EditParentID, setEditParentID] = useState({ id: "", name: "" });
  const [parentvalue, setParentvalue] = useState({
    id: "",
    designation: "",
    email: "",
    employment_category: "",
    gender: "",
    income: "",
    name: "",
    nativeLanguage: "",
    organization: "",
    phone: "",
    profession: "",
    qualification: "",
    relationship: "",
  }); // GET VALUE FROM INPUTS

  // GET VALUE FROM INPUT
  const handleChangeValue = (e) => {
    setParentvalue({
      ...parentvalue,
      [e.target.name]: e.target.value,
    });
  };

  // ADD PARENT TO ARRAY ( COLLECTPARENT )
  const AddParent = () => {
    if (
      parentvalue.name !== "" &&
      parentvalue.email !== "" &&
      parentvalue.gender !== "" &&
      parentvalue.phone !== "" &&
      parentvalue.relationship !== ""
    ) {
      setCollectParent((current) => [
        ...current,
        {
          extras: {
            designation: parentvalue.designation,
            employment_category: parentvalue.employment_category,
            income: parentvalue.income,
            nativeLanguage: parentvalue.nativeLanguage,
            organization: parentvalue.organization,
            profession: parentvalue.profession,
            qualification: parentvalue.qualification,
          },
          email: parentvalue.email,
          gender: parentvalue.gender,
          name: parentvalue.name,
          phone: parentvalue.phone,
          relationship: parentvalue.relationship,
          id: uuidv4(),
        },
      ]);
      setParentvalue(emptyParentObject);
      formik.setFieldValue("name", "");
      toast("Parent Successfully Added", options);
    } else {
      toast("Please fill required fields", options);
    }
  };

  // EDIT PARENT TO ARRAY ( COLLECTPARENT )
  const EditParent = (data) => {
    setEditParentID({
      id: data.id,
      name: data.name,
    });
    setParentButton(true);
    setParentvalue({
      designation: data.extras.designation,
      email: data.email,
      employment_category: data.extras.employment_category,
      gender: data.gender,
      income: data.extras.income,
      name: data.name,
      nativeLanguage: data.extras.nativeLanguage,
      organization: data.extras.organization,
      phone: data.phone,
      profession: data.extras.profession,
      qualification: data.extras.qualification,
      relationship: data.relationship,
    });
    formik.setFieldValue("name", data.name);
    formik.setFieldValue("phone", data.phone);
    formik.setFieldValue("email", data.email);
    formik.setFieldValue("gender", data.gender);
    formik.setFieldValue("relationship", data.relationship);
  };

  // UPDATE PARENT TO ARRAY ( COLLECTPARENT )
  const UpdateParent = () => {
    const updatedData = collectParent.map((data) => {
      if (data.id === EditParentID.id) {
        toast("Parent Successfully Updated", options);
        return {
          extras: {
            designation: parentvalue.designation,
            employment_category: parentvalue.employment_category,
            income: parentvalue.income,
            nativeLanguage: parentvalue.nativeLanguage,
            organization: parentvalue.organization,
            profession: parentvalue.profession,
            qualification: parentvalue.qualification,
          },
          email: parentvalue.email,
          gender: parentvalue.gender,
          name: parentvalue.name,
          phone: parentvalue.phone,
          relationship: parentvalue.relationship,
          id: EditParentID.id,
        };
      }
      return data;
    });

    setCollectParent(updatedData);
    setParentButton(false);
    setParentvalue(emptyParentObject);
    formik.setFieldValue("name", "");
  };

  // DELETE PARENT TO ARRAY ( COLLECTPARENT )
  const DeleteParent = (id) => {
    setCollectParent((current) =>
      current.filter((obj) => {
        toast("Parent Successfully Deleted", options);
        return obj.id !== id;
      })
    );
  };

  const onSubmit = async (values) => {
    const parentData = [];

    await Promise.all(
      collectParent.map((res) => {
        parentData.push({
          id: res.id.length > 8 ? 0 : res.id,
          name: res.name,
          phone: res.phone,
          email: res.email,
          gender: res.gender,
          extras: JSON.stringify(res.extras),
          relationship: res.relationship,
        });
      })
    );

    // WHEN WE NEED TO CREATE A STUDENT
    const payload = {
      student_details: {
        name: values.student_name,
        phone: values.student_phone,
        email: values.student_email,
        gender: userGender,
        dob: `${values.student_dob}T06:12:59.541Z`,
        category: values.student_category,
        avatar:
          "https://noema.s3.ap-south-1.amazonaws.com/1653722159317-52.jpg",
        status: 1,
        facebook_id: "0",
      },
      school_details: JSON.stringify({
        school_name: values.school_name,
        board: values.school_board,
        school_code: values.school_code,
        class: values.school_class,
        section: values.school_section,
        core_subjects: values.school_core_subjects,
        study_medium: values.school_study_medium,
        city: values.school_city,
        state: values.school_state,
        pincode: values.school_pin_code,
        country: values.school_country,
      }),
      mentor_details: JSON.stringify({
        name: values.mentor_name,
        type: values.mentor_type,
        email: values.mentor_email,
        phone: values.mentor_phone,
      }),
      extra_details: {
        englishLanguageAge: "1",
        englishLanguageProficiency: "1",
        languageAge: "1",
        nativeLanguage: "hindi",
        nativeLanguageProficiency: "1",
        neurologicalImpairmenets: "tt",
        otherLanguage: "hindi",
        otherLanguageMedium: "hindi",
      },
      parent_details: parentData,
      isSchool: schoolCode ? true : false,
    };

    // WHEN WE NEED TO UPDATE A STUDENT
    const updatepayload = {
      student_details: {
        id: Number(id),
        name: values.student_name,
        phone: values.student_phone,
        email: values.student_email,
        gender: userGender,
        dob: `${values.student_dob}T06:12:59.541Z`,
        category: values.student_category,
      },
      school_details: JSON.stringify({
        school_name: values.school_name,
        board: values.school_board,
        school_code: values.school_code,
        class: values.school_class,
        section: values.school_section,
        core_subjects: values.school_core_subjects,
        study_medium: values.school_study_medium,
        city: values.school_city,
        state: values.school_state,
        pincode: values.school_pin_code,
        country: values.school_country,
      }),
      mentor_details: JSON.stringify({
        name: values.mentor_name,
        type: values.mentor_type,
        email: values.mentor_email,
        phone: values.mentor_phone,
      }),
      extra_details: studentDetail.extra_details,
      parent_details: parentData,
    };

    if (id) {
      const studentPayload = {
        id: id,
        data: updatepayload,
      }; // UPDATE ID WITH PAYLOAD
      dispatch(studentUpdateStart(studentPayload)); // UPDATE STUDENT
    } else {
      dispatch(addStudentStart(payload)); // ADD STUDENT
    }
  }; // FORM SUBMIT

  useEffect(() => {
    if (id) {
      dispatch(getStudentByIdStart(id));
      setText({
        save: "Update",
        title: "Update Student Details",
      });
    } else {
      setCollectParent([]);
      dispatch(studentEmpty());
    }
  }, []); // GET STUDENT BY ID AND CHANGE TEXT

  useEffect(() => {
    if (studentById) setStudentDetail(studentById);
  }, [studentById]); // SET STUDENT VALUE IN STATE;

  useEffect(() => {
    if (id) {
      if (studentDetail.parent_details?.length > 0) {
        setCollectParent(studentDetail.parent_details);
      } // CHECK LENGTH OF PARENT DETAILS AND SET IN STATE
      setInputValues();
    } // CHECK IF ID EXIST IN URL ( SET ALL VALUES IN FIELD )
  }, [studentDetail]);

  const setInputValues = () => {
    formik.setFieldValue(
      "student_name",
      studentDetail?.student_details?.name || ""
    );
    formik.setFieldValue(
      "student_phone",
      studentDetail?.student_details?.phone || ""
    );
    formik.setFieldValue(
      "student_email",
      studentDetail?.student_details?.email || ""
    );
    formik.setFieldValue(
      "student_gender",
      studentDetail?.student_details?.gender || ""
    );

    if (studentDetail?.student_details?.gender !== "") {
      const gender = Capitalize(studentDetail?.student_details?.gender);
      setUserGender(gender);
    }

    formik.setFieldValue(
      "student_dob",
      studentDetail?.student_details?.dob?.slice(0, 10)
    );
    formik.setFieldValue(
      "student_category",
      studentDetail?.student_details?.category || ""
    );

    formik.setFieldValue(
      "school_name",
      studentDetail?.school_details?.school_name || ""
    );
    formik.setFieldValue(
      "school_board",
      studentDetail?.school_details?.board || ""
    );
    formik.setFieldValue(
      "school_code",
      studentDetail?.school_details?.school_code || ""
    );
    formik.setFieldValue(
      "school_class",
      studentDetail?.school_details?.class || ""
    );
    formik.setFieldValue(
      "school_section",
      studentDetail?.school_details?.section || ""
    );
    formik.setFieldValue(
      "school_core_subjects",
      studentDetail?.school_details?.core_subjects || ""
    );
    formik.setFieldValue(
      "school_city",
      studentDetail?.school_details?.city || ""
    );
    formik.setFieldValue(
      "school_state",
      studentDetail?.school_details?.state || ""
    );
    formik.setFieldValue(
      "school_pin_code",
      studentDetail?.school_details?.pincode || ""
    );
    formik.setFieldValue(
      "school_country",
      studentDetail?.school_details?.country || ""
    );
    formik.setFieldValue(
      "school_study_medium",
      studentDetail?.school_details?.study_medium || ""
    );

    formik.setFieldValue(
      "mentor_name",
      studentDetail?.mentor_details?.name || ""
    );
    formik.setFieldValue(
      "mentor_type",
      studentDetail?.mentor_details?.type || ""
    );
    formik.setFieldValue(
      "mentor_email",
      studentDetail?.mentor_details?.email || ""
    );
    formik.setFieldValue(
      "mentor_phone",
      studentDetail?.mentor_details?.phone || ""
    );
  }; // SET VALUES IN FILED IF ID EXIST IN URL

  useEffect(() => {
    if (new_users.code === 200) {
      toast(new_users.message, options);
      dispatch(studentEmpty());
      navigate("/app/students");
    }
    if (studentUpdate.code === 200) {
      toast(studentUpdate.message, options);
      dispatch(studentEmpty());
      navigate("/app/students");
    }
    if (studenterror) {
      toast.error(studenterror);
      dispatch(studentEmpty());
    }
  }, [new_users, studentUpdate, studenterror]); // TOAST ALERT

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit,
    validationSchema: addStudentValidationSchema,
  }); // FORMIK

  // VALIDATION SCROLLING
  const student_name = useRef(null);
  const student_email = useRef(null);
  const student_gender = useRef(null);
  const student_dob = useRef(null);
  const student_phone = useRef(null);
  const student_category = useRef(null);

  const name = useRef(null);
  const phone = useRef(null);
  const email = useRef(null);
  const gender = useRef(null);
  const relationship = useRef(null);

  const executeScroll = (e) => {
    e.preventDefault();
    const key = Object.keys(formik.errors)[0];
    var keyvar = eval(key);
    keyvar.current.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  };

  return (
    <div className="p-7 AddNewStudent-main-div">
      <div className="back-icon ">
        <img
          src={BackArrow}
          alt=""
          onClick={() => navigate(-1)}
          className="custom-click"
        />
      </div>
      {studentLoader ? (
        <>
          <div className="d-flex justify-content-center">
            <img src={LoaderSpin} alt="loader" />
          </div>
        </>
      ) : (
        <>
          <form
            onSubmit={(e) => {
              formik.handleSubmit(e);
              executeScroll(e);
            }}
          >
            <div className="d-flex justify-content-between align-items-center mr-15 mb-15">
              <div>
                <p className="add-new-stu-text">{text.title}</p>
              </div>
              <div>
                <CustomButton
                  className="first-button mr-7"
                  type="reset"
                  handleClick={() => navigate(-1)}
                  text="Cancel"
                />
                <CustomButton
                  className="second-button"
                  type="submit"
                  text={text.save}
                />
              </div>
            </div>

            <div className="form-div">
              <Row>
                <Col xs={6}>
                  <h4 className=" font-weight-bold">Student Details</h4>
                  <Form.Group>
                    <div className="d-flex align-items-center">
                      <Form.Label
                        className="font-size-3 mb-1 font-weight-bold text-black flex-grow-1"
                        ref={student_name}
                      >
                        Name*
                      </Form.Label>
                    </div>
                    <Form.Control
                      className="bg-light border rounded h-auto pb-2 pt-2 font-size-3 text-black"
                      name="student_name"
                      type="text"
                      placeholder="Enter Here..."
                      {...formik.getFieldProps("student_name")}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.student_name &&
                      formik.errors.student_name && (
                        <small className="text-danger">
                          {formik.errors.student_name}
                        </small>
                      )}
                  </Form.Group>

                  <Form.Group className="position-relative my-5">
                    <Form.Label
                      className="font-size-3 mb-1 font-weight-bold text-black"
                      ref={student_email}
                    >
                      Email*
                    </Form.Label>
                    <Form.Control
                      className="bg-light border rounded h-auto pb-2 pt-2 font-size-3 text-black"
                      type="email"
                      name="student_email"
                      placeholder="Enter Here..."
                      {...formik.getFieldProps("student_email")}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.student_email &&
                      formik.errors.student_email && (
                        <small className="text-danger">
                          {formik.errors.student_email}
                        </small>
                      )}
                  </Form.Group>

                  <div>
                    <h6
                      className="font-size-3 mb-1 font-weight-bold text-black"
                      ref={student_gender}
                    >
                      Gender*
                    </h6>
                    <ToggleButtonGroup
                      name="student_gender"
                      type="radio"
                      className="w-100 bg-light border rounded toggle-button-group"
                      {...formik.getFieldProps("student_gender")}
                      onChange={(value) => {
                        setUserGender(value);
                        formik.setFieldValue("student_gender", value);
                      }}
                    >
                      <ToggleButton
                        value="Male"
                        id="malecheckbox"
                        htmlFor="malecheckbox"
                        className={`${
                          userGender === "Male"
                            ? "border-primary bg-blue-grey-10 text-primary border-width-2  "
                            : "bg-light text-gray border-0"
                        } font-weight-bold rounded font-size-3 mr-5 my-0`}
                      >
                        Male
                      </ToggleButton>
                      <ToggleButton
                        value="Female"
                        id="femalecheckbox"
                        htmlFor="femalecheckbox"
                        className={`${
                          userGender === "Female"
                            ? "border-primary bg-blue-grey-10 text-primary border-width-2"
                            : "bg-light text-gray border-0"
                        } font-weight-bold rounded font-size-3 mr-3 my-0`}
                      >
                        Female
                      </ToggleButton>
                      <ToggleButton
                        value="Others"
                        id="otherscheckbox"
                        htmlFor="otherscheckbox"
                        className={`${
                          userGender === "Others"
                            ? "border-primary bg-blue-grey-10 text-primary border-width-2"
                            : "bg-light text-gray border-0 "
                        } font-weight-bold rounded font-size-3 ml-2 my-0`}
                      >
                        Others
                      </ToggleButton>
                    </ToggleButtonGroup>
                    {formik.touched.student_gender &&
                      formik.errors.student_gender && (
                        <small className="text-danger">
                          {formik.errors.student_gender}
                        </small>
                      )}
                  </div>

                  <Form.Group className=" my-5">
                    <Form.Label
                      className="font-size-3 mb-1 font-weight-bold text-black"
                      ref={student_dob}
                    >
                      Date of Birth*
                    </Form.Label>
                    <Form.Control
                      className="bg-light border rounded h-auto pb-2 pt-2 font-size-3 text-black"
                      type="date"
                      name="student_dob"
                      placeholder="Enter Here..."
                      {...formik.getFieldProps("student_dob")}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.student_dob &&
                      formik.errors.student_dob && (
                        <small className="text-danger">
                          {formik.errors.student_dob}
                        </small>
                      )}
                  </Form.Group>

                  <Form.Group>
                    <Form.Label
                      className="font-size-3 mb-1 font-weight-bold text-black"
                      ref={student_phone}
                    >
                      Phone Number*
                    </Form.Label>
                    <Form.Group>
                      <div className="d-flex">
                        <Form.Control
                          as="select"
                          name="countryCode"
                          className="mr-4 rounded bg-light border w-auto pt-2 pb-2 h-auto"
                        >
                          <option defaultValue value="+91">
                            +91
                          </option>
                        </Form.Control>
                        <Form.Control
                          className="bg-light border rounded h-auto pb-2 pt-2 font-size-3 text-black"
                          pattern="[0-9]{10}"
                          type="tel"
                          name="student_phone"
                          placeholder="Enter Here..."
                          {...formik.getFieldProps("student_phone")}
                          onChange={formik.handleChange}
                        />
                      </div>
                      {formik.touched.student_phone &&
                        formik.errors.student_phone && (
                          <small className="text-danger">
                            {formik.errors.student_phone}
                          </small>
                        )}
                    </Form.Group>
                  </Form.Group>

                  <Form.Group className="my-5">
                    <div className="d-flex align-items-center">
                      <Form.Label
                        className="font-size-3 mb-1 font-weight-bold text-black flex-grow-1"
                        ref={student_category}
                      >
                        Category*
                      </Form.Label>
                    </div>
                    <Form.Control
                      as="select"
                      name="student_category"
                      className="rounded bg-light border h-auto pb-4 pt-4 font-size-3 pr-2"
                      {...formik.getFieldProps("student_category")}
                      onChange={formik.handleChange}
                    >
                      <option defaultValue value="">
                        Choose a category
                      </option>
                      {_student_category.map((ele, index) => {
                        return (
                          <option value={ele.value} key={index}>
                            {ele.lable}
                          </option>
                        );
                      })}
                    </Form.Control>
                    {formik.touched.student_category &&
                      formik.errors.student_category && (
                        <small className="text-danger">
                          {formik.errors.student_category}
                        </small>
                      )}
                  </Form.Group>
                </Col>
                <Col xs={6}>
                  <img
                    src="https://res.cloudinary.com/cogmentor/image/upload/v1635449735/ILLUSTRATION%20IMAGES/PROFILE%20PAGE/cogmentor_illustrations-03_1_unmvpw.png"
                    alt=""
                  />
                </Col>
              </Row>
            </div>

            <div className="form-div my-10">
              <Row>
                <Col xs={6}>
                  <h4 className=" font-weight-bold">School Details</h4>

                  <Form.Group>
                    <div className="d-flex align-items-center">
                      <Form.Label className="font-size-3 mb-1 font-weight-bold text-black flex-grow-1">
                        Name
                      </Form.Label>
                    </div>
                    <Form.Control
                      className="bg-light border rounded h-auto pb-2 pt-2 font-size-3 text-black"
                      type="text"
                      name="school_name"
                      placeholder="Enter Here..."
                      {...formik.getFieldProps("school_name")}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.school_name &&
                      formik.errors.school_name && (
                        <small className="text-danger">
                          {formik.errors.school_name}
                        </small>
                      )}
                  </Form.Group>

                  <div className="d-flex justify-content-between my-5">
                    <Form.Group className="w-100">
                      <Form.Label className="font-size-3 mb-1 font-weight-bold text-black flex-grow-1">
                        Board
                      </Form.Label>
                      <Form.Control
                        as="select"
                        className="rounded bg-light border h-auto pb-4 pt-4 font-size-3"
                        name="school_board"
                        {...formik.getFieldProps("school_board")}
                        onChange={formik.handleChange}
                      >
                        <option defaultValue value="">
                          Choose a board
                        </option>
                        {_board_values.map((ele, index) => {
                          return (
                            <option value={ele.value} key={index}>
                              {ele.lable}
                            </option>
                          );
                        })}
                      </Form.Control>
                      {formik.touched.school_board &&
                        formik.errors.school_board && (
                          <small className="text-danger">
                            {formik.errors.school_board}
                          </small>
                        )}
                    </Form.Group>

                    <Form.Group className="w-100 ml-2">
                      <Form.Label className="font-size-3 mb-1 font-weight-bold text-black flex-grow-1">
                        School Code
                      </Form.Label>
                      <Form.Control
                        className="bg-light border rounded h-auto pb-2 pt-2 font-size-3 text-black"
                        type="number"
                        placeholder="Enter Here..."
                        name="school_code"
                        {...formik.getFieldProps("school_code")}
                        onChange={formik.handleChange}
                      />
                      {formik.touched.school_code &&
                        formik.errors.school_code && (
                          <small className="text-danger">
                            {formik.errors.school_code}
                          </small>
                        )}
                    </Form.Group>
                  </div>

                  <div className="d-flex justify-content-between ">
                    <Form.Group className="w-100">
                      <Form.Label className="font-size-3 mb-1 font-weight-bold text-black flex-grow-1">
                        Class
                      </Form.Label>
                      <Form.Control
                        as="select"
                        className="rounded bg-light border h-auto pb-4 pt-4 font-size-3"
                        name="school_class"
                        {...formik.getFieldProps("school_class")}
                        onChange={formik.handleChange}
                      >
                        <option defaultValue value="">
                          Choose a class
                        </option>
                        {_class_values.map((ele, index) => {
                          return (
                            <option value={ele.value} key={index}>
                              {ele.lable}
                            </option>
                          );
                        })}
                      </Form.Control>
                      {formik.touched.school_class &&
                        formik.errors.school_class && (
                          <small className="text-danger">
                            {formik.errors.school_class}
                          </small>
                        )}
                    </Form.Group>

                    <Form.Group className="w-100 ml-2">
                      <Form.Label className="font-size-3 mb-1 font-weight-bold text-black flex-grow-1">
                        Section
                      </Form.Label>
                      <Form.Control
                        className="bg-light border rounded h-auto pb-2 pt-2 font-size-3 text-black"
                        type="text"
                        name="school_section"
                        placeholder="Enter Here..."
                        {...formik.getFieldProps("school_section")}
                        onChange={formik.handleChange}
                      />
                      {formik.touched.school_section &&
                        formik.errors.school_section && (
                          <small className="text-danger">
                            {formik.errors.school_section}
                          </small>
                        )}
                    </Form.Group>
                  </div>

                  <Form.Group>
                    <div className="my-5">
                      <Form.Label className="font-size-3 mb-1 font-weight-bold text-black flex-grow-1">
                        Core Subjects
                      </Form.Label>
                      <Form.Control
                        as="select"
                        className="rounded bg-light border h-auto pb-4 pt-4 font-size-3"
                        name="school_core_subjects"
                        {...formik.getFieldProps("school_core_subjects")}
                        onChange={formik.handleChange}
                      >
                        <option defaultValue value="">
                          Choose a core subject
                        </option>
                        {_core_subjects.map((ele, index) => {
                          return (
                            <option value={ele.value} key={index}>
                              {ele.lable}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </div>
                    {formik.touched.school_core_subjects &&
                      formik.errors.school_core_subjects && (
                        <small className="text-danger">
                          {formik.errors.school_core_subjects}
                        </small>
                      )}
                  </Form.Group>

                  <div>
                    <Form.Group>
                      <Form.Label className="font-size-3 mb-1 font-weight-bold text-black flex-grow-1">
                        Medium of study
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="mediumStudy"
                        className="rounded bg-light border h-auto pb-4 pt-4 font-size-3"
                        {...formik.getFieldProps("school_study_medium")}
                        onChange={formik.handleChange}
                      >
                        <option defaultValue value="">
                          Choose a Medium
                        </option>

                        {_study_medium.map((ele, index) => {
                          return (
                            <option value={ele.value} key={index}>
                              {ele.lable}
                            </option>
                          );
                        })}
                      </Form.Control>
                      {formik.touched.school_study_medium &&
                        formik.errors.school_study_medium && (
                          <small className="text-danger">
                            {formik.errors.school_study_medium}
                          </small>
                        )}
                    </Form.Group>
                  </div>

                  <div className="d-flex justify-content-between my-5">
                    <Form.Group className="w-100">
                      <Form.Label className="font-size-3 mb-1 font-weight-bold text-black flex-grow-1">
                        City
                      </Form.Label>
                      <Form.Control
                        className="bg-light border rounded h-auto pb-2 pt-2 font-size-3 text-black"
                        type="text"
                        name="school_city"
                        placeholder="Enter Here..."
                        {...formik.getFieldProps("school_city")}
                        onChange={formik.handleChange}
                      />
                      {formik.touched.school_city &&
                        formik.errors.school_city && (
                          <small className="text-danger">
                            {formik.errors.school_city}
                          </small>
                        )}
                    </Form.Group>

                    <Form.Group className="w-100 ml-2">
                      <Form.Label className="font-size-3 mb-1 font-weight-bold text-black flex-grow-1">
                        State
                      </Form.Label>
                      <Form.Control
                        className="bg-light border rounded h-auto pb-2 pt-2 font-size-3 text-black"
                        type="text"
                        name="school_state"
                        placeholder="Enter Here..."
                        {...formik.getFieldProps("school_state")}
                        onChange={formik.handleChange}
                      />
                      {formik.touched.school_state &&
                        formik.errors.school_state && (
                          <small className="text-danger">
                            {formik.errors.school_state}
                          </small>
                        )}
                    </Form.Group>
                  </div>

                  <div className="d-flex justify-content-between">
                    <Form.Group className="w-100">
                      <Form.Label className="font-size-3 mb-1 font-weight-bold text-black flex-grow-1">
                        Pin Code
                      </Form.Label>
                      <Form.Control
                        className="bg-light border rounded h-auto pb-2 pt-2 font-size-3 text-black"
                        type="number"
                        name="school_pin_code"
                        placeholder="Enter Here..."
                        {...formik.getFieldProps("school_pin_code")}
                        onChange={formik.handleChange}
                      />
                      {formik.touched.school_pin_code &&
                        formik.errors.school_pin_code && (
                          <small className="text-danger">
                            {formik.errors.school_pin_code}
                          </small>
                        )}
                    </Form.Group>

                    <Form.Group className="w-100 ml-2">
                      <Form.Label className="font-size-3 mb-1 font-weight-bold text-black flex-grow-1">
                        Country
                      </Form.Label>
                      <Form.Control
                        className="bg-light border rounded h-auto pb-2 pt-2 font-size-3 text-black"
                        type="text"
                        name="v"
                        placeholder="Enter Here..."
                        {...formik.getFieldProps("school_country")}
                        onChange={formik.handleChange}
                      />
                      {formik.touched.school_country &&
                        formik.errors.school_country && (
                          <small className="text-danger">
                            {formik.errors.school_country}
                          </small>
                        )}
                    </Form.Group>
                  </div>
                </Col>
                <Col xs={6}>
                  <img
                    src="https://res.cloudinary.com/cogmentor/image/upload/v1635449735/ILLUSTRATION%20IMAGES/PROFILE%20PAGE/cogmentor_illustrations-01_wb6fsn.png"
                    alt=""
                  />
                </Col>
              </Row>
            </div>

            {/* PARENT SECTION START */}
            <div className="form-div my-10">
              <Row>
                <Col xs={6}>
                  <h4 className=" font-weight-bold">
                    Parents/ Guardian Details
                  </h4>

                  {collectParent?.map((res, index) => {
                    return (
                      <div
                        className="d-flex justify-content-between align-items-center border rounded py-0 px-8 my-5"
                        key={index}
                      >
                        <p className="mt-5 parent-text-name">
                          {res.name} - {res.relationship}
                        </p>
                        <div>
                          <button
                            type="button"
                            className="edit-parent"
                            onClick={() => EditParent(res)}
                          >
                            Edit
                          </button>
                          {res.id === 0 ? (
                            <button
                              type="button"
                              className="edit-parent ml-2"
                              onClick={() => DeleteParent(res.id)}
                            >
                              Delete
                            </button>
                          ) : null}
                        </div>
                      </div>
                    );
                  })}

                  <Form.Group>
                    <div className="d-flex align-items-center">
                      <Form.Label
                        className="font-size-3 mb-1 font-weight-bold text-black flex-grow-1"
                        ref={name}
                      >
                        Name*
                      </Form.Label>
                    </div>
                    <Form.Control
                      className="bg-light border rounded h-auto pb-2 pt-2 font-size-3 text-black"
                      type="text"
                      name="name"
                      placeholder="Enter Here..."
                      value={parentvalue.name}
                      onChange={(e) => {
                        handleChangeValue(e);
                        formik.handleChange(e);
                      }}
                    />
                    {formik.touched.name && formik.errors.name && (
                      <small className="text-danger">
                        {formik.errors.name}
                      </small>
                    )}
                  </Form.Group>

                  <div className="d-flex justify-content-between my-5">
                    <Form.Group className="w-100">
                      <Form.Label
                        className="font-size-3 mb-1 font-weight-bold text-black flex-grow-1"
                        ref={relationship}
                      >
                        Relationship*
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="relationship"
                        className="rounded bg-light border h-auto pb-4 pt-4 font-size-3"
                        value={parentvalue.relationship}
                        onChange={(e) => {
                          handleChangeValue(e);
                          formik.handleChange(e);
                        }}
                      >
                        <option defaultValue value="">
                          Choose a relationship
                        </option>
                        {_relationship.map((ele, index) => {
                          return (
                            <option value={ele.value} key={index}>
                              {ele.lable}
                            </option>
                          );
                        })}
                      </Form.Control>
                      {formik.touched.relationship &&
                        formik.errors.relationship && (
                          <small className="text-danger">
                            {formik.errors.relationship}
                          </small>
                        )}
                    </Form.Group>

                    <Form.Group className="w-100 ml-2">
                      <Form.Label
                        className="font-size-3 mb-1 font-weight-bold text-black flex-grow-1"
                        ref={gender}
                      >
                        Gender*
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="gender"
                        className="rounded bg-light border h-auto pb-4 pt-4 font-size-3"
                        value={parentvalue.gender}
                        onChange={(e) => {
                          handleChangeValue(e);
                          formik.handleChange(e);
                        }}
                      >
                        <option defaultValue value="">
                          Choose a gender
                        </option>
                        {_parent_gender.map((ele, index) => {
                          return (
                            <option value={ele.value} key={index}>
                              {ele.lable}
                            </option>
                          );
                        })}
                      </Form.Control>
                      {formik.touched.gender && formik.errors.gender && (
                        <small className="text-danger">
                          {formik.errors.gender}
                        </small>
                      )}
                    </Form.Group>
                  </div>

                  <Form.Group>
                    <Form.Label
                      className="font-size-3 mb-1 font-weight-bold text-black flex-grow-1"
                      ref={email}
                    >
                      Email Id*
                    </Form.Label>
                    <Form.Control
                      className="bg-light border rounded h-auto pb-2 pt-2 font-size-3 text-black"
                      type="email"
                      name="email"
                      placeholder="Enter Here..."
                      value={parentvalue.email}
                      onChange={(e) => {
                        handleChangeValue(e);
                        formik.handleChange(e);
                      }}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <small className="text-danger">
                        {formik.errors.email}
                      </small>
                    )}
                  </Form.Group>

                  <Form.Group className="position-relative my-5">
                    <Form.Label
                      className="font-size-3 mb-1 font-weight-bold text-black"
                      ref={phone}
                    >
                      Phone Number*
                    </Form.Label>
                    <Form.Control
                      className="bg-light border rounded h-auto pb-2 pt-2 font-size-3 text-black"
                      pattern="[0-9]{10}"
                      type="tel"
                      name="phone"
                      placeholder="Enter Here..."
                      value={parentvalue.phone}
                      onChange={(e) => {
                        handleChangeValue(e);
                        formik.handleChange(e);
                      }}
                    />
                    {/* <button type="button" className="verify-btn rounded">Verify</button> */}
                    {formik.touched.phone && formik.errors.phone && (
                      <small className="text-danger">
                        {formik.errors.phone}
                      </small>
                    )}
                  </Form.Group>

                  <Form.Group className="my-5">
                    <Form.Label className="font-size-3 mb-1 font-weight-bold text-black flex-grow-1">
                      Native Language
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="nativeLanguage"
                      className="rounded bg-light border h-auto pb-4 pt-4 font-size-3"
                      value={parentvalue.nativeLanguage}
                      // {...formik.getFieldProps("parent_nativeLanguage")} onChange={formik.handleChange}
                      onChange={handleChangeValue}
                    >
                      <option defaultValue value="">
                        Choose a Native Language
                      </option>
                      {_native_language.map((ele, index) => {
                        return (
                          <option value={ele.value} key={index}>
                            {ele.lable}
                          </option>
                        );
                      })}
                    </Form.Control>
                    {formik.touched.parent_nativeLanguage &&
                      formik.errors.parent_nativeLanguage && (
                        <small className="text-danger">
                          {formik.errors.parent_nativeLanguage}
                        </small>
                      )}
                  </Form.Group>

                  <Form.Group>
                    <Form.Label className="font-size-3 mb-1 font-weight-bold text-black flex-grow-1">
                      Highest Qualification
                    </Form.Label>
                    <Form.Control
                      className="bg-light border rounded h-auto pb-2 pt-2 font-size-3 text-black"
                      value={parentvalue.qualification}
                      type="text"
                      name="qualification"
                      placeholder="Enter Here..."
                      // {...formik.getFieldProps("parent_qualification")} onChange={formik.handleChange}
                      onChange={handleChangeValue}
                    />
                    {formik.touched.parent_qualification &&
                      formik.errors.parent_qualification && (
                        <small className="text-danger">
                          {formik.errors.parent_qualification}
                        </small>
                      )}
                  </Form.Group>

                  <Form.Group className="my-5">
                    <Form.Label className="font-size-3 mb-1 font-weight-bold text-black flex-grow-1">
                      Income (Annualy)
                    </Form.Label>
                    <Form.Control
                      className="bg-light border rounded h-auto pb-2 pt-2 font-size-3 text-black"
                      value={parentvalue.income}
                      type="text"
                      name="income"
                      placeholder="Enter Here..."
                      // {...formik.getFieldProps("parent_income")} onChange={formik.handleChange}
                      onChange={handleChangeValue}
                    />
                    {formik.touched.parent_income &&
                      formik.errors.parent_income && (
                        <small className="text-danger">
                          {formik.errors.parent_income}
                        </small>
                      )}
                  </Form.Group>

                  <div className="d-flex justify-content-between">
                    <Form.Group className="w-100">
                      <Form.Label className="font-size-3 mb-1 font-weight-bold text-black flex-grow-1">
                        Profession
                      </Form.Label>
                      <Form.Control
                        className="bg-light border rounded h-auto pb-2 pt-2 font-size-3 text-black"
                        value={parentvalue.profession}
                        type="text"
                        name="profession"
                        placeholder="Enter Here..."
                        // {...formik.getFieldProps("parent_profession")} onChange={formik.handleChange}
                        onChange={handleChangeValue}
                      />
                      {formik.touched.parent_profession &&
                        formik.errors.parent_profession && (
                          <small className="text-danger">
                            {formik.errors.parent_profession}
                          </small>
                        )}
                    </Form.Group>

                    <Form.Group className="w-100 ml-2">
                      <Form.Label className="font-size-3 mb-1 font-weight-bold text-black flex-grow-1">
                        Employment Category
                      </Form.Label>
                      <Form.Control
                        className="bg-light border rounded h-auto pb-2 pt-2 font-size-3 text-black"
                        value={parentvalue.employment_category}
                        type="text"
                        name="employment_category"
                        placeholder="Enter Here..."
                        // {...formik.getFieldProps("parent_employment_category")} onChange={formik.handleChange}
                        onChange={handleChangeValue}
                      />
                      {formik.touched.parent_employment_category &&
                        formik.errors.parent_employment_category && (
                          <small className="text-danger">
                            {formik.errors.parent_employment_category}
                          </small>
                        )}
                    </Form.Group>
                  </div>

                  <div className="d-flex justify-content-between my-5">
                    <Form.Group className="w-100">
                      <Form.Label className="font-size-3 mb-1 font-weight-bold text-black flex-grow-1">
                        Organization
                      </Form.Label>
                      <Form.Control
                        className="bg-light border rounded h-auto pb-2 pt-2 font-size-3 text-black"
                        value={parentvalue.organization}
                        type="text"
                        name="organization"
                        placeholder="Enter Here..."
                        // {...formik.getFieldProps("parent_organization")} onChange={formik.handleChange}
                        onChange={handleChangeValue}
                      />
                      {formik.touched.parent_organization &&
                        formik.errors.parent_organization && (
                          <small className="text-danger">
                            {formik.errors.parent_organization}
                          </small>
                        )}
                    </Form.Group>

                    <Form.Group className="w-100 ml-2 ">
                      <Form.Label className="font-size-3 mb-1 font-weight-bold text-black flex-grow-1">
                        Designation
                      </Form.Label>
                      <Form.Control
                        className="bg-light border rounded h-auto pb-2 pt-2 font-size-3 text-black"
                        value={parentvalue.designation}
                        type="text"
                        name="designation"
                        placeholder="Enter Here..."
                        // {...formik.getFieldProps("parent_designation")} onChange={formik.handleChange}
                        onChange={handleChangeValue}
                      />
                      {formik.touched.parent_designation &&
                        formik.errors.parent_designation && (
                          <small className="text-danger">
                            {formik.errors.parent_designation}
                          </small>
                        )}
                    </Form.Group>
                  </div>
                  {ParentButton ? (
                    <button
                      className="mt-10 border text-primary btn border-width-2 border-primary bg-blue-grey-10 font-size-4 p-3 h-auto w-50"
                      type="button"
                      onClick={() => UpdateParent()}
                    >
                      Update Parent
                    </button>
                  ) : (
                    <button
                      className="mt-10 border text-primary btn border-width-2 border-primary bg-blue-grey-10 font-size-4 p-3 h-auto w-50"
                      type="button"
                      onClick={() => AddParent()}
                    >
                      Add Parent
                    </button>
                  )}
                </Col>
                <Col xs={6}>
                  <img
                    src="https://res.cloudinary.com/cogmentor/image/upload/v1635449735/ILLUSTRATION%20IMAGES/PROFILE%20PAGE/cogmentor_illustrations-02_znemms.png"
                    alt=""
                  />
                </Col>
              </Row>
            </div>

            <div className="form-div my-10">
              <Row>
                <Col xs={6}>
                  <h4 className=" font-weight-bold">Mentor Details</h4>
                  <InputTextGroup
                    label="Mentor Name"
                    type="text"
                    name="mentor_name"
                    placeholder="Mentor Name"
                    classNameLabel="font-size-3 mb-1 font-weight-bold text-black flex-grow-1"
                    classNameControl="bg-light border rounded h-auto pb-2 pt-2 font-size-3 text-black"
                    {...formik.getFieldProps("mentor_name")}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.mentor_name && formik.errors.mentor_name
                    }
                  />
                  <Form.Group className="my-5">
                    <Form.Label className="font-size-3 mb-1 font-weight-bold text-black flex-grow-1">
                      Type
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="mentor_type"
                      className="rounded bg-light border h-auto pb-4 pt-4 font-size-3"
                      {...formik.getFieldProps("mentor_type")}
                      onChange={formik.handleChange}
                    >
                      {_mentor_type.map((ele, index) => {
                        return (
                          <option value={ele.value} key={index}>
                            {ele.lable}
                          </option>
                        );
                      })}
                    </Form.Control>
                    {formik.touched.mentor_type &&
                      formik.errors.mentor_type && (
                        <small className="text-danger">
                          {formik.errors.mentor_type}
                        </small>
                      )}
                  </Form.Group>
                  <InputTextGroup
                    label="Email Id"
                    type="email"
                    name="mentor_email"
                    placeholder="Mentor Email"
                    classNameGroup="position-relative"
                    classNameLabel="font-size-3 mb-1 font-weight-bold text-black flex-grow-1"
                    classNameControl="bg-light border rounded h-auto pb-2 pt-2 font-size-3 text-black"
                    {...formik.getFieldProps("mentor_email")}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.mentor_email && formik.errors.mentor_email
                    }
                  />
                  <InputTextGroup
                    label="Phone Number"
                    type="phone"
                    name="mentor_phone"
                    placeholder="Mentor Phone"
                    classNameGroup="my-5"
                    classNameLabel="font-size-3 mb-1 font-weight-bold text-black"
                    classNameControl="bg-light border rounded h-auto pb-2 pt-2 font-size-3 text-black"
                    {...formik.getFieldProps("mentor_phone")}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.mentor_phone && formik.errors.mentor_phone
                    }
                  />
                </Col>
                <Col xs={6}>
                  <img
                    src="https://res.cloudinary.com/cogmentor/image/upload/v1635449735/ILLUSTRATION%20IMAGES/PROFILE%20PAGE/cogmentor_illustrations-04_ug8mg3.png"
                    alt=""
                  />
                </Col>
              </Row>
            </div>
          </form>
        </>
      )}
    </div>
  );
}
