import { useFormik } from "formik";
import React from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Cut from "../../assets/Image/ManageSubAdmins/cut.svg";
import {
  downloadCognitiveDataStart,
  downloadStudentDataStart,
  downloadTrainingDataStart,
} from "../../redux/actions";

import { downloadInitialValue, downloadValidationSchema } from "./utils";

const AssementDownloadModal = ({ onHide, condition }) => {
  const dispatch = useDispatch();

  const { studentLoader } = useSelector((state) => state.studentDetailSession);
  const { cognitiveAssessmentsLoader } = useSelector(
    (state) => state.cognitiveAssesSession
  );
  const today = new Date().toISOString().split("T")[0];

  const onSubmit = async (values) => {
    if (condition.type === "STUDENT") {
      dispatch(
        downloadStudentDataStart({
          userid: condition.payload,
          start: values.start_date,
          end: values.end_date,
        })
      );
    } else if (condition.type === "COGNITIVEASS") {
      dispatch(
        downloadCognitiveDataStart({
          userid: condition.payload,
          start: values.start_date,
          end: values.end_date,
        })
      );
    } else if (condition.type === "TRAINING") {
      console.log('Dispatching Training data');
      dispatch(
        downloadTrainingDataStart({
          userid: condition.payload,
          start: values.start_date,
          end: values.end_date,
        })
      );
    }
  };

  const formik = useFormik({
    initialValues: downloadInitialValue,
    onSubmit,
    validationSchema: downloadValidationSchema,
  }); // FORMIK

  return (
    <>
      <div className="p-10 ">
        <div className="d-flex justify-content-end">
          <div onClick={onHide} style={{ cursor: "pointer" }}>
            <img src={Cut} alt="" />
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <div onClick={onHide} style={{ cursor: "pointer" }}></div>
        </div>
        <h3 className="text-center my-7 font-weight-bold download-assessments">
          Select Date and Download
        </h3>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group>
            <Form.Label className="font-size-3 mb-1 mt-2 font-weight-bold text-black">
              Start Date*
            </Form.Label>
            <Form.Control
              className="bg-light border rounded h-auto pb-2 pt-2 font-size-3 text-black"
              type="date"
              name="start_date"
              placeholder="Start Date"
              max={today}
              onChange={formik.handleChange}
              {...formik.getFieldProps("start_date")}
            />
            {formik.touched.start_date && formik.errors.start_date && (
              <small className="text-danger">{formik.errors.start_date}</small>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label className="font-size-3 mb-1 mt-2 font-weight-bold text-black">
              End Date*
            </Form.Label>
            <Form.Control
              className="bg-light border rounded h-auto pb-2 pt-2 font-size-3 text-black"
              type="date"
              name="end_date"
              placeholder="End Date"
              max={today}
              {...formik.getFieldProps("end_date")}
              onChange={formik.handleChange}
            />
            {formik.touched.end_date && formik.errors.end_date && (
              <small className="text-danger">{formik.errors.end_date}</small>
            )}
          </Form.Group>

          <div className="d-flex justify-content-between mt-9 w-100">
            <Button type="submit" className="w-100">
              {studentLoader || cognitiveAssessmentsLoader
                ? "Loading..."
                : "Download Assessment"}
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default React.memo(AssementDownloadModal);
