import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import {
  COGNITIVE_ASSESSMENT,
  COGNITIVE_ASSESSMENT_COUNT,
  DOWNLOAD_COGNITIVE_DATA_START,
} from "../actions";
import {
  cognitiveAssessmentsSuccess,
  cognitiveAssessmentsCountSuccess,
  cognitiveAssessmentsError,
  downloadCognitiveDataSuccess,
  downloadCognitiveDataError,
} from "./actions";
import { API_PATH_LOCAL } from "../../utils/apisPath";

// GET ALL COGNITIVE ASSESSMENT DETAILS
const cognitiveAssessmentsDetailsAsync = async (payload) =>
  await axios
    .get(API_PATH_LOCAL + `/getcognitiveAssessment-admin?page=${payload}`)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return error.response.data.error;
    });

function* fetchCognitiveAssessmentsDetails(payload) {
  try {
    const cognitiveAssessments = yield call(
      cognitiveAssessmentsDetailsAsync,
      payload.payload
    );
    yield put(cognitiveAssessmentsSuccess(cognitiveAssessments));
  } catch (error) {
    yield put(cognitiveAssessmentsError(error));
  }
}

//Get Domain and Asessment Count
const cognitiveAssCountAsync = async () =>
  await axios
    .get(API_PATH_LOCAL + `/cognitiveAssessment/count`)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return error.response.data.error;
    });

function* fetchcognitiveAssCountDetails() {
  try {
    const data = yield call(cognitiveAssCountAsync);
    yield put(cognitiveAssessmentsCountSuccess(data));
  } catch (error) {
    yield put(downloadCognitiveDataError(error));
  }
}

// DOWNLOAD COGNITIVE DATA
const downloadCognitiveDataAsync = async (payload) =>
  await axios
    .get(
      API_PATH_LOCAL +
        `/getExpDataByAssId?assessmentId=${payload.userid}&startDate=${payload.start}&endDate=${payload.end}`
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data.error;
    });

function* fetchCogniiveData(payload) {
  try {
    const downloaddata = yield call(
      downloadCognitiveDataAsync,
      payload.payload
    );
    yield put(downloadCognitiveDataSuccess(downloaddata));
  } catch (error) {
    yield put(downloadStudentDataError(error));
  }
}

export function* addCognitiveAssessments() {
  yield takeEvery(COGNITIVE_ASSESSMENT, fetchCognitiveAssessmentsDetails);
}

export function* getCognitiveAsseCount() {
  yield takeEvery(COGNITIVE_ASSESSMENT_COUNT, fetchcognitiveAssCountDetails);
}

export function* downloadCogData() {
  yield takeEvery(DOWNLOAD_COGNITIVE_DATA_START, fetchCogniiveData);
}

export default function* rootSaga() {
  yield all([fork(addCognitiveAssessments)]);
  yield all([fork(getCognitiveAsseCount)]);
  yield all([fork(downloadCogData)]);
}
