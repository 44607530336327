import moment from "moment";

export const columns = ({ index, navigate }) => {
  const columnValues = [
    {
      name: "Id",
      selector: (row) => row.id,
      style: {
        cursor: "default",
      },
      center: true,
    },
    {
      name: "Name",
      selector: (row) => {
        return (
          <button
            className="border-0 py-2 px-7 bg-opacity-10 rounded-3 table-btn"
            style={{
              whiteSpace: "nowrap",
              backgroundColor: "#607f962e",
              borderRadius: "50px",
            }}
            onClick={() => navigate(`/app/students/${row.id}`)}
          >
            {row.name}
          </button>
        );
      },
      style: {
        cursor: "default",
      },
      center: true,
    },
    {
      name: "Gender",
      selector: (row) => row.gender,
      style: {
        cursor: "default",
      },
      center: true,
    },
    {
      name: "Age",
      selector: (row) => {
        if (row.dob) {
          return (
            moment(moment(row.dob).format("L"), "MM/DD/YYYY")
              .fromNow()
              .split(" ")[0] + " Years"
          );
        }
      },
      style: {
        cursor: "default",
      },
      center: true,
    },
    {
      name: "Class",
      selector: (row) => row.name,
      style: {
        cursor: "default",
      },
      center: true,
    },
  ];
  return columnValues;
};
