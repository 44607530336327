export const emptyParentObject = {
  designation: "",
  email: "",
  employment_category: "",
  gender: "",
  income: "",
  name: "",
  nativeLanguage: "",
  organization: "",
  phone: "",
  profession: "",
  qualification: "",
  relationship: "",
}; // use for empty parent
