import React, {
  useEffect,
  useState,
} from 'react';

import moment from 'moment';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  BackArrow,
  BronchBadge,
  DefaultAvatar,
  DimondBadge,
  GoldBadge,
  PlatinumBadge,
  SilverBadge,
} from '../../../assets';
import {
  CustomButton,
  ParentAccordion,
} from '../../../components';
import CustomLoader from '../../../components/CustomLoader/index';
import { LoaderSpin } from '../../../components/Global/ImageGlobal';
import {
  badgesAction,
  badgesByIdAction,
} from '../../../redux/badges/actions';
import {
  getStudentByIdStart,
  StudentCompletedAssessmentStart,
  studentEmpty,
  StudentUnblockAssessmentStart,
} from '../../../redux/studentDetails/actions';
import { _badges_details } from '../../../utils/dummyData';
import {
  addZeroIfNeeded,
  options,
} from '../../../utils/functions/commonFunctions';

const StudentProfile = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();

  const [studentDetail, setStudentDetail] = useState([]); // STUDENT DATA
  const [parentDetail, setParentDetail] = useState([]); // PARENT DATA
  const [allBadgesDetails, setAllBadgesDetails] = useState([]);
  const [earnBadges, setEarnBadges] = useState([]);

  // STUDENT REDUX
  const {
    studentById,
    studentLoader,
    completed_assessment,
    unblock_assessment,
    studenterror,
  } = useSelector((state) => state.studentDetailSession);
  const {
    all_badges,
    indivisual_badges,
    badgesLoader,
    indivisualBadgesLoader,
  } = useSelector((state) => state.badgesSession);

  // CALL API
  useEffect(() => {
    dispatch(getStudentByIdStart(id));
    dispatch(StudentCompletedAssessmentStart(id));
    dispatch(badgesAction());
    dispatch(badgesByIdAction(id));
  }, []);

  // STORE DATA TO STATE
  useEffect(() => {
    if (studentById) {
      setStudentDetail(studentById);
    }
    if (all_badges) {
      setAllBadgesDetails(all_badges);
    }
    if (indivisual_badges) {
      setEarnBadges(indivisual_badges?.data);
    }
  }, [studentById, all_badges, indivisual_badges]);

  // STORE PARENT DATA TO STATE
  useEffect(() => {
    if (studentDetail.parent_details?.length > 0) {
      setParentDetail(studentDetail.parent_details);
    }
  }, [studentDetail]);

  useEffect(() => {
    if (unblock_assessment.code === 200) {
      toast(unblock_assessment.message, options);
      dispatch(studentEmpty());
      dispatch(StudentCompletedAssessmentStart(id));
    }

    if (studenterror) {
      toast.error(studenterror);
      dispatch(studentEmpty());
    }
  }, [unblock_assessment, studenterror]); // TOAST ALERT

  return (
    <>
      {studentLoader ? (
        <div className="d-flex justify-content-center">
          <img src={LoaderSpin} alt="loader" />
        </div>
      ) : (
        <div className="p-7 student-profile">
          <div>
            <img
              src={BackArrow}
              alt=""
              onClick={() => navigate(-1)}
              className="custom-click"
            />
            <span className="ml-4 student-profile-text ">Student Profile</span>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="name-text w-100">
              {studentDetail?.student_details?.name
                ? studentDetail?.student_details?.name
                : "N/A"}
            </div>
            <div className="d-flex w-100 align-items-center justify-content-end ">
              <CustomButton
                className="view-button w-100"
                handleClick={() => navigate(`/app/add-new-student/${id}`)}
                text="Edit Student"
              />
            </div>
          </div>
          <Row className="mt-7">
            <Col className="d-flex flex-column jutify-content-between">
              <div className="bg-white all-cards student-details-card  h-100">
                <div className="all-cards-heading">Student Details</div>
                <Row className="align-items-center my-10">
                  <Col lg={3} xl={2}>
                    <img
                      src={
                        studentDetail?.student_details?.avatar
                          ? studentDetail?.student_details?.avatar
                          : DefaultAvatar
                      }
                      alt=""
                      className="card-image"
                      style={{ objectFit: "cover" }}
                    />
                  </Col>
                  <Col lg={9} xl={6} className="my-3 my-lg-0 ">
                    <div className="all-cards-names  ">
                      {studentDetail?.student_details?.name
                        ? studentDetail?.student_details?.name
                        : "N/A"}
                    </div>
                    <div>
                      <span className="all-cards-email">
                        {studentDetail?.student_details?.email
                          ? studentDetail?.student_details?.email
                          : "N/A"}
                      </span>
                      <span className="text-primary font-weight-bold  ml-md-5">
                        {studentDetail?.student_details
                          ?.email_verification_status === 1
                          ? "Verified"
                          : "Not Verified"}
                      </span>
                    </div>
                  </Col>
                  <Col
                    lg={12}
                    xl={4}
                    className="my-3 my-xl-0 "
                    style={{ whiteSpace: "nowrap" }}
                  ></Col>
                </Row>
                <Row className="align-items-center my-10">
                  <Col lg={4}>
                    <div
                      className=".font-weight-bolder "
                      style={{ fontSize: "32px", color: "#652581" }}
                    >
                      0.00%
                    </div>
                    <div style={{ fontSize: "12px" }}>Score Earned </div>
                  </Col>
                  <Col lg={8}>
                    {" "}
                    <div
                      className=".font-weight-bolder "
                      style={{ fontSize: "32px", color: "#652581" }}
                    >
                      N/A
                    </div>
                    <div style={{ fontSize: "12px" }}>
                      {/* Subscription - Expiring on 21 Aug 2021 */}
                      Subscription - N/A
                    </div>
                  </Col>
                </Row>
                <Row className="align-items-center my-10">
                  <Col lg={6} xl={2} className="my-5 my-xl-0 ">
                    <div className=" all-texts-first">
                      {studentDetail?.student_details?.gender
                        ? studentDetail?.student_details?.gender
                        : "N/A"}
                    </div>
                    <div className="all-texts-second">Gender</div>
                  </Col>
                  <Col lg={6} xl={4} className="my-5 my-xl-0 ">
                    <div className=" all-texts-first">
                      {moment(studentDetail?.student_details?.dob).format("ll")}
                    </div>
                    <div className="all-texts-second">DOB</div>
                  </Col>
                  <Col lg={6} xl={6} className="my-5 my-xl-0 ">
                    <div className=" all-texts-first">
                      {studentDetail?.student_details?.phone
                        ? studentDetail?.student_details?.phone
                        : "N/A"}
                    </div>
                    <div className="all-texts-second">Phone Number</div>
                  </Col>
                  <Col lg={6} xl={2} className="my-5 my-xl-10 ">
                    <div className=" all-texts-first">India</div>
                    <div className="all-texts-second">Country</div>
                  </Col>
                  <Col lg={6} xl={4} className="my-5 my-xl-10">
                    <div className=" all-texts-first">
                      {studentDetail?.student_details?.category
                        ? studentDetail?.student_details?.category
                        : "N/A"}
                    </div>
                    <div className="all-texts-second">Category</div>
                  </Col>
                </Row>
              </div>
              <div className="all-cards teacher-details-card my-7 h-100">
                <div className="all-cards-heading">Teacher Details</div>
                <Row className="mt-10">
                  <Col lg={6} xl={5} xxl={4}>
                    <div className="all-cards-names ">
                      {studentDetail?.mentor_details?.name
                        ? studentDetail?.mentor_details?.name
                        : "N/A"}
                    </div>
                    <div className="all-cards-email">
                      {studentDetail?.mentor_details?.email
                        ? studentDetail?.mentor_details?.email
                        : "N/A"}
                    </div>
                  </Col>
                  <Col lg={6} xl={7} xxl={8}>
                    <div className="all-texts-first">
                      {studentDetail?.mentor_details?.phone
                        ? studentDetail?.mentor_details?.phone
                        : "N/A"}
                    </div>
                    <div className="all-texts-second">Phone Number</div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col className="d-flex flex-column jutify-content-between">
              <div className="all-cards parent-details-card h-100">
                <div className="all-cards-heading mb-5">Parent Details</div>
                {parentDetail &&
                  parentDetail.map((accordion) => {
                    return <ParentAccordion value={accordion} />;
                  })}
              </div>

              <div className="all-cards my-7 h-100">
                <div className="all-cards-heading ">School Details</div>
                <div className="mt-10">
                  <div className="all-cards-names  ">
                    {studentDetail?.school_details?.school_name
                      ? studentDetail?.school_details?.school_name
                      : "N/A"}
                  </div>
                  <div className="all-cards-email">
                    {studentDetail?.school_details?.board}
                  </div>
                </div>
                <Row className="align-items-center ">
                  <Col lg={6} xl={3} className="mt-10 ">
                    <div className=" all-texts-first">
                      {studentDetail?.school_details?.school_id
                        ? studentDetail?.school_details?.school_id
                        : "N/A"}
                    </div>
                    <div className="all-texts-second">School Code</div>
                  </Col>

                  <Col lg={6} xl={3} className="mt-10 ">
                    <div className=" all-texts-first">{`${
                      studentDetail?.school_details?.class
                        ? studentDetail?.school_details?.class
                        : "N/A"
                    }th  ${
                      studentDetail?.school_details?.section
                        ? studentDetail?.school_details?.section
                        : ""
                    }`}</div>
                    <div className="all-texts-second">Class - Section</div>
                  </Col>
                  <Col lg={6} xl={4} className="mt-10 ">
                    <div className="all-texts-first">N/A</div>

                    <div className="all-texts-second">Core Subjects</div>
                  </Col>
                  <Col lg={6} xl={2} className="mt-10 ">
                    <div className=" all-texts-first">
                      {studentDetail?.school_details?.study_medium
                        ? studentDetail?.school_details?.study_medium
                        : "N/A"}
                    </div>
                    <div className="all-texts-second">Medium of study</div>
                  </Col>
                  <Col lg={6} xl={3} className="mt-10">
                    <div className=" all-texts-first">
                      {studentDetail?.school_details?.pincode
                        ? studentDetail?.school_details?.pincode
                        : "N/A"}
                    </div>
                    <div className="all-texts-second">Pincode</div>
                  </Col>
                  <Col lg={6} xl={3} className="mt-10 ">
                    <div className=" all-texts-first">
                      {studentDetail?.school_details?.city
                        ? studentDetail?.school_details?.city
                        : "N/A"}
                    </div>
                    <div className="all-texts-second">City</div>
                  </Col>
                  <Col lg={6} xl={3} className="mt-10">
                    <div className=" all-texts-first">
                      {studentDetail?.school_details?.state
                        ? studentDetail?.school_details?.state
                        : "N/A"}
                    </div>
                    <div className="all-texts-second">State</div>
                  </Col>
                  <Col lg={6} xl={3} className="mt-10">
                    <div className=" all-texts-first">
                      {studentDetail?.school_details?.country
                        ? studentDetail?.school_details?.country
                        : "N/A"}
                    </div>
                    <div className="all-texts-second">Country</div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <div className="all-cards mt-7">
            <div className="d-flex justify-content-between align-items-center">
              <div className="all-cards-heading">
                Reward Points &amp; Badges
              </div>

              <div className="d-flex justify-content-between align-items-center">
                <div className="mr-10">
                  <div>Total Badges Earned</div>
                  <div
                    className=".font-weight-bolder  text-right "
                    style={{ fontSize: "32px", color: "#9366A7" }}
                  >
                    {addZeroIfNeeded(indivisual_badges?.count)}
                  </div>
                </div>
                <div>
                  Total Reward Points
                  <div
                    className=".font-weight-bolder  text-right "
                    style={{ fontSize: "32px", color: "#905808" }}
                  >
                    06
                  </div>
                </div>
              </div>
            </div>

            <Row className="my-10">
              <Col xs={12} xl={6}>
                <div className="reward-points-card border rounded-3 h-100 p-5">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="all-cards-names ">Reward Points</div>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="font-weight-bold ">
                        Intermediate
                        <div style={{ fontSize: "12px" }}>Current Level</div>
                      </div>
                      <div>
                        <img
                          src={PlatinumBadge}
                          alt=""
                          style={{ height: "70px", width: "70px  " }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className=" mt-17">
                    <div className="slider-container">
                      <input
                        type="range"
                        min="800"
                        max="2800"
                        value="2000"
                        className="slider"
                        id="myRange"
                        disabled
                      />
                    </div>
                    <Row className="justify-content-between align-content-center all-badges">
                      <Col className="p-0">
                        <div className="mb-4 " style={{}}>
                          Amateur
                        </div>
                        <img src={SilverBadge} alt="" />
                      </Col>
                      <Col className="">
                        <div className="mb-4 ">Begineer</div>
                        <img src={BronchBadge} alt="" />
                      </Col>
                      <Col className="">
                        <div className="mb-4 ">Intermediate</div>
                        <img src={GoldBadge} alt="" />
                      </Col>
                      <Col className="">
                        <div className="mb-4 ">Amateur</div>
                        <img src={DimondBadge} alt="" />
                      </Col>
                      <Col className="text-right p-0">
                        <div className="mb-4 ">Amateur</div>
                        <img src={PlatinumBadge} alt="" />
                      </Col>
                    </Row>
                  </div>
                  <div className="mt-10 mb-3">
                    <div
                      className=".font-weight-bolder "
                      style={{ color: " rgba(0, 0, 0, 0.45)" }}
                    >
                      History
                    </div>
                    {completed_assessment?.map((data) => {
                      return (
                        <div className="py-7 border-bottom">
                          <div
                            className="font-weight-bold  "
                            style={{ fontSize: "12px", color: "#3B9362" }}
                          >
                            Completed
                          </div>
                          <div className="d-flex justify-content-between align-items-center mt-4">
                            <div>
                              <div
                                className="font-weight-bold "
                                style={{ fontSize: "16px", color: "#000000" }}
                              >
                                {data.name}
                              </div>
                              <div
                                style={{ fontSize: "12px", color: "#000000" }}
                              >
                                Completed on{" "}
                                {moment(data.completed_on).format("ll")}
                              </div>
                            </div>
                            <div>
                              <CustomButton
                                className="w-100 unblock-btn"
                                handleClick={() => {
                                  dispatch(
                                    StudentUnblockAssessmentStart({
                                      userid: id,
                                      assId: data.assessment_id
                                    })
                                  );
                                }}
                                text="Unblock"
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Col>

              <Col xs={12} xl={6} className="mt-10 mt-xl-0">
                <div
                  className="reward-points-card border rounded-3 h-100 p-5 overflow-auto"
                  style={{ maxHeight: "696px" }}
                >
                  <div className="all-cards-names">Badges Earned</div>
                  <Row>
                    {indivisualBadgesLoader ? (
                      <CustomLoader />
                    ) : (
                      <>
                        {earnBadges && earnBadges.length ? (
                          <>
                            {earnBadges &&
                              earnBadges.map((_ele, index) => {
                                return (
                                  <Col xs={4} className="my-7" key={index}>
                                    <div
                                      className="small-box"
                                      style={{
                                        background: "#652581",
                                        opacity: 0.24,
                                      }}
                                    ></div>
                                    <div className="mt-4 text-center">
                                      {_ele.name}
                                    </div>
                                  </Col>
                                );
                              })}
                          </>
                        ) : (
                          <Col xs={12} className="text-center">
                            <div>No badges Earned</div>
                          </Col>
                        )}
                      </>
                    )}
                  </Row>
                  <div className="mt-10">All Badges</div>
                  <Row >
                    {badgesLoader ? (
                      <CustomLoader />
                    ) : (
                      <>
                        {allBadgesDetails && allBadgesDetails.length ? (
                          <>
                            {allBadgesDetails &&
                              allBadgesDetails.map((_ele, index) => {
                                return (
                                  <Col xs={4} className="my-7" key={index}>
                                    <div
                                      className="small-box m-auto"
                                      style={{
                                        background: "#D97A4F",
                                        opacity: 0.24,
                                      }}
                                    ></div>
                                    <div className="mt-4 text-center">
                                      {_ele.name}
                                    </div>
                                  </Col>
                                );
                              })}
                          </>
                        ) : (
                          <Col xs={12} className="text-center">
                            <div>No badges Earned</div>
                          </Col>
                        )}
                      </>
                    )}
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo(StudentProfile);
