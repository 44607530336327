import React, { useEffect, useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import Cut from "../../assets/Image/ManageSubAdmins/cut.svg";

function CreateDomainPopup(props) {
    return (
        <div >
            <Modal show={props.show} onHide={props.hide}>
                <div className=" p-10 ">
                    <div className="d-flex justify-content-end">
                        <div onClick={props.hide} style={{ cursor: 'pointer' }}>
                            <img src={Cut} alt="" />
                        </div>
                    </div>

                    <div className=" text-center font-weight-bolder" style={{ fontSize: "24px" }}>Create a domain</div>
                    <Form>

                        <Form.Group className="my-5">
                            <Form.Label className="font-size-3 mb-1 font-weight-bold text-black flex-grow-1">
                                Enter Title
                            </Form.Label>
                            <Form.Control
                                className="bg-light border rounded h-auto pb-2 pt-2 font-size-3 text-black"
                                type="text" name="name" placeholder="Title"
                            />

                        </Form.Group>


                        <Button className="bg-primary border-0 py-4 text-light fw-bolder rounded w-100 my-7" type="submit">
                            Create a domain
                        </Button>
                    </Form>
                </div>
            </Modal>
        </div>
    )
}

export default CreateDomainPopup