import React from "react";

const InputError = ({ error, className }) => {
  return (
    <div
      style={{
        color: "#f71e3f",
        fontSize: "13px",
        lineHeight: "19px",
        paddingTop: "2px"
      }}
      className={className}
    >
      {error}
    </div>
  );
};

export default InputError;
