import DeleteIcon from "../../assets/Image/Table/DeleteIcon.svg";
import EditIcon from "../../assets/Image/Table/EditIcon.svg";
import {
  commonGeneratePasswordStart,
  commonStatusChangeStart,
} from "../../redux/actions";

export const columns = ({
  dispatch, // DISPATCH
  setShow, // FORM MODAL
  setUserOnEdit, // WHEN EDIT USER SENT DATA TO EDIT MODAL
  setModalPermission, // MODAL PERMISSION TO CHECK USER WANT TO ADD EDIT
  setDeleteModalOpen, // DELETE MODAL
  setAdminID, // ID FOR DELETE USER
  pagenumber, // PAGINATION PAGE NUMBER
  role,
}) => {
  const columnValues = [
    {
      name: "SL No.",
      selector: (row, index) =>
        pagenumber === 1
          ? index + 1
          : index === 9
          ? `${pagenumber}0`
          : `${pagenumber - 1}${index + 1}`,
      width: "80px",
    },
    {
      name: "User ID",
      selector: (row) => row.id,
      style: {
        cursor: "default",
      },
      width: "100px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      style: {
        cursor: "default",
      },
      width: "200px",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      style: {
        cursor: "default",
      },
      width: "300px",
    },
    {
      name: "Department",
      selector: (row) => row.department,
      sortable: true,
      style: {
        cursor: "default",
      },
      width: "150px",
    },
    {
      name: "Generate Reset Password",
      center: true,
      cell: (row) => (
        <>
          <button
            className="border-0 py-2 px-7 bg-opacity-10 rounded-3 table-btn"
            style={{
              whiteSpace: "nowrap",
              backgroundColor: "#607f962e",
              borderRadius: "50px",
            }}
            onClick={() =>
              dispatch(commonGeneratePasswordStart({ email: row.email }))
            }
          >
            Generate Password
          </button>
        </>
      ),
      width: "250px",
    },
    {
      name: "Status",
      selector: (row) => {
        return (
          <>
            <span
              className="border-0 py-2 px-5 bg-opacity-10 table-btn"
              style={{
                backgroundColor: "#607f962e",
                borderRadius: "50px",
                cursor: "pointer",
              }}
              onClick={() => {
                const payload = {
                  id: row.id,
                  data: {
                    status: row.status === 1 ? 0 : 1,
                    department: row.department,
                  },
                };
                dispatch(commonStatusChangeStart(payload));
              }}
            >
              {row.status === 1 ? "Active" : "Inactive"}
            </span>
          </>
        );
      },
      center: true,
    },
    {
      name: "Action",
      center: true,
      cell: (row) => (
        <>
          <div className="d-flex align-items-center">
            <div
              className="mr-5"
              onClick={() => {
                setShow(true);
                setUserOnEdit(row);
                setModalPermission(true);
              }}
              style={{ cursor: "pointer" }}
            >
              <img src={EditIcon} alt="" />
            </div>
            {/* <div
              onClick={() => {
                setDeleteModalOpen(true);
                setAdminID(row.id);
              }}
              style={{ cursor: "pointer" }}
            >
              <img src={DeleteIcon} alt="" />
            </div> */}
          </div>
        </>
      ),
    },
  ];
  return columnValues;
};
