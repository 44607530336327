import React from "react";
import CustomTable from "../CustomTable";
import Union from "../../assets/Image/DashboardPage/Union.svg";
import { columns } from "../../pages/SchoolAdminDashboard/utils";
import { useNavigate } from "react-router-dom";

const SchoolAdminDashboardCard = ({
  index,
  bgColor,
  icon,
  count,
  text,
  data,
  loader,
}) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="SchoolAdminDashboardCard rounded-4 overflow-hidden bg-white ">
        <div className="p-7 d-flex align-items-center justify-content-between">
          <div className=" d-flex align-items-center ">
            <div className="position-relative">
              <div
                className="card-small-circle d-flex align-items-center justify-content-center"
                style={{ background: `${bgColor}` }}
              ></div>
              <div className="icon-main">
                <img src={icon} alt="" className="icon" />
              </div>
            </div>

            <div className="d-flex flex-column ml-6  ">
              <div className="counter-number text-left">{count}</div>
              <div className="counter-card-text">{text}</div>
            </div>
          </div>
          <div
            className="mb-4"
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              const type = {
                0: "subscribed",
                1: "presubscribed",
                2: "postsubscribed",
                3: "unsubscribed",
              };
              navigate(`/app/students`, { state: { param: type[index] } });
            }}
          >
            <img src={Union} alt="" style={{ opacity: "50%" }} />
          </div>
        </div>
        <CustomTable
          data={data}
          columns={columns({
            index,
            navigate,
          })}
          loader={loader}
        />
      </div>
    </>
  );
};

export default SchoolAdminDashboardCard;
