import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { toast } from "react-toastify";
import {
  cognitiveAssessmentsAction,
  cognitiveAssessmentsCountAction,
  cognitiveEmpty,
  downloadCognitiveDataStart,
} from "../../redux/cognitive-assessments/actions";
import SearchBox from "../../components/SearchBox/index";
import CounterCard from "../../components/CounterCard/index";
import CustomTable from "../../components/CustomTable/index";
import CreateDomainPopup from "../../components/CreateDomainPopup/index";
import { CONG_ASS_DATA } from "../../utils/constant";
import { columns } from "./utils";
import { options } from "../../utils/functions/commonFunctions";
import { Pagination } from "@mui/material";
import { CustomModal } from "../../components";
import AssementDownloadModal from "../../components/AssementDownloadModal/AssementDownloadModal";

const CognitiveAssessments = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [x_count, setx_count] = useState(20);
  const [pagenumber, setPagenumber] = useState(1);

  const [downloadAssementModal, setDownloadAssementModal] = useState(false); // DOWNLOAD ASSEMENT MODAL

  const [cognitiveAssessmentsData, setCognitiveAssessmentsData] =
    React.useState([]);
  const [show, setShow] = React.useState(false);
  const [department, setDepartment] = React.useState(0);
  const [filterCognitive, setFilterCognitive] = React.useState([]);
  const [cognitiveId, setCognitiveId] = useState();

  const {
    cognitive_assessments,
    cognitive_ass_count,
    cognitive_download,
    cognitiveAssessmentsLoader,
  } = useSelector((state) => state.cognitiveAssesSession);

  const tooltip = (
    <Tooltip id="tooltip" style={{ ".tooltip-arrow": "#f2f2f2" }}>
      <span style={{ padding: "5px" }}>
        You didn't have a permission to use this feature
      </span>
    </Tooltip>
  );

  const entering = (e) => {
    e.children[0].style.borderTopColor = "#000";
    e.children[1].style.backgroundColor = "#000";
  };

  useEffect(() => {
    initialLoad();
  }, [pagenumber]);

  const initialLoad = () => {
    dispatch(cognitiveAssessmentsAction(pagenumber));
    dispatch(cognitiveAssessmentsCountAction());
  };

  useEffect(() => {
    if (cognitive_assessments && cognitive_assessments) {
      setCognitiveAssessmentsData(cognitive_assessments);
    }
  }, [cognitive_assessments]);

  const handleClick = () => {
    navigate("/app/add-new-activity");
  };
  // MODAL OPEN CLOSE FUNCTION
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  useEffect(() => {
    const userdepartment = localStorage.getItem("department");

    userdepartment === "SuperAdmin"
      ? setDepartment(1)
      : userdepartment === "HelpDesk"
      ? setDepartment(2)
      : userdepartment === "Scientist"
      ? setDepartment(3)
      : null; // CHECK USER ROLE FOR ROUTING RISTRICTION
  }, []);

  useEffect(() => {
    // STUDENT PASSWORD RESET
    if (cognitive_download.code === 200) {
      toast(cognitive_download.mesg, options);
      setDownloadAssementModal(false);

      if (cognitive_download.data?.location) {
        window.location.href = cognitive_download.data?.location;
      }
      dispatch(cognitiveEmpty());
    } else if (cognitive_download.statusCode === 422) {
      toast.error(cognitive_download.message, {
        position: "top-right",
        autoClose: 3000,
        pauseOnHover: true,
      });
      dispatch(cognitiveEmpty());
    }
  }, [cognitive_download]);

  const handlepagoination = (event, value) => {
    setPagenumber(value);
  };

  const handleFilterdata = (value) => {
    setFilterCognitive(value);
  };

  const handleFilter = (value) => {};
  const handleCloseDownloadModal = (e) => {
    setDownloadAssementModal(false);
  };

  return (
    <div className=" p-7  cognitive-asses-main-div">
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="fw-bold mb-7 page-head">Cognitive Assessments</h4>

        <div className="d-flex justify-content-between align-items-center">
          {department === 1 ? (
            <></>
          ) : (
            <>
              <OverlayTrigger
                className="d-flex flex-column align-items-center justify-content-center h-100"
                placement={"bottom"}
                overlay={tooltip}
                onEntering={entering}
              >
                <button className="first-button mr-5">Add New Activity</button>
              </OverlayTrigger>
              <OverlayTrigger
                className="d-flex flex-column align-items-center justify-content-center h-100"
                placement={"bottom"}
                overlay={tooltip}
                onEntering={entering}
              >
                <button className="second-button" type="button">
                  Create a domain
                </button>
              </OverlayTrigger>
            </>
          )}

          <CreateDomainPopup show={show} hide={handleClose} />
        </div>
      </div>

      <Row className="mb-5 mt-7">
        {cognitive_ass_count &&
          CONG_ASS_DATA(cognitive_ass_count).map((data, index) => {
            return (
              <Col
                xs={12}
                md={6}
                lg={4}
                xl={3}
                className="mt-10 mt-xl-0"
                key={index}
              >
                <CounterCard cardDetails={data} />
              </Col>
            );
          })}
      </Row>
      <div className="mt-7">
        <SearchBox
          InputplaceHolder="Search Cognitive Assessment"
          DropDown={false}
          API={cognitiveAssessmentsData}
          handleFilter={handleFilter}
          handleFilterdata={handleFilterdata}
        />
      </div>
      {/* === [] ? [] : cognitiveAssessmentsData */}
      <CustomTable
        columns={columns({
          dispatch,
          downloadCognitiveDataStart,
          pagenumber,
          setDownloadAssementModal,
          setCognitiveId,
        })}
        data={
          filterCognitive.length === 0
            ? cognitiveAssessmentsData
            : filterCognitive
        }
        dataPerPage={10}
        loader={cognitiveAssessmentsLoader}
      />
      <div
        style={{
          height: "100px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Pagination
          count={1}
          variant="outlined"
          shape="rounded"
          // onChange={handlepagoination}
          disabled={cognitiveAssessmentsLoader}
        />
      </div>
      <CustomModal
        show={downloadAssementModal}
        handleClose={() => setDownloadAssementModal(false)}
      >
        <AssementDownloadModal
          onHide={() => setDownloadAssementModal(false)}
          condition={{
            type: "COGNITIVEASS",
            payload: cognitiveId,
          }}
        />
      </CustomModal>
    </div>
  );
};

export default CognitiveAssessments;
