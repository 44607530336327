import {
  STUDENT_UPLOAD_EXCEL_START,
  STUDENT_UPLOAD_EXCEL_SUCCESS,
  STUDENT_UPLOAD_EXCEL_ERROR,
  UPLOAD_EMPTY,
} from "../../actions";

// UPLOAD STUDENT
export const studentUploadStartAction = (data) => ({
  type: STUDENT_UPLOAD_EXCEL_START,
  payload: data,
});

export const studentUploadSuccess = (data) => ({
  type: STUDENT_UPLOAD_EXCEL_SUCCESS,
  payload: data,
});

// ERROR
export const UploadError = (data) => ({
  type: STUDENT_UPLOAD_EXCEL_ERROR,
  payload: data,
});
// EMPTY
export const uploadEmpty = () => ({
  type: UPLOAD_EMPTY,
  payload: [],
});
