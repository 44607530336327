import {
  COMMON_GENERATE_PASSWORD_START,
  COMMON_GENERATE_PASSWORD_SUCCESS,
  COMMON_GENERATE_PASSWORD_ERROR,
  COMMON_STATUS_CHANGE_START,
  COMMON_STATUS_CHANGE_SUCCESS,
  COMMON_STATUS_CHANGE_ERROR,
  COMMON_DELETE_START,
  COMMON_DELETE_SUCCESS,
  COMMON_DELETE_ERROR,
  COMMON_EMPTY_SUCCESS,
} from "../actions";

const INIT_STATE = {
  commonloader: false,
  commonerror: "",
  generate_pass: [],
  status_change: [],
  delete_data: [],
};

const commonSession = (state = INIT_STATE, action) => {
  switch (action.type) {
    case COMMON_GENERATE_PASSWORD_START:
    case COMMON_STATUS_CHANGE_START:
    case COMMON_DELETE_START:
      return {
        ...state,
        commonloader: true,
      };
    case COMMON_GENERATE_PASSWORD_SUCCESS:
      return {
        ...state,
        commonloader: false,
        generate_pass: action.payload,
      };
    case COMMON_STATUS_CHANGE_SUCCESS:
      return {
        ...state,
        commonloader: false,
        status_change: action.payload,
      };
    case COMMON_DELETE_SUCCESS:
      return {
        ...state,
        commonloader: false,
        delete_data: action.payload,
      };
    case COMMON_GENERATE_PASSWORD_ERROR:
    case COMMON_STATUS_CHANGE_ERROR:
    case COMMON_DELETE_ERROR:
      return {
        ...state,
        commonloader: false,
        commonerror: action.payload,
      };
    case COMMON_EMPTY_SUCCESS:
      return {
        commonerror: "",
        generate_pass: [],
        status_change: [],
        delete_data: [],
      };
    default:
      return { ...state };
  }
};

export default commonSession;
