import * as yup from "yup";

export const InitialValues = {
  name: "",
  email: "",
  password: "",
  department: "",
  status: "1",
};

export const adminValidationSchema = yup.object({
  name: yup.string().required("Field is required"),
  email: yup
    .string()
    .email("Invalid Email Address")
    .required("Field is required"),
  password: yup
    .string()
    .required("Field is required")
    .min(8, "Must be Minimum 8 characters"),
  department: yup.string().required("Field is required"),
  status: yup.string().required("Field is required"),
});

export const parseAddData = (values) => {
  return {
    name: values.name,
    email: values.email,
    password: values.password,
    department: values.department,
    status: parseInt(values.status),
  };
};

export const parseUpdateData = (values, data) => {
  return {
    id: data.id,
    name: values.name,
    email: values.email,
    department: values.department,
    status: parseInt(values.status),
  };
};
