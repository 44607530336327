import { isJSON } from "../isJSON";
import { toast } from "react-toastify";

export const countProfileCompletion = (row) => {
  let counter = 100;
  if (row?.extra_details === "{}" || row?.extra_details === "") {
    counter -= 25;
  }
  if (row?.parent_details) {
    if (row?.parent_details?.length === 0) {
      counter -= 25;
    }
  }
  if (row?.school_details) {
    if (isJSON(row?.school_details)) {
      const aa = JSON.parse(row?.school_details);
      if (aa.school_name == null || aa.school_name === "") {
        counter -= 25;
      }
    }
  } else {
    counter -= 25;
  }
  if (row?.category == null) {
    counter -= 5;
  }
  if (row?.gender == null) {
    counter -= 5;
  }
  return counter;
};

export const options = {
  autoClose: 2000,
  type: toast.TYPE.SUCCESS,
  hideProgressBar: true,
  position: toast.POSITION.TOP_RIGHT,
  pauseOnHover: true,
  draggable: true,
  closeOnClick: true,
  style: {
    background: "#607f96",
    color: "#fff",
  },
};

export const addZeroIfNeeded = (val) => {
  return val < 10 ? "0" + val : val;
};
