import {
  STUDENT_UPLOAD_EXCEL_START,
  STUDENT_UPLOAD_EXCEL_SUCCESS,
  STUDENT_UPLOAD_EXCEL_ERROR,
  UPLOAD_EMPTY,
} from "../../actions";

const INIT_STATE = {
  uploadLoader: false,
  uploadError: "",
  upload_Student: [],
};

const uploadDetailSession = (state = INIT_STATE, action) => {
  switch (action.type) {
    case STUDENT_UPLOAD_EXCEL_START:
      return {
        ...state,
        uploadLoader: true,
      };
    case STUDENT_UPLOAD_EXCEL_SUCCESS:
      return {
        ...state,
        uploadLoader: true,
        upload_Student: action.payload,
      };
    case STUDENT_UPLOAD_EXCEL_ERROR:
      return {
        ...state,
        uploadLoader: true,
        uploadError: action.payload,
      };
    case UPLOAD_EMPTY:
      return {
        uploadLoader: false,
        uploadError: "",
        upload_Student: [],
      };
    default:
      return { ...state };
  }
};

export default uploadDetailSession;
