import {
  GET_SCHOOL_SUBADMIN_START,
  GET_SCHOOL_SUBADMIN_SUCCESS,
  ADD_SCHOOL_SUBADMIN_START,
  ADD_SCHOOL_SUBADMIN_SUCCESS,
  EDIT_SCHOOL_SUBADMIN_START,
  EDIT_SCHOOL_SUBADMIN_SUCCESS,
  SCHOOLS_SUBADMIN_ERROR,
  SCHOOL_SUBADMIN_EMPTY,
} from "../actions";

// GET SCHOOL ADMIN
export const getSchoolAdminStart = (data) => ({
  type: GET_SCHOOL_SUBADMIN_START,
  payload: data,
});
export const getSchoolAdminSuccess = (data) => ({
  type: GET_SCHOOL_SUBADMIN_SUCCESS,
  payload: data,
});

// ADD SCHOOL ADMIN
export const addSchoolAdminStart = (data) => ({
  type: ADD_SCHOOL_SUBADMIN_START,
  payload: data,
});
export const addSchoolAdminSuccess = (data) => ({
  type: ADD_SCHOOL_SUBADMIN_SUCCESS,
  payload: data,
});

// EDIT SCHOOL ADMIN
export const editSchoolAdminStart = (data) => ({
  type: EDIT_SCHOOL_SUBADMIN_START,
  payload: data,
});
export const editSchoolAdminSuccess = (data) => ({
  type: EDIT_SCHOOL_SUBADMIN_SUCCESS,
  payload: data,
});

// SCHOOL SUB ADMIN ERROR
export const schoolSubadminError = (data) => ({
  type: SCHOOLS_SUBADMIN_ERROR,
  payload: data,
});

// SCHOOL SUB ADMIN EMPTY
export const schoolSubadminEmpty = () => ({
  type: SCHOOL_SUBADMIN_EMPTY,
  payload: [],
});
