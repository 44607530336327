import {
    STUDENT_RESET_PASSWORD_START,
    STUDENT_RESET_PASSWORD_SUCCESS,
    STUDENT_RESET_PASSWORD_ERROR,
    STUDENT_RESET_PASSWORD_EMPTY
} from "../actions";


const INIT_STATE = {
    loder: false,
    error: "",
    StudentResetPassword: [],
};

const studentResetPasswordSession = (state = INIT_STATE, action) => {
    switch (action.type) {
        case STUDENT_RESET_PASSWORD_START:
            return {
                ...state,
                loder: true,
            };
        case STUDENT_RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                loder: false,
                StudentResetPassword: action.payload,
                error: "",
            };
        case STUDENT_RESET_PASSWORD_EMPTY:
            return {
                ...state,
                loder: false,
                StudentResetPassword: [],
                error: "",
            };
        case STUDENT_RESET_PASSWORD_ERROR:
            return {
                ...state,
                loder: false,
                error: action.payload.message,
            };
        default:
            return { ...state };
    }
};

export default studentResetPasswordSession;