import * as yup from "yup";

//Sign In Form
export const validationSchema_signIn = yup.object().shape({
  email: yup.string().required("Please Provide Email!"),
  password: yup.mixed().required("Please Provide Password!"),
});

// Admin Form
export const adminValidationSchema = yup.object({
  name: yup.string().required("Please Provide Name"),
  email: yup
    .string()
    .email("Invalid Email Address")
    .required("Please Provide Email"),
  password: yup.mixed().required("Please Provide Password"),
  department: yup.string().required("Please Provide Department"),
  status: yup.string().required("Please Provide Status"),
});


//Student Feedback
export const feedback_validationSchema = yup.object().shape({
  feedback: yup.string().required("Please Provide Feedback!"),
});
