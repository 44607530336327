import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import IconSearch from "../../assets/Image/Topbar/IconSearch.svg";
import IconBell from "../../assets/Image/Topbar/IconBell.svg";
import { useNavigate } from "react-router-dom";
import { adminLogout } from "../../redux/actions";
import { useDispatch } from "react-redux";
import OneUser from "../../assets/Image/Sidebar/OneUser.svg";

function Topbar() {
  const navigate = useNavigate(); // GET CURRENT PATHNAME
  const dispatch = useDispatch();

  const [userDetails, setUserDetails] = useState({
    name: "",
    department: "",
  });

  const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("department");
    dispatch(adminLogout());
    navigate("/login");
  };

  useEffect(() => {
    const username = localStorage.getItem("username");
    const userdepartment = localStorage.getItem("department");
    const department = {
      SuperAdmin: "Super Admin",
      HelpDesk: "Help Desk",
      Scientist: "Scientist",
      School: "School",
      SchoolAdmin: "School SubAdmin",
    };
    if (username && userdepartment) {
      setUserDetails({
        name: username,
        department: department[userdepartment],
      });
    }
  }, []);

  const [showLogoutDiv, setshowLogoutDiv] = useState(false);
  return (
    <div style={{ position: "sticky", top: "0", zIndex: "100 " }}>
      <div className="topbar-main-div">
        <div className="px-10">
          <Row>
            {/* <Col className="d-flex align-items-center search-box-box">
              <div className="mr-4">
                <img src={IconSearch} alt="" />
              </div>
              <input type="text" placeholder="Enter keywords ..." />
            </Col> */}
            <Col className="d-flex justify-content-end  ">
              <div
                className="rounded-div d-flex  align-items-center justify-content-center mr-5 position-relative"
                style={{ cursor: "pointer" }}
              >
                <img src={IconBell} alt="" />
                <div className="small-circle" />
              </div>
              <div
                className="rounded-div d-flex align-items-center justify-content-center mr-5 position-relative cursor-pointer"
                style={{ cursor: "pointer" }}
                onMouseEnter={() => setshowLogoutDiv(true)}
              >
                <img src={OneUser} alt="" />
              </div>

              {showLogoutDiv ? (
                <div
                  className="p-7 bg-white position-absolute logout-popup-div  shadow "
                  onMouseLeave={() => setshowLogoutDiv(false)}
                >
                  <div className="d-flex align-items-center">
                    <div
                      className="rounded-div d-flex align-items-center justify-content-center mr-5 position-relative cursor-pointer "
                      style={{ height: "60px", width: "60px" }}
                    >
                      {/* <img src="" alt="" style={{ height: "40px", width: "40px" }} /> */}
                      {/* <img src={OneUser} alt="" /> */}
                    </div>
                    <div>
                      <div
                        style={{ fontSize: "16px", lineHeight: "11px" }}
                        className="font-weight-bold mt-2"
                      >
                        {userDetails.name}
                      </div>
                      <div style={{ fontSize: "12px" }} className="pt-1">
                        {userDetails.department}
                      </div>
                    </div>
                  </div>
                  <hr className="my-5" />
                  <div
                    className="text-primary font-weight-bold "
                    style={{ cursor: "pointer" }}
                    onClick={() => logout()}
                  >
                    Logout
                  </div>
                </div>
              ) : (
                ""
              )}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default Topbar;
