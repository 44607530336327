import IconBarChart from "../../assets/Image/DashboardPage/icon-bar-chart.svg";
import IconFeather from "../../assets/Image/DashboardPage/icon-feather.svg";
import IconMail from "../../assets/Image/DashboardPage/icon-mail.svg";

export const _counterCardData = [
    {
      id: 1,
      number: "N/A",
      text: "Total Subscribed Users",
      icon: ` ${IconBarChart}`,
      bgColor: "#2F49D1",
    },
    {
      id: 1,
      number: "N/A",
      text: "Daily Active Users",
      icon: ` ${IconFeather}`,
      bgColor: "#4BDE97",
    },
    {
      id: 2,
      number: "N/A",
      text: "Mobile App Users",
      icon: ` ${IconBarChart}`,
      bgColor: "#2F49D1",
    },
    {
      id: 3,
      number: "34,445,345",
      text: "Total Registered Users",
      icon: ` ${IconMail}`,
      bgColor: "#5F2EEA",
    },
  ];