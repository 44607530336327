import React, { Suspense, useEffect, useState } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import "react-toastify/dist/ReactToastify.css";
import { PrivateRoute } from "./PrivateRoutes";
import { isAuth, isLoggedInUser, isRole } from "../utils/isLogin";
import { PrivateRoutesRoles } from "./routes";

const SignInFrom = React.lazy(() => import("../pages/SignInFrom"));
const ResetPassword = React.lazy(() => import("../pages/ResetPassword"));
const NotFound = React.lazy(() => import("../pages/NotFound"));

const AppRoutes = () => {
  const location = useLocation();
  const path = location.pathname;
  const navigate = useNavigate();
  const auth = isAuth();
  const role = isRole();
  const [sidebarRemove, setSidebarRemove] = useState(false);
  const [sidebarSize, setSidebarSize] = useState(false);

  useEffect(() => {
    if (path === "/") {
      navigate("/app");
    }
    if (path === "/login" || path.includes("/reset-password")) {
      setSidebarSize(false);
    }
    if (auth) {
      setSidebarRemove(false);
    } else {
      setSidebarRemove(true);
    }
    if (
      (role === "HelpDesk" && path === "/app") ||
      (role === "Scientist" && path === "/app")
    ) {
      navigate("/app/cognitive-assessments");
    }
    if (
      (role === "School" && path === "/app") ||
      (role === "SchoolAdmin" && path === "/app")
    ) {
      navigate("/app/school-dashboard");
    }
  }, [path]);

  return (
    <Suspense
      fallback={
        <div
          style={{
            width: "100vw!important",
            height: "100vh!important",
            display: "flex!important",
            justifyContent: "center!important",
            alignContent: "center!important",
          }}
        >
          Loading...
        </div>
      }
    >
      <div className="d-flex w-100 h-100">
        <div
          style={{ width: sidebarSize ? "15%" : sidebarRemove ? "0%" : "4%" }}
          className={`sidebar ${
            sidebarSize ? "overflow-auto" : "overflow-hidden"
          }`}
        >
          <div className="bg-primary">
            {sidebarRemove ? null : (
              <Sidebar openSidebar={(value) => setSidebarSize(value)} />
            )}
          </div>
        </div>
        <div
          style={{
            width: sidebarSize ? "85%" : sidebarRemove ? "100%" : "96%",
          }}
          className="route"
        >
          {sidebarRemove ? (
            <div
              style={{
                display: "none",
              }}
            />
          ) : (
            <Topbar />
          )}
          <Routes>
            <Route path="/login" element={<SignInFrom />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="*" element={<NotFound />} />

            {PrivateRoutesRoles.map((route, index) => {
              return (
                <Route
                  key={index}
                  path={route.route}
                  element={
                    <PrivateRoute
                      roles={route.roles}
                      component={route.component}
                    />
                  }
                />
              );
            })}
          </Routes>
        </div>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="colored"
      />
    </Suspense>
  );
};

export default AppRoutes;
