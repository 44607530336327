import {
  GET_BADGES,
  GET_BADGES_SUCCESS,
  GET_BADGES_BY_ID,
  GET_BADGES_BY_ID_SUCCESS,
  GET_BADGES_ERROR,
} from "../actions";

export const badgesAction = (data) => ({
  type: GET_BADGES,
  payload: data,
});

export const badgesSuccess = (data) => ({
  type: GET_BADGES_SUCCESS,
  payload: data,
});

//Get badges ById
export const badgesByIdAction = (data) => ({
  type: GET_BADGES_BY_ID,
  payload: data,
});

export const badgesByIdSuccess = (data) => ({
  type: GET_BADGES_BY_ID_SUCCESS,
  payload: data,
});

export const badgesError = (message) => ({
  type: GET_BADGES_ERROR,
  payload: message,
});
