import { all } from "redux-saga/effects";
import studentDetailsSagas from "./studentDetails/saga";
import cognitiveAssessmentsSagas from "./cognitive-assessments/saga";
import adminsSagas from "./admins/saga";
import loginSagas from "./login/saga";
import StudentrResetPasswordMail from "./studentResetPassword/saga";
import schoolSagas from "./School/saga";
import SchoolAdmin from "./SchoolSubAdmin/saga";
import UploadStudent from "./UploadViaExcel/StudentUpload/saga";
import commonSaga from "./common/saga";
import allBadgesSaga from "./badges/saga";

export default function* rootSaga() {
  yield all([
    studentDetailsSagas(),
    cognitiveAssessmentsSagas(),
    adminsSagas(),
    loginSagas(),
    StudentrResetPasswordMail(),
    schoolSagas(),
    SchoolAdmin(),
    UploadStudent(),
    commonSaga(),
    allBadgesSaga(),
  ]);
}
