import React from "react";
import Loader from "../../assets/Image/loader.gif";

const CustomLoader = () => {
  return (
    <>
      <img src={Loader} alt="loader_cognitive" />
    </>
  );
};

export default CustomLoader;
