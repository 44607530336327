import {
  ADD_STUDENT_START,
  ADD_STUDENT_SUCCESS,
  DELETE_STUDENT_DETAILS_START,
  DELETE_STUDENT_DETAILS_SUCCESS,
  REGISTER_STUDENT_START,
  REGISTER_STUDENT_SUCCESS,
  STUDENT_BY_ID_START,
  STUDENT_BY_ID_SUCCESS,
  STUDENT_COMPLETED_ASSESSMENT_START,
  STUDENT_COMPLETED_ASSESSMENT_SUCCESS,
  STUDENT_DETAILS,
  STUDENT_DETAILS_SUCCESS,
  STUDENT_DOWNLOAD_START,
  STUDENT_DOWNLOAD_SUCCESS,
  STUDENT_EMPTY,
  STUDENT_ERROR,
  STUDENT_FEEBACK_START,
  STUDENT_FEEBACK_SUCCESS,
  STUDENT_STATUS_UPDATE,
  STUDENT_STATUS_UPDATE_SUCCESS,
  STUDENT_TRAINING_START,
  STUDENT_TRAINING_SUCCESS,
  STUDENT_UNBLOCK_ASSESSMENT_START,
  STUDENT_UNBLOCK_ASSESSMENT_SUCCESS,
  STUDENT_UPDATE_START,
  STUDENT_UPDATE_SUCCESS,
} from '../actions';

// GET STUDENT
export const getStudentDeatilsStart = (data) => ({
  type: STUDENT_DETAILS,
  payload: data,
});
export const getStudentDeatilsSuccess = (data) => ({
  type: STUDENT_DETAILS_SUCCESS,
  payload: data,
});


// STUDENT GET BY ID
export const getStudentByIdStart = (data) => ({
  type: STUDENT_BY_ID_START,
  payload: data,
});
export const getStudentByIdSuccess = (data) => ({
  type: STUDENT_BY_ID_SUCCESS,
  payload: data,
});

// ADD STUDENT
export const addStudentStart = (data) => ({
  type: ADD_STUDENT_START,
  payload: data,
});
export const addStudentSuccess = (data) => ({
  type: ADD_STUDENT_SUCCESS,
  payload: data,
});

// REGISTER STUDENT
export const registerStudentStart = (data) => ({
  type: REGISTER_STUDENT_START,
  payload: data,
});
export const registerStudentSuccess = (data) => ({
  type: REGISTER_STUDENT_SUCCESS,
  payload: data,
});

// STATUS UPDATE
export const studentStatusUpdateAction = (data) => {
  return {
    type: STUDENT_STATUS_UPDATE,
    payload: data,
  };
};
export const studentStatusUpdateSuccess = (data) => ({
  type: STUDENT_STATUS_UPDATE_SUCCESS,
  payload: data,
});

// UPDATE STUDENT
export const studentUpdateStart = (data) => ({
  type: STUDENT_UPDATE_START,
  payload: data,
});
export const studentUpdateSuccess = (data) => ({
  type: STUDENT_UPDATE_SUCCESS,
  payload: data,
});

// STUDENT FEEDBACK
export const studentFeedbackUpdateStart = (data) => ({
  type: STUDENT_FEEBACK_START,
  payload: data,
});
export const studentFeedbackUpdateSuccess = (data) => ({
  type: STUDENT_FEEBACK_SUCCESS,
  payload: data,
});

// DELETE STUDENT
export const deleteStudentDetailsAction = (data) => ({
  type: DELETE_STUDENT_DETAILS_START,
  payload: data,
});
export const deleteStudentDetailsSuccess = (data) => ({
  type: DELETE_STUDENT_DETAILS_SUCCESS,
  payload: data,
});

// DOWNLOAD STUDENT
export const downloadStudentDataStart = (data) => ({
  type: STUDENT_DOWNLOAD_START,
  payload: data,
});
export const downloadStudentDataSuccess = (data) => ({
  type: STUDENT_DOWNLOAD_SUCCESS,
  payload: data,
});

//DOWNLOAD TRAINING DATA
export const downloadTrainingDataStart = (data) => ({
  type: STUDENT_TRAINING_START,
  payload: data,
});

export const downloadTrainingDataSuccess = (data) => ({
  type: STUDENT_TRAINING_SUCCESS,
  payload: data,
});


// COMPLETED ASSESSMENTS
export const StudentCompletedAssessmentStart = (data) => ({
  type: STUDENT_COMPLETED_ASSESSMENT_START,
  payload: data,
});
export const StudentCompletedAssessmentSuccess = (data) => ({
  type: STUDENT_COMPLETED_ASSESSMENT_SUCCESS,
  payload: data,
});
// UNBLOCK ASSESSMENT
export const StudentUnblockAssessmentStart = (data) => ({
  type: STUDENT_UNBLOCK_ASSESSMENT_START,
  payload: data,
});
export const StudentUnblockAssessmentSuccess = (data) => ({
  type: STUDENT_UNBLOCK_ASSESSMENT_SUCCESS,
  payload: data,
});

// STUDENT ERROR
export const studentError = (data) => ({
  type: STUDENT_ERROR,
  payload: data,
});

// EMPTY FUNCTION
export const studentEmpty = () => ({
  type: STUDENT_EMPTY,
  payload: [],
});
