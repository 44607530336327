import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Cut from "../../assets/Image/ManageSubAdmins/cut.svg";
import { CustomButton, InputGroup } from "../../components";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import {
  parseAddSubadmin,
  parseEditSubadmin,
  schoolAdminInitialValue,
  schoolAdminValidationSchema,
} from "./utils";
import { addSchoolAdminStart, editSchoolAdminStart } from "../../redux/actions";
import { isRole, isSchoolCode, isUserId } from "../../utils/isLogin";

const SchoolAdminForm = ({
  handleClose,
  modalpermission,
  editUserDetails,
  loader,
}) => {
  const role = isRole();
  const userId = isUserId();
  const dispatch = useDispatch();
  const schoolCode = isSchoolCode();
  const [para, setPara] = useState({
    title: "Add School SubAdmin",
    button: "Add User",
  });

  const onSubmit = async (values) => {
    if (!modalpermission) {
      const addPayload = parseAddSubadmin(values, schoolCode);
      dispatch(addSchoolAdminStart(addPayload));
    } else if (modalpermission) {
      const editPayload = parseEditSubadmin(values, editUserDetails);
      dispatch(editSchoolAdminStart(editPayload));
    }
  };

  useEffect(() => {
    // IF MODALPERMISSION TRUE USER WANT TO EDIT || IF MODALPERMISSION FALSE USER WANT TO ADD
    if (modalpermission) {
      const field = ["name", "email", "subadmin_dep", "phone", "password"];
      const value = [
        editUserDetails?.name,
        editUserDetails?.email,
        editUserDetails?.subadmin_dep,
        editUserDetails?.phone,
        "nullvalue",
      ];
      setPara({
        title: "Update School Sub-Admin",
        button: "Update User",
      });
      for (let [index, fields] of field.entries()) {
        formik.setFieldValue(fields, value[index]);
      }
    } else {
      const field = ["name", "email", "subadmin_dep", "phone", "password"];
      setPara({
        title: "Add School Sub-Admin",
        button: "Add User",
      });
      for (let fields of field) {
        formik.setFieldValue(fields, "");
      }
    }
  }, [modalpermission]); // CHECK IF USER WANT TO EDIT USER

  const formik = useFormik({
    initialValues: schoolAdminInitialValue,
    onSubmit,
    validationSchema: schoolAdminValidationSchema,
  }); // FORMIK
  return (
    <>
      <div className=" p-10 ">
        <div className="d-flex justify-content-end">
          <div onClick={handleClose} style={{ cursor: "pointer" }}>
            <img src={Cut} alt="" />
          </div>
        </div>

        <div className="modal-title-text text-center">{para.title}</div>
        <Form onSubmit={formik.handleSubmit}>
          <InputGroup
            label="Name"
            type="text"
            name="name"
            placeholder="Name"
            {...formik.getFieldProps("name")}
            onChange={formik.handleChange}
            error={formik.touched.name && formik.errors.name}
          />
          <InputGroup
            label="Subject Department"
            type="text"
            name="subadmin_dep"
            placeholder="Subject Department"
            {...formik.getFieldProps("subadmin_dep")}
            onChange={formik.handleChange}
            error={formik.touched.subadmin_dep && formik.errors.subadmin_dep}
          />
          <InputGroup
            label="Email"
            type="email"
            name="email"
            placeholder="Email"
            {...formik.getFieldProps("email")}
            onChange={formik.handleChange}
            error={formik.touched.email && formik.errors.email}
          />
          <InputGroup
            label="Phone"
            type="number"
            name="phone"
            placeholder="Phone"
            {...formik.getFieldProps("phone")}
            onChange={formik.handleChange}
            error={formik.touched.phone && formik.errors.phone}
          />
          {!modalpermission ? (
            <InputGroup
              label="Password"
              type="password"
              name="password"
              placeholder="Password"
              {...formik.getFieldProps("password")}
              onChange={formik.handleChange}
              error={formik.touched.password && formik.errors.password}
            />
          ) : null}

          <CustomButton
            className="bg-primary border-0 py-4 text-light fw-bolder rounded w-100 my-7"
            type="submit"
            text={loader ? "Loading..." : para.button}
            disabled={loader}
          />
        </Form>
      </div>
    </>
  );
};

export default SchoolAdminForm;
