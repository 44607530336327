export const initialValues = {
  student_name: "",
  student_phone: "",
  student_email: "",
  student_gender: "",
  student_dob: "",
  student_category: "",
  student_avatar: "",
  school_name: "",
  school_board: "",
  school_code: "",
  school_class: "",
  school_section: "",
  school_core_subjects: "",
  school_study_medium: "",
  school_city: "",
  school_state: "",
  school_pin_code: "",
  school_country: "",
  mentor_name: "",
  mentor_type: "Teacher",
  mentor_email: "",
  mentor_phone: "",
  name: "",
  phone: "",
  email: "",
  gender: "",
  relationship: "",
};

export const _class_values = [
  { value: "3", lable: "3rd" },
  { value: "4", lable: "4th" },
  { value: "5", lable: "5th" },
  { value: "6", lable: "6th" },
  { value: "7", lable: "7th" },
  { value: "8", lable: "8th" },
  { value: "9", lable: "9th" },
  { value: "10", lable: "10th" },
  { value: "11", lable: "11th" },
  { value: "12", lable: "12th" },
  { value: "Others", lable: "Others" },
];

export const _board_values = [
  { value: "cbse", lable: "CBSE" },
  { value: "icsc", lable: "ICSC" },
];

export const _student_category = [
  { value: "General", lable: "General" },
  { value: "OBC", lable: "OBC" },
  { value: "SCST", lable: "SC/ST" },
  { value: "Others", lable: "Others" },
];

export const _core_subjects = [
  { value: "Non-Medical (PCM)", lable: "Non-Medical (PCM)" },
  { value: "Medical (PCB)", lable: "Medical (PCB)" },
];

export const _study_medium = [
  { value: "english", lable: "English" },
  { value: "hindi", lable: "Hindi" },
];

export const _relationship = [
  { value: "father", lable: "Father" },
  { value: "mother", lable: "Mother" },
];

export const _parent_gender = [
  { value: "Male", lable: "Male" },
  { value: "Female", lable: "Female" },
];

export const _native_language = [
  { value: "english", lable: "English" },
  { value: "hindi", lable: "Hindi" },
];

export const _mentor_type = [
  { value: "Teacher", lable: "Teacher" },
  { value: "Tuition Teacher", lable: "Tuition Teacher" },
  { value: "Others", lable: "Others" },
];
