import AcademicAssessments from '../pages/AcademicAssessments';
import CognitiveAssessments from '../pages/CognitiveAssessments';
import AddNewActivity from '../pages/CognitiveAssessments/AddNewActivity';
import CognitiveTraining from '../pages/CognitiveTraining';
import DashboardPage from '../pages/DashboardPage';
import ManageSubAdmins from '../pages/ManageAdmin';
import SchoolAdminDashboard from '../pages/SchoolAdminDashboard';
import Schools from '../pages/Schools';
import AddNewSchoolStudent from '../pages/Schools/AddNewSchoolStudent';
import SchoolSubAdmins from '../pages/SchoolSubAdmins';
import Students from '../pages/Students';
import AddNewStudent from '../pages/Students/AddNewStudent';
import StudentProfile from '../pages/Students/StudentProfile';
import UploadStudent from '../pages/UploadViaExcel/Student/UploadStudent';

export const PrivateRoutesRoles = [
  {
    route: "/app",
    roles: ["SuperAdmin"],
    component: DashboardPage,
  },
  {
    route: "/app/manage-sub-admins",
    roles: ["SuperAdmin"],
    component: ManageSubAdmins,
  },
  {
    route: "/app/schools",
    roles: ["SuperAdmin"],
    component: Schools,
  },
  {
    route: "/app/academic-assessments",
    roles: ["SuperAdmin"],
    component: AcademicAssessments,
  },
  {
    route: "/app/cognitive-assessments",
    roles: ["SuperAdmin", "HelpDesk", "Scientist"],
    component: CognitiveAssessments,
  },
  {
    route: "/app/add-new-activity",
    roles: ["SuperAdmin", "HelpDesk", "Scientist"],
    component: AddNewActivity,
  },
  {
    route: "/app/cognitive-training",
    roles: ["SuperAdmin", "HelpDesk", "Scientist"],
    component: CognitiveTraining,
  },
  {
    // STUDENT ROUTES
    route: "/app/students",
    roles: ["SuperAdmin", "HelpDesk", "Scientist", "School", "SchoolAdmin"],
    component: Students,
  },
  {
    route: "/app/add-new-student",
    roles: ["SuperAdmin", "School", "SchoolAdmin"],
    component: AddNewStudent,
  },
  {
    route: "/app/add-new-student/:id",
    roles: ["SuperAdmin", "School", "SchoolAdmin"],
    component: AddNewStudent,
  },
  {
    route: "/app/students/:id",
    roles: ["SuperAdmin", "HelpDesk", "Scientist", "School", "SchoolAdmin"],
    component: StudentProfile,
  },
  {
    // SCHOOL ROUTES
    route: "/app/upload-student",
    roles: ["School", "SchoolAdmin","SuperAdmin"],
    component: UploadStudent,
  },
  {
    route: "/app/school-subadmins",
    roles: ["School"],
    component: SchoolSubAdmins,
  },
  {
    route: "/app/school-dashboard",
    roles: ["School", "SchoolAdmin"],
    component: SchoolAdminDashboard,
  },
  {
    route: "/app/add-school-student",
    roles: ["SuperAdmin", "HelpDesk", "Scientist", "School", "SchoolAdmin"],
    component: AddNewSchoolStudent,
  },
];
