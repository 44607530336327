import {
  GET_BADGES,
  GET_BADGES_SUCCESS,
  GET_BADGES_BY_ID,
  GET_BADGES_BY_ID_SUCCESS,
  GET_BADGES_ERROR,
} from "../actions";

const INIT_STATE = {
  badgesLoader: false,
  indivisualBadgesLoader: false,
  error: "",
  all_badges: [],
  indivisual_badges: [],
};

const badgesSession = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BADGES:
      return {
        ...state,
        badgesLoader: true,
      };

    case GET_BADGES_SUCCESS:
      return {
        ...state,
        badgesLoader: false,
        all_badges: action.payload,
        error: "",
      };

    case GET_BADGES_BY_ID:
      return {
        ...state,
        indivisualBadgesLoader: true,
      };

    case GET_BADGES_BY_ID_SUCCESS:
      return {
        ...state,
        indivisualBadgesLoader: false,
        indivisual_badges: action.payload,
        error: "",
      };

    case GET_BADGES_ERROR:
      return {
        ...state,
        badgesLoader: false,
        indivisualBadgesLoader:false,
        error: action.payload.message,
      };
    default:
      return { ...state };
  }
};

export default badgesSession;
