import { Navigate } from "react-router-dom";
import { isAuth, isRole } from "../utils/isLogin";
import AccessDenied from "../pages/AccessDenied";

export const PrivateRoute = ({ component: RouteComponent, roles }) => {
  const user = isRole();
  const isAuthUser = isAuth();
  const userRole = user && roles.includes(user) ? true : false;

  if (isAuthUser && userRole) {
    return <RouteComponent />;
  }

  if (isAuthUser && !userRole) {
    return <AccessDenied />;
  }

  return <Navigate to="/login" />;
};
