import React from "react";
import CounterCard from "../../components/CounterCard";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { _counterCardData } from "./constant.js";

function AcademicAssessments() {
  return (
    <>
      <div className=" p-7  AcademicAssessments-main-div">
        <h5
          className="fw-bold mb-10 mt-3 page-head"
          style={{ fontSize: "24px", color: " #000000" }}
        >
          Academic Assessments
        </h5>

        <Row>
          {_counterCardData.map((text) => {
            return (
              <Col xs={12} md={6} lg={4} xl={3} className="mt-10 mt-xl-0">
                <CounterCard cardDetails={text} />
              </Col>
            );
          })}
        </Row>
        <div className="mt-7">{/* <SearchBox /> */}</div>
      </div>
    </>
  );
}

export default AcademicAssessments;
