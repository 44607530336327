import {
  ADMIN_USER,
  ADMIN_USER_SUCCESS,
  ADMIN_USER_ERROR,
  ADD_ADMIN_USER,
  ADD_ADMIN_USER_SUCCESS,
  EDIT_ADMIN_USER,
  EDIT_ADMIN_USER_SUCCESS,
  ADMIN_EMPTY,
  CREATE_NEW_PASSWORD_START,
  CREATE_NEW_PASSWORD_SUCCESS,
} from "../actions";

const INIT_STATE = {
  userLoader: false,
  adminerror: "",
  admin_details: [],
  new_users: [],
  update_user: [],
  create_password: [],
};

const adminDetailSession = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADMIN_USER:
    case ADD_ADMIN_USER:
    case EDIT_ADMIN_USER:
    case CREATE_NEW_PASSWORD_START:
      return {
        ...state,
        userLoader: true,
      };
    case ADMIN_USER_SUCCESS:
      return {
        ...state,
        userLoader: false,
        admin_details: action.payload,
        adminerror: "",
      };
    case ADD_ADMIN_USER_SUCCESS:
      return {
        ...state,
        userLoader: false,
        new_users: action.payload,
        adminerror: "",
      };
    case EDIT_ADMIN_USER_SUCCESS:
      return {
        ...state,
        userLoader: false,
        update_user: action.payload,
        adminerror: "",
      };
    case CREATE_NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        userLoader: false,
        create_password: action.payload,
        adminerror: "",
      };
    case ADMIN_USER_ERROR:
      return {
        ...state,
        userLoader: false,
        adminerror: action.payload,
      };
    case ADMIN_EMPTY:
      return {
        ...state,
        adminerror: "",
        new_users: [],
        update_user: [],
        create_password: [],
      };
    default:
      return { ...state };
  }
};

export default adminDetailSession;
