import React from "react";
import Layout from "./components/Layout";
import { BrowserRouter as Router } from "react-router-dom";
import "./theme/bootstrap.scss";
import "./theme/main.scss";
import "./App.scss";

const App = () => (
  <Router>
    <Layout />
  </Router>
);

export default App;
