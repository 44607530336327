import {
  COGNITIVE_ASSESSMENT,
  COGNITIVE_ASSESSMENT_SUCCESS,
  COGNITIVE_ASSESSMENT_COUNT,
  COGNITIVE_ASSESSMENT_COUNT_SUCCESS,
  COGNITIVE_ASSESSMENT_ERROR,
  DOWNLOAD_COGNITIVE_DATA_START,
  DOWNLOAD_COGNITIVE_DATA_SUCCESS,
  DOWNLOAD_COGNITIVE_DATA_ERROR,
  COGNITIVE_ASSESSMENT_EMPTY
} from "../actions";

const INIT_STATE = {
  cognitiveAssessmentsLoader: false,
  error: "",
  cognitive_assessments: [],
  cognitive_ass_count: {},
  cognitive_download: []
};

const cognitiveAssesSession = (state = INIT_STATE, action) => {
  switch (action.type) {
    case COGNITIVE_ASSESSMENT:
    case COGNITIVE_ASSESSMENT_COUNT:
    case DOWNLOAD_COGNITIVE_DATA_START:
      return {
        ...state,
        cognitiveAssessmentsLoader: true,
      };
    case COGNITIVE_ASSESSMENT_SUCCESS:
      return {
        ...state,
        cognitiveAssessmentsLoader: false,
        cognitive_assessments: action.payload,
        error: "",
      };
    case COGNITIVE_ASSESSMENT_COUNT_SUCCESS:
      return {
        ...state,
        cognitive_ass_count: action.payload,
        error: "",
      };
    case DOWNLOAD_COGNITIVE_DATA_SUCCESS:
      return {
        ...state,
        cognitiveAssessmentsLoader: false,
        cognitive_download: action.payload,
        error: "",
      };
    case COGNITIVE_ASSESSMENT_EMPTY:
      return {
        ...state,
        cognitive_download: [],
        error: "",
      };
    case COGNITIVE_ASSESSMENT_ERROR:
    case DOWNLOAD_COGNITIVE_DATA_ERROR:
      return {
        ...state,
        cognitiveAssessmentsLoader: false,
        error: action.payload.message,
      };
    default:
      return { ...state };
  }
};

export default cognitiveAssesSession;
