import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import XLSXICON from "../../../assets/xlsx.png";
import { studentUploadStartAction, uploadEmpty } from "../../../redux/actions";
import { options } from "../../../utils/functions/commonFunctions";

const UploadStudent = () => {
  const [file, setFile] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { uploadLoader, uploadError, upload_Student } = useSelector(
    (state) => state.uploadDetailSession
  );

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (file) {
      const base64 = await convertToBase64(file);
      dispatch(
        studentUploadStartAction({
          file: base64,
        })
      );
    }
  };

  // RESPONSE
  useEffect(() => {
    if (upload_Student.code === 200) {
      toast(upload_Student.message, options);
      dispatch(uploadEmpty());
      navigate("/app/school-dashboard");
    }
    if (uploadError) {
      toast.error(uploadError);
      dispatch(uploadEmpty());
    }
  }, [upload_Student, uploadError]);

  return (
    <div className="d-flex justify-content-center my-6 sign-in-main-div">
      <div className="w-90 w-sm-70 w-md-50 w-xl-30 pt-15">
        <div className="text-center">
          <div className="welcome-text">
            Hey there, start by uploading your students data
          </div>
          <p className="para">Please upload the file in XLSX format</p>
        </div>
        <div className="form-main-div p-10">
          <Form>
            <label
              htmlFor="fileUpload"
              className=" w-100  border rounded d-flex justify-content-center align-items-center bg-light "
              style={{ height: "124px", cursor: file ? "" : "pointer" }}
            >
              <div className="m-0 text-black">
                {file ? (
                  <div className="removexlsxbox">
                    <img src={XLSXICON} />
                  </div>
                ) : (
                  <h4>+</h4>
                )}
              </div>
            </label>
            <input
              type="file"
              id="fileUpload"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              className="d-none"
              onChange={(e) => {
                if (e?.target?.files[0]?.name?.includes(".xlsx")) {
                  setFile(e.target.files[0]);
                } else {
                  toast("Please Upload XLSX File", options);
                }
              }}
              onClick={(e) => {
                e.target.value = null;
              }}
              disabled={file}
            />
            {file ? (
              <p className="xlsxremove" onClick={() => setFile()}>
                Remove
              </p>
            ) : null}

            <Button
              className="bg-primary w-100 mt-7"
              type="submit"
              onClick={handleSubmit}
              disabled={!file || uploadLoader}
            >
              {uploadLoader ? "Uploading..." : "Upload"}
            </Button>
            <Button
              className="bg-primary w-100 mb-7 mt-3"
              type="button"
              onClick={() => {
                window.location.href =
                  "https://noema.s3.ap-south-1.amazonaws.com/studentsample.xlsx";
              }}
            >
              Download Sample XLSX
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default UploadStudent;
