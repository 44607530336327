import {
  LOGIN_ADMIN_USER_START,
  LOGIN_ADMIN_USER_SUCCESS,
  LOGIN_ADMIN_USER_ERROR,
  INDIVIDUAL_ADMIN_DETAILS,
  INDIVIDUAL_ADMIN_DETAILS_SUCCESS,
  ADMIN_LOGOUT,
} from "../actions";

const INIT_STATE = {
  userLoader: false,
  error: "",
  loginstate: [],
  admin: {},
};

const adminLoginSession = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_ADMIN_USER_START:
    case INDIVIDUAL_ADMIN_DETAILS:
      return {
        ...state,
        userLoader: true,
      };
    case LOGIN_ADMIN_USER_SUCCESS:
      return {
        ...state,
        userLoader: false,
        loginstate: action.payload,
        error: "",
      };
    case LOGIN_ADMIN_USER_ERROR:
      return {
        ...state,
        userLoader: false,
        error: action.payload.message,
      };
    case INDIVIDUAL_ADMIN_DETAILS_SUCCESS:
      return {
        ...state,
        userLoader: false,
        admin: action.payload,
        error: "",
      };
    case ADMIN_LOGOUT:
      return {
        ...state,
        userLoader: false,
        loginstate: [],
        error: "",
      };
    default:
      return { ...state };
  }
};

export default adminLoginSession;
