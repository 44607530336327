import * as yup from "yup";

export const downloadInitialValue = {
  start_date: "",
  end_date: "",
};

export const downloadValidationSchema = yup.object({
  start_date: yup.date().required("Field is required"),
  end_date: yup
    .date()
    .min(yup.ref("start_date"), "End date can't be before Start date")
    .required("Field is required"),
});
