import React, { useEffect, useState } from "react";
import {
  SearchBox,
  CustomTable,
  CustomModal,
  DeleteModal,
  CustomButton,
  SchoolAdminForm,
} from "../../components";
import { columns } from "./utils";
import { Pagination } from "@mui/material";
import { useSelector } from "react-redux";
import {
  commonEmptySuccess,
  getSchoolAdminStart,
  schoolSubadminEmpty,
} from "../../redux/actions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { options } from "../../utils/functions/commonFunctions";
import { isRole, isSchoolCode, isUserId } from "../../utils/isLogin";

const SchoolSubAdmins = () => {
  const dispatch = useDispatch();
  const role = isRole();
  const userId = isUserId();
  const schoolCode = isSchoolCode();

  const [show, setShow] = useState(false); // ADD EDIT MODAL
  const [SchoolDetails, setSchoolDetails] = useState([]); // ALL SCHOOL SUBADMIN DATA

  const [deleteModalOpen, setDeleteModalOpen] = useState(false); // DELETE MODAL
  const [adminID, setAdminID] = useState(); // SET ID FOR DELETE MODAL

  const [modalpermission, setModalPermission] = useState(false); // MODAL PERMISSION FOR ADD EDIT MODAL
  const [userOnEdit, setUserOnEdit] = useState(); // SINGLE USER DATA FOR EDIT MODAL

  const [filterSchool, setFilterSchool] = useState(""); // FILTER INPUT VALUE

  const [FilterPagination, setFilterPagination] = useState(false); // PAGINATION COUNT CHANGES
  const [x_count, setx_count] = useState(0); // PAGINATION COUNT
  const [pagenumber, setPagenumber] = useState(1); // PAGINATION PAGE NUMBER

  const {
    subadminloader,
    subadmin,
    add_subadmin,
    edit_subadmin,
    subadminerror,
  } = useSelector((state) => state.schoolAdminSession); // GET DATA FROM REDUX STORE

  // COMMON REDUCERS
  const {
    commonloader,
    generate_pass,
    status_change,
    delete_data,
    commonerror,
  } = useSelector((state) => state.commonSession);

  // INITIAL LOAD STUDENT
  const InitialLoad = () => {
    dispatch(
      getSchoolAdminStart({
        code: schoolCode,
        page: pagenumber,
        filter: filterSchool,
      })
    );
  };

  // FILTER FUNCTION
  const handleFilter = (value) => {
    if (value.length > 0) {
      setFilterPagination(true);
    } else {
      setFilterPagination(false);
    }
    setFilterSchool(value);
    setPagenumber(1);
  };

  // PAGINATION CHANGE PAGE NUMBER
  const handlepagination = (event, value) => {
    setPagenumber(value);
  };

  // MODAL OPEN CLOSE FUNCTION
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setModalPermission(false);
  };
  // CLOSE MODAL
  const handleCloseDeleteModal = () => setDeleteModalOpen(false);

  // INITIAL CALL
  useEffect(() => {
    InitialLoad();
  }, [pagenumber, filterSchool]);

  // SET NEW STUDENT DATA TO STATE
  useEffect(() => {
    if (subadmin) {
      setSchoolDetails(subadmin?.data);
      setx_count(subadmin?.count);
    }
  }, [subadmin]);

  useEffect(() => {
    // SUBADMIN ADD
    if (add_subadmin.code === 200) {
      toast(add_subadmin.message, options);
      InitialLoad();
      handleClose();
      dispatch(schoolSubadminEmpty());
    }
    // SUBADMIN UPDATE
    if (edit_subadmin.code === 200) {
      toast(edit_subadmin.message, options);
      InitialLoad();
      handleClose();
      dispatch(schoolSubadminEmpty());
    }
    // SUBADMIN DELETE
    if (delete_data.code === 200) {
      toast(delete_data.message, options);
      InitialLoad();
      dispatch(commonEmptySuccess());
    }
    // GENERATE PASSWORD
    if (generate_pass.code === 200) {
      toast(generate_pass.message, options);
      InitialLoad();
      dispatch(commonEmptySuccess());
    }
    // STATUS CHANGE
    if (status_change.code === 200) {
      toast(status_change.message, options);
      InitialLoad();
      dispatch(commonEmptySuccess());
    }
    // IF ANY ERROR
    if (commonerror) {
      toast.error(commonerror);
      dispatch(commonEmptySuccess());
    }
    if (subadminerror) {
      toast.error(subadminerror);
      dispatch(schoolSubadminEmpty());
    }
  }, [
    add_subadmin,
    edit_subadmin,
    subadminerror,
    generate_pass,
    status_change,
    delete_data,
    commonerror,
  ]);

  return (
    <>
      <div className="text-center">
        <div className="p-7 SchoolSubAdmins-main-div">
          <div className="d-flex justify-content-between align-item-center">
            <div className="SchoolSubAdmins-text page-head">
              Manage School-Admins
            </div>
            <div>
              <CustomButton
                className="first-button"
                handleClick={handleShow}
                type="button"
                text="Add An Sub-Admin"
              />
            </div>
          </div>
          <div className="mt-7">
            <SearchBox
              InputplaceHolder="Search School"
              DropDown={false}
              handleFilter={handleFilter}
            />
          </div>
          <CustomTable
            data={SchoolDetails}
            columns={columns({
              setDeleteModalOpen,
              pagenumber,
              setShow,
              setUserOnEdit,
              setModalPermission,
              setAdminID,
              dispatch,
            })}
            loader={subadminloader || commonloader}
          />
          <div
            style={{
              height: "100px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Pagination
              count={
                FilterPagination
                  ? 1
                  : x_count > 10
                  ? Math.ceil(x_count / 10)
                  : 1
              }
              variant="outlined"
              shape="rounded"
              onChange={handlepagination}
              disabled={subadminloader}
            />
          </div>
          <div className="modal-main-div">
            <CustomModal show={show} handleClose={handleClose}>
              <SchoolAdminForm
                handleClose={handleClose}
                modalpermission={modalpermission}
                editUserDetails={userOnEdit}
                loader={subadminloader}
              />
            </CustomModal>
            <CustomModal
              show={deleteModalOpen}
              handleClose={handleCloseDeleteModal}
            >
              <DeleteModal onHide={handleCloseDeleteModal} adminID={adminID} />
            </CustomModal>
          </div>
        </div>
      </div>
    </>
  );
};

export default SchoolSubAdmins;
