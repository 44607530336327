import React from "react";
import InputError from "../../InputError";
import { Form } from "react-bootstrap";

const InputTextGroup = ({
  label,
  type,
  name,
  placeholder,
  onChange,
  error,
  ref,
  classNameLabel,
  classNameControl,
  classNameGroup,
  ...rest
}) => {
  return (
    <Form.Group className={classNameGroup}>
      <Form.Label className={classNameLabel} ref={ref}>
        {label}
      </Form.Label>
      <Form.Control
        {...rest}
        className={classNameControl}
        type={type}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
      />
      <InputError error={error} />
    </Form.Group>
  );
};

export default InputTextGroup;
