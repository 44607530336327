import React, { useEffect, useState } from "react";
import {
  parseAddSchool,
  parseUpdateSchool,
  schoolInitialValue,
  schoolValidationSchema,
} from "./utils";
import { useDispatch } from "react-redux";
import { addSchoolStart, editSchoolStart } from "../../redux/School/actions";
import { useFormik } from "formik";
import Cut from "../../assets/Image/ManageSubAdmins/cut.svg";
import CustomButton from "../Common/CustomButton";
import InputGroup from "../InputGroup";

const SchoolForm = ({
  handleClose,
  modalpermission,
  editUserDetails,
  loader,
}) => {
  const dispatch = useDispatch();
  const [para, setPara] = useState({
    title: "Add School",
    button: "Add User",
  });

  const onSubmit = async (values) => {
    if (!modalpermission) {
      const addPayload = parseAddSchool(values);
      dispatch(addSchoolStart(addPayload));
    } else if (modalpermission) {
      const editPayload = parseUpdateSchool(values, editUserDetails);
      dispatch(editSchoolStart(editPayload));
    }
  };

  useEffect(() => {
    // IF MODALPERMISSION TRUE USER WANT TO EDIT || IF MODALPERMISSION FALSE USER WANT TO ADD
    if (modalpermission) {
      const field = [
        "school_name",
        "school_code",
        "board",
        "city",
        "state",
        "email",
        "phone",
        "password",
      ];
      const value = [
        editUserDetails?.school_name,
        editUserDetails?.school_code,
        editUserDetails?.board,
        editUserDetails?.city,
        editUserDetails?.state,
        editUserDetails?.email,
        editUserDetails?.phone,
        "nullvalue",
      ];
      setPara({
        title: "Update School",
        button: "Update School",
      });
      for (let [index, fields] of field.entries()) {
        formik.setFieldValue(fields, value[index]);
      }
    } else {
      const field = [
        "school_name",
        "school_code",
        "board",
        "city",
        "state",
        "email",
        "phone",
        "password",
      ];
      setPara({
        title: "Add School",
        button: "Add School",
      });
      for (let fields of field) {
        formik.setFieldValue(fields, "");
      }
    }
  }, [modalpermission]); // CHECK IF USER WANT TO EDIT USER

  const formik = useFormik({
    initialValues: schoolInitialValue,
    onSubmit,
    validationSchema: schoolValidationSchema,
  }); // FORMIK
  return (
    <>
      <div className=" p-10 ">
        <div className="d-flex justify-content-end">
          <div onClick={handleClose} style={{ cursor: "pointer" }}>
            <img src={Cut} alt="" />
          </div>
        </div>

        <div className="modal-title-text text-center">{para.title}</div>
        <form onSubmit={formik.handleSubmit}>
          <InputGroup
            label="School Name"
            type="text"
            name="school_name"
            placeholder="School Name"
            {...formik.getFieldProps("school_name")}
            onChange={formik.handleChange}
            error={formik.touched.school_name && formik.errors.school_name}
          />
          <InputGroup
            label="School Code"
            type="number"
            name="school_code"
            placeholder="School Code"
            {...formik.getFieldProps("school_code")}
            onChange={formik.handleChange}
            error={formik.touched.school_code && formik.errors.school_code}
          />
          <InputGroup
            label="Board"
            type="text"
            name="board"
            placeholder="Board"
            {...formik.getFieldProps("board")}
            onChange={formik.handleChange}
            error={formik.touched.board && formik.errors.board}
          />
          <InputGroup
            label="City"
            type="text"
            name="city"
            placeholder="City"
            {...formik.getFieldProps("city")}
            onChange={formik.handleChange}
            error={formik.touched.city && formik.errors.city}
          />
          <InputGroup
            label="State"
            type="text"
            name="state"
            placeholder="State"
            {...formik.getFieldProps("state")}
            onChange={formik.handleChange}
            error={formik.touched.state && formik.errors.state}
          />
          <InputGroup
            label="Email"
            type="text"
            name="email"
            placeholder="Email"
            {...formik.getFieldProps("email")}
            onChange={formik.handleChange}
            error={formik.touched.email && formik.errors.email}
          />
          <InputGroup
            label="Phone"
            type="number"
            name="phone"
            placeholder="Phone"
            {...formik.getFieldProps("phone")}
            onChange={formik.handleChange}
            error={formik.touched.phone && formik.errors.phone}
          />
          {!modalpermission ? (
            <InputGroup
              label="Password"
              type="password"
              name="password"
              placeholder="Password"
              {...formik.getFieldProps("password")}
              onChange={formik.handleChange}
              error={formik.touched.password && formik.errors.password}
            />
          ) : null}

          <CustomButton
            className="bg-primary border-0 py-4 text-light fw-bolder rounded w-100 my-7"
            type="submit"
            text={loader ? "Loading..." : para.button}
            disabled={loader}
          />
        </form>
      </div>
    </>
  );
};

export default SchoolForm;
