import React from "react";
import Modal from "react-bootstrap/Modal";

const Index = ({ show, handleClose, children, size }) => {
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size={size || ""}
      >
        {children}
      </Modal>
    </>
  );
};

export default React.memo(Index);
