import DeleteIcon from "../../assets/Image/Table/DeleteIcon.svg";
import EditIcon from "../../assets/Image/Table/EditIcon.svg";
import {
  commonGeneratePasswordStart,
  commonStatusChangeStart,
} from "../../redux/actions";

export const columns = ({
  dispatch,
  setShow,
  setUserOnEdit,
  setModalPermission,
  setDeleteModalOpen,
  pagenumber,
  setAdminID,
}) => {
  const columnValues = [
    {
      name: "SL No.",
      selector: (row, index) =>
        pagenumber === 1
          ? index + 1
          : index === 9
          ? `${pagenumber}0`
          : `${pagenumber - 1}${index + 1}`,
      width: "80px",
    },
    {
      name: "User ID",
      selector: (row) => row.id,
      style: {
        cursor: "default",
      },
      maxWidth: "50px",
      center: true
    },
    {
      name: "Name",
      selector: (row) => row.name,
      style: {
        cursor: "default",
      },
      width: "200px",
      center: true
    },
    {
      name: "Email",
      selector: (row) => row.email,
      style: {
        cursor: "default",
      },
      width: "250px",
      center: true
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
      sortable: true,
      style: {
        cursor: "default",
      },
      maxWidth: "150px",
      center: true
    },
    {
      name: "Generate Reset Password",
      cell: (row) => {
        return (
          <span
            style={{
              cursor: "pointer",
              backgroundColor: "#607f962e",
              borderRadius: "50px",
            }}
            onClick={() => {
              dispatch(commonGeneratePasswordStart({ email: row.email }));
            }}
            className="border-0 py-2 px-5 bg-opacity-10 table-btn"
          >
            Generate Password
          </span>
        );
      },
      center: true,
      width: "200px",
    },
    {
      name: "Status",
      selector: (row) => {
        return (
          <button
            className="border-0 py-2 px-5 bg-opacity-10 table-btn"
            style={{ backgroundColor: "#607f962e", borderRadius: "50px" }}
            onClick={() => {
              const payload = {
                id: row.id,
                data: {
                  status: row.status === 1 ? 0 : 1,
                  department: "SchoolAdmin"
                },
              };
              dispatch(commonStatusChangeStart(payload));
            }}
          >
            Active
          </button>
        );
      },
      center: true,
    },
    {
      name: "Action",
      center: true,
      cell: (row) => (
        <>
          <div className="d-flex align-items-center">
            <div
              className="mr-5"
              onClick={() => {
                setShow(true);
                setUserOnEdit(row);
                setModalPermission(true);
              }}
              style={{ cursor: "pointer" }}
            >
              <img src={EditIcon} alt="" />
            </div>
            {/* <div
              onClick={() => {
                setDeleteModalOpen(true);
                setAdminID(row.id);
              }}
              style={{ cursor: "pointer" }}
            >
              <img src={DeleteIcon} alt="" />
            </div> */}
          </div>
        </>
      ),
    },
  ];
  return columnValues;
};
