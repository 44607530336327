import {
  COMMON_GENERATE_PASSWORD_START,
  COMMON_GENERATE_PASSWORD_SUCCESS,
  COMMON_GENERATE_PASSWORD_ERROR,
  COMMON_STATUS_CHANGE_START,
  COMMON_STATUS_CHANGE_SUCCESS,
  COMMON_STATUS_CHANGE_ERROR,
  COMMON_DELETE_START,
  COMMON_DELETE_SUCCESS,
  COMMON_DELETE_ERROR,
  COMMON_EMPTY_SUCCESS,
} from "../actions";

// PASSWORD GENERATE
export const commonGeneratePasswordStart = (data) => ({
  type: COMMON_GENERATE_PASSWORD_START,
  payload: data,
});

export const commonGeneratePasswordSuccess = (data) => ({
  type: COMMON_GENERATE_PASSWORD_SUCCESS,
  payload: data,
});

export const commonGeneratePasswordSError = (message) => ({
  type: COMMON_GENERATE_PASSWORD_ERROR,
  payload: message,
});

// STATUS CHANGE
export const commonStatusChangeStart = (data) => ({
  type: COMMON_STATUS_CHANGE_START,
  payload: data,
});

export const commonStatusChangeSuccess = (data) => ({
  type: COMMON_STATUS_CHANGE_SUCCESS,
  payload: data,
});

export const commonStatusChangeError = (message) => ({
  type: COMMON_STATUS_CHANGE_ERROR,
  payload: message,
});

// DELETE
export const commonDeleteStart = (data) => ({
  type: COMMON_DELETE_START,
  payload: data,
});

export const commonDeleteSuccess = (data) => ({
  type: COMMON_DELETE_SUCCESS,
  payload: data,
});

export const commonDeleteError = (message) => ({
  type: COMMON_DELETE_ERROR,
  payload: message,
});

// EMPTY STATE
export const commonEmptySuccess = (data) => ({
  type: COMMON_EMPTY_SUCCESS,
  payload: data,
});
