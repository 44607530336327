import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Pagination } from "@mui/material";
import {
  SearchBox,
  CustomTable,
  CustomModal,
  DeleteModal,
  AdminForm,
  CustomButton,
} from "../../components";
import { adminEmptySuccess, getAdminAction } from "../../redux/admins/actions";
import { columns } from "./utils";
import { options } from "../../utils/functions/commonFunctions";
import { commonEmptySuccess } from "../../redux/actions";
import { isRole } from "../../utils/isLogin";

const ManageSubAdmins = () => {
  const dispatch = useDispatch();
  const role = isRole()

  const [show, setShow] = useState(false); // ADD EDIT MODAL
  const [userDetails, setUserDetails] = useState([]); // ALL USER DATA

  const [deleteModalOpen, setDeleteModalOpen] = useState(false); // DELETE MODAL
  const [adminID, setAdminID] = useState(); // SET ID FOR DELETE MODAL

  const [modalpermission, setModalPermission] = useState(false); // MODAL PERMISSION FOR ADD EDIT MODAL
  const [userOnEdit, setUserOnEdit] = useState(); // SINGLE USER DATA FOR EDIT MODAL

  const [filterStudent, setFilterStudent] = useState(""); // FILTER INPUT VALUE

  const [FilterPagination, setFilterPagination] = useState(false); // PAGINATION COUNT CHANGES
  const [x_count, setx_count] = useState(0); // PAGINATION COUNT
  const [pagenumber, setPagenumber] = useState(1); // PAGINATION PAGE NUMBER

  // ADMIN REDUCERS
  const { userLoader, admin_details, new_users, update_user, adminerror } =
    useSelector((state) => state.adminDetailSession);
  // COMMON REDUCERS
  const {
    commonloader,
    generate_pass,
    status_change,
    delete_data,
    commonerror,
  } = useSelector((state) => state.commonSession);

  // INITIAL LOAD WHEN PAGENUMBER AND FILTER STUDENT CHANGES
  useEffect(() => {
    initialLoad();
  }, [pagenumber, filterStudent]);

  // INITIAL LOAD
  const initialLoad = () => {
    dispatch(
      getAdminAction({
        page: pagenumber,
        filter: filterStudent,
      })
    );
  };

  // SET USER DATA AND COUNT
  useEffect(() => {
    if (admin_details) {
      setUserDetails(admin_details?.data);
      setx_count(admin_details?.count);
    }
  }, [admin_details]);

  // FILTER FUNCTION
  const handleFilter = (value) => {
    if (value.length > 0) {
      setFilterPagination(true);
    } else {
      setFilterPagination(false);
    }
    setFilterStudent(value);
    setPagenumber(1);
  };

  // MODAL OPEN CLOSE FUNCTION
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setModalPermission(false);
    setShow(false);
  };
  // CLOSE DELETE MODAL
  const handleCloseDeleteModal = () => setDeleteModalOpen(false);
  // PAGINATION FUNCTION
  const handlepagoination = (event, value) => {
    setPagenumber(value);
  };

  useEffect(() => {
    // USER ADDED
    if (new_users.code === 200) {
      toast(new_users.message, options);
      initialLoad();
      handleClose();
      dispatch(adminEmptySuccess());
    }
    // USER UPDATE
    if (update_user.code === 200) {
      toast(update_user.message, options);
      initialLoad();
      handleClose();
      dispatch(adminEmptySuccess());
    }
    // USER DELETE
    if (delete_data.code === 200) {
      toast(delete_data.message, options);
      initialLoad();
      dispatch(commonEmptySuccess());
    }
    // GENERATE PASSWORD
    if (generate_pass.code === 200) {
      toast(generate_pass.message, options);
      initialLoad();
      dispatch(commonEmptySuccess());
    }
    // STATUS CHANGE
    if (status_change.code === 200) {
      toast(status_change.message, options);
      initialLoad();
      dispatch(commonEmptySuccess());
    }
    // IF ANY ERROR
    if (commonerror) {
      toast.error(commonerror, options);
      dispatch(commonEmptySuccess());
    }
    if (adminerror) {
      toast.error(adminerror, options);
      dispatch(adminEmptySuccess());
    }
  }, [
    new_users,
    update_user,
    delete_data,
    status_change,
    generate_pass,
    commonerror,
    adminerror,
  ]);

  return (
    <>
      <div className="p-7 manage-sub-admins-main-div">
        <div className="d-flex justify-content-between align-item-center">
          <div className="Manage-text page-head">Manage Sub-Admins</div>
          <div>
            <CustomButton
              className="first-button"
              handleClick={handleShow}
              type="button"
              text="Add An Sub-Admin"
            />
          </div>
        </div>
        <div className="mt-7">
          <SearchBox
            InputplaceHolder="Search Sub-Admins"
            DropDown={false}
            handleFilter={handleFilter}
          />
        </div>
        <CustomTable
          data={userDetails === [] ? [] : userDetails}
          columns={columns({
            dispatch,
            setShow,
            setUserOnEdit,
            setModalPermission,
            setDeleteModalOpen,
            setAdminID,
            pagenumber
          })}
          loader={userLoader || commonloader}
        />
        <div
          style={{
            height: "100px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Pagination
            count={
              FilterPagination ? 1 : x_count > 10 ? Math.ceil(x_count / 10) : 1
            }
            variant="outlined"
            shape="rounded"
            onChange={handlepagoination}
            disabled={userLoader}
          />
        </div>
        <div className="modal-main-div">
          <CustomModal show={show} handleClose={handleClose}>
            <AdminForm
              editUserDetails={userOnEdit}
              modalpermission={modalpermission}
              handleClose={handleClose}
              loader={userLoader}
            />
          </CustomModal>
          <CustomModal
            show={deleteModalOpen}
            handleClose={handleCloseDeleteModal}
          >
            <DeleteModal onHide={handleCloseDeleteModal} adminID={adminID} />
          </CustomModal>
        </div>
      </div>
    </>
  );
};
export default ManageSubAdmins;
