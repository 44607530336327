/**
 * STUDENT ACTIONS
 * @start
 * @success API SUCCESS
 * @error   API ERROR
 * @empty   STATE EMPTY
 */
export const STUDENT_DETAILS = "STUDENT_DETAILS"; // STUDENT GET START
export const STUDENT_BY_ID_START = "STUDENT_BY_ID_START"; // STUDENT GET BY ID START
export const STUDENT_DOWNLOAD_START = "STUDENT_DOWNLOAD_START"; // STUDENT DOWNLOAD START
export const STUDENT_TRAINING_START = "STUDENT_TRAINING_START"; //STUDENT TRAINING START
export const STUDENT_TRAINING_SUCCESS = "STUDENT_TRAINING_SUCCESS";
export const STUDENT_STATUS_UPDATE = "STUDENT_STATUS_UPDATE"; // STUDENT STATUS UPDATE START
export const ADD_STUDENT_START = "ADD_STUDENT_START"; // STUDENT ADD START
export const REGISTER_STUDENT_START = "REGISTER_STUDENT_START";
export const DELETE_STUDENT_DETAILS_START = "DELETE_STUDENT_DETAILS"; // STUDENT DELETE START
export const STUDENT_FEEBACK_START = "STUDENT_FEEBACK_START"; // STUDENT FEEDBACK UPDATE START
export const STUDENT_UPDATE_START = "STUDENT_UPDATE_START"; // STUDENT UPDATE STARTc
export const STUDENT_COMPLETED_ASSESSMENT_START =
  "STUDENT_COMPLETED_ASSESSMENT_START";
export const STUDENT_UNBLOCK_ASSESSMENT_START =
  "STUDENT_UNBLOCK_ASSESSMENT_START";

export const STUDENT_DETAILS_SUCCESS = "STUDENT_DETAILS_SUCCESS"; // STUDENT GET SUCCESS
export const STUDENT_STATUS_UPDATE_SUCCESS = "STUDENT_STATUS_UPDATE_SUCCESS"; // STUDENT STATUS UPDATE SUCCESS
export const STUDENT_BY_ID_SUCCESS = "STUDENT_BY_ID_SUCCESS"; // STUDENT GET ID SUCCESS
export const STUDENT_DOWNLOAD_SUCCESS = "STUDENT_DOWNLOAD_SUCCESS"; // STUDENT DOWNLOAD SUCCESS
export const STUDENT_FEEBACK_SUCCESS = "STUDENT_FEEBACK_SUCCESS"; // STUDENT FEEDBACK SUCCESS
export const ADD_STUDENT_SUCCESS = "ADD_STUDENT_SUCCESS"; // STUDENT DELETE SUCCESS
export const REGISTER_STUDENT_SUCCESS = "REGISTER_STUDENT_SUCCESS";
export const DELETE_STUDENT_DETAILS_SUCCESS = "DELETE_STUDENT_DETAILS_SUCCESS"; // STUDENT UPDATE SUCCESS
export const STUDENT_UPDATE_SUCCESS = "STUDENT_UPDATE_SUCCESS"; // STUDENT UPDATE SUCCESS
export const STUDENT_COMPLETED_ASSESSMENT_SUCCESS =
  "STUDENT_COMPLETED_ASSESSMENT_SUCCESS";
export const STUDENT_UNBLOCK_ASSESSMENT_SUCCESS =
  "STUDENT_UNBLOCK_ASSESSMENT_SUCCESS";

export const STUDENT_ERROR = "STUDENT_ERROR"; // STUDENT ERROR
export const STUDENT_EMPTY = "STUDENT_EMPTY"; // STUDENT EMPTY
export const STUDENT_RESET_PASSWORD_EMPTY = "STUDENT_RESET_PASSWORD_EMPTY"; // STUDENT RESET PASSWORD EMPTY

// RESET PASSWORD GENERATE ( ANOTHER REDUCER IMPLIMENTED )
export const STUDENT_RESET_PASSWORD_START = "STUDENT_RESET_PASSWORD_START"; // STUDENT REST PASSWORD START
export const STUDENT_RESET_PASSWORD_SUCCESS = "STUDENT_RESET_PASSWORD_SUCCESS"; // STUDENT REST PASSWORD SUCCESS
export const STUDENT_RESET_PASSWORD_ERROR = "STUDENT_RESET_PASSWORD_ERROR"; // STUDENT REST PASSWORD ERROR

/**
 * COGNITIVE ACTIONS
 * @start
 * @success API SUCCESS
 * @error   API ERROR
 * @empty   STATE EMPTY
 */
export const COGNITIVE_ASSESSMENT = "COGNITIVE_ASSESSMENT"; // COGNITIVE ASSESMENTS GET START
export const COGNITIVE_ASSESSMENT_COUNT = "COGNITIVE_ASSESSMENT_COUNT"; // COGNITIVE ASSESMENTS COUNT START
export const DOWNLOAD_COGNITIVE_DATA_START = "DOWNLOAD_COGNITIVE_DATA_START"; // COGNITIVE ASSESMENTS DOWNLOAD START

export const COGNITIVE_ASSESSMENT_SUCCESS = "COGNITIVE_ASSESSMENT_SUCCESS"; // COGNITIVE ASSESMENTS GET SUCCESS
export const COGNITIVE_ASSESSMENT_COUNT_SUCCESS =
  "COGNITIVE_ASSESSMENT_COUNT_SUCCESS"; // COGNITIVE ASSESMENTS COUNT SUCCESS
export const DOWNLOAD_COGNITIVE_DATA_SUCCESS =
  "DOWNLOAD_COGNITIVE_DATA_SUCCESS"; // COGNITIVE ASSESMENTS DOWNLOAD SUCCESS

export const COGNITIVE_ASSESSMENT_ERROR = "COGNITIVE_ASSESSMENT_ERROR"; // COGNITIVE ERROR
export const DOWNLOAD_COGNITIVE_DATA_ERROR = "DOWNLOAD_COGNITIVE_DATA_ERROR"; // NEED TO BE CLEAR
export const COGNITIVE_ASSESSMENT_EMPTY = "COGNITIVE_ASSESSMENT_EMPTY"; // NEED TO BE CLEAR

/**
 * ADMIN ACTIONS
 * @start
 * @success API SUCCESS
 * @error   API ERROR
 * @empty   STATE EMPTY
 */
export const ADMIN_USER = "ADMIN_USER"; // ADMIN GET START
export const INDIVIDUAL_ADMIN_DETAILS = "INDIVIDUAL_ADMIN_DETAILS"; // ADMIN GET BY ID START
export const ADD_ADMIN_USER = "ADD_ADMIN_USER"; // ADMIN ADD START
export const EDIT_ADMIN_USER = "EDIT_ADMIN_USER"; // ADMIN EDIT START
export const DELETE_ADMIN_USER_START = "DELETE_ADMIN_USER_START"; // ADMIN DELETE START

export const ADMIN_USER_SUCCESS = "ADMIN_USER_SUCCESS"; // ADMIN GET SUCCESS
export const ADD_ADMIN_USER_SUCCESS = "ADD_ADMIN_USER_SUCCESS"; // ADMIN ADD SUCCESS
export const EDIT_ADMIN_USER_SUCCESS = "EDIT_ADMIN_USER_SUCCESS"; // ADMIN EDIT SUCCESS
export const INDIVIDUAL_ADMIN_DETAILS_SUCCESS =
  "INDIVIDUAL_ADMIN_DETAILS_SUCCESS"; // ADMIN GET BY ID SUCCESS
export const DELETE_ADMIN_USER_SUCCESS = "DELETE_ADMIN_USER_SUCCESS"; // ADMIN DELETE SUCCESS

export const ADMIN_USER_ERROR = "ADMIN_USER_ERROR"; // ADMIN ERROR
export const ADMIN_EMPTY = "ADMIN_EMPTY"; // ADMIN EMPTY

/**
 * LOGIN ACTIONS
 * @start
 * @success API SUCCESS
 * @error   API ERROR
 * @empty   STATE EMPTY
 */
export const LOGIN_ADMIN_USER_START = "LOGIN_ADMIN_USER_START"; // LOGIN START
export const ADMIN_LOGOUT = "ADMIN_LOGOUT"; // LOGOUT
export const LOGIN_ADMIN_USER_SUCCESS = "LOGIN_ADMIN_USER_SUCCESS"; // LOGIN SUCCESS
export const LOGIN_ADMIN_USER_ERROR = "LOGIN_ADMIN_USER_ERROR"; // ERROR

/**
 * SCHOOL ACTIONS
 * @start
 * @success API SUCCESS
 * @error   API ERROR
 * @empty   STATE EMPTY
 */
export const GET_SCHOOL_LIST = "GET_SCHOOL_LIST";
export const GET_SCHOOL_LIST_SUCCESS = "GET_SCHOOL_LIST_SUCCESS";
export const GET_SCHOOLS_START = "GET_SCHOOLS_START";
export const ADD_SCHOOLS_START = "ADD_SCHOOLS_START";
export const EDIT_SCHOOLS_START = "EDIT_SCHOOLS_START";

export const GET_SCHOOLS_SUCCESS = "GET_SCHOOLS_SUCCESS";
export const ADD_SCHOOLS_SUCCESS = "ADD_SCHOOLS_SUCCESS";
export const EDIT_SCHOOLS_SUCCESS = "EDIT_SCHOOLS_SUCCESS";

/**
 * CREATE NEW PASSWORD ACTIONS ADMIN SIDE
 * @start
 * @success API SUCCESS
 * @error   API ERROR
 * @empty   STATE EMPTY
 */
export const CREATE_NEW_PASSWORD_START = "CREATE_NEW_PASSWORD_START"; // GENERATE START ADMIN PASSWORD CHANGE UI
export const CREATE_NEW_PASSWORD_SUCCESS = "CREATE_NEW_PASSWORD_SUCCESS"; // GENERATE SUCCESS ADMIN PASSWORD CHANGE UI

/**
 * SCHOOL ACTIONS
 * @start
 * @success API SUCCESS
 * @error   API ERROR
 * @empty   STATE EMPTY
 */
export const GET_SCHOOL_SUBADMIN_START = "GET_SCHOOL_SUBADMIN_START"; // SCHOOL GET
export const ADD_SCHOOL_SUBADMIN_START = "ADD_SCHOOL_SUBADMIN_START";
export const EDIT_SCHOOL_SUBADMIN_START = "EDIT_SCHOOL_SUBADMIN_START";
export const SCHOOL_DASHBOARD_START = "SCHOOL_DASHBOARD_START";
export const SCHOOL_STUDENT_START = "SCHOOL_STUDENT_START";

export const GET_SCHOOL_SUBADMIN_SUCCESS = "GET_SCHOOL_SUBADMIN_SUCCESS";
export const ADD_SCHOOL_SUBADMIN_SUCCESS = "ADD_SCHOOL_SUBADMIN_SUCCESS";
export const EDIT_SCHOOL_SUBADMIN_SUCCESS = "EDIT_SCHOOL_SUBADMIN_SUCCESS";
export const SCHOOL_DASHBOARD_SUCCESS = "SCHOOL_DASHBOARD_SUCCESS";
export const SCHOOL_STUDENT_SUCCESS = "SCHOOL_STUDENT_SUCCESS";

export const SCHOOLS_ERROR = "SCHOOLS_ERROR"; // SCHOOL ERROR
export const SCHOOL_EMPTY = "SCHOOL_EMPTY"; // SCHOOL EMPTY

export const SCHOOLS_SUBADMIN_ERROR = "SCHOOLS_SUBADMIN_ERROR"; // SCHOOL SUBADMIN ERROR
export const SCHOOL_SUBADMIN_EMPTY = "SCHOOL_SUBADMIN_EMPTY"; // SCHOOL SUBADMIN EMPTY

/**
 * UPLOAD VIA EXCEL ACTION
 * @start
 * @success API SUCCESS
 * @error   API ERROR
 * @empty   STATE EMPTY
 */
export const STUDENT_UPLOAD_EXCEL_START = "STUDENT_UPLOAD_EXCEL_START";
export const STUDENT_UPLOAD_EXCEL_SUCCESS = "STUDENT_UPLOAD_EXCEL_SUCCESS";
export const STUDENT_UPLOAD_EXCEL_ERROR = "STUDENT_UPLOAD_EXCEL_ERROR";

export const UPLOAD_EMPTY = "UPLOAD_EMPTY"; // UPLOAD EMPTY

/**
 * COMMON GENERATE PASSWORD FOR ADMIN SIDES ACTIONS
 * @start
 * @success API SUCCESS
 * @error   API ERROR
 * @empty   STATE EMPTY
 */
export const COMMON_GENERATE_PASSWORD_START = "COMMON_GENERATE_PASSWORD_START";
export const COMMON_GENERATE_PASSWORD_SUCCESS =
  "COMMON_GENERATE_PASSWORD_SUCCSS";
export const COMMON_GENERATE_PASSWORD_ERROR = "COMMON_GENERATE_PASSWORD_ERROR";

/**
 * COMMON STATUS CHANGE ACTIONS
 * @start
 * @success API SUCCESS
 * @error   API ERROR
 * @empty   STATE EMPTY
 */
export const COMMON_STATUS_CHANGE_START = "COMMON_STATUS_CHANGE_START";
export const COMMON_STATUS_CHANGE_SUCCESS = "COMMON_STATUS_CHANGE_SUCCSS";
export const COMMON_STATUS_CHANGE_ERROR = "COMMON_STATUS_CHANGE_ERROR";

/**
 * COMMON DELETE ACTIONS
 * @start
 * @success API SUCCESS
 * @error   API ERROR
 * @empty   STATE EMPTY
 */
export const COMMON_DELETE_START = "COMMON_DELETE_START";
export const COMMON_DELETE_SUCCESS = "COMMON_DELETE_SUCCSS";
export const COMMON_DELETE_ERROR = "COMMON_DELETE_ERROR";
export const COMMON_EMPTY_SUCCESS = "COMMON_EMPTY_SUCCSS"; // COMMON EMPTY

// Badges Deatils

export const GET_BADGES = `GET_BADGES`;
export const GET_BADGES_SUCCESS = `${GET_BADGES}_SUCCESS`;
export const GET_BADGES_ERROR = `${GET_BADGES}_ERROR`;

export const GET_BADGES_BY_ID = `GET_BADGES_BY_ID`;
export const GET_BADGES_BY_ID_SUCCESS = `${GET_BADGES_BY_ID}_SUCCESS`;
export const GET_BADGES_BY_ID_ERROR = `${GET_BADGES_BY_ID}_ERROR`;

export * from "./admins/actions";
export * from "./cognitive-assessments/actions";
export * from "./login/actions";
export * from "./studentDetails/actions";
export * from "./studentResetPassword/actions";
export * from "./SchoolSubAdmin/action";
export * from "./UploadViaExcel/StudentUpload/action";
export * from "./common/action";
export * from "./badges/actions";
