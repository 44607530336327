import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import {
  GET_SCHOOL_SUBADMIN_START,
  ADD_SCHOOL_SUBADMIN_START,
  EDIT_SCHOOL_SUBADMIN_START,
} from "../actions";
import {
  getSchoolAdminSuccess,
  addSchoolAdminSuccess,
  editSchoolAdminSuccess,
  schoolSubadminError,
} from "./action";
import { API_PATH_LOCAL } from "../../utils/apisPath";

// GET ALL STUDENTS DETAILS
const getSchoolAdminDetailAsync = async (payload) =>
  await axios
    .get(
      API_PATH_LOCAL +
        `/school-sub-admins?schoolCode=${payload.code}&page=${payload.page}&filter=${payload.filter}`
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data.error;
    });

function* fetchSchoolAdminDetails(payload) {
  try {
    const schooladmin = yield call(getSchoolAdminDetailAsync, payload.payload);

    if (schooladmin.code === 200) {
      // WHEN STATUS CODE 200
      yield put(getSchoolAdminSuccess(schooladmin));
    } else {
      // WHEN ERROR
      yield put(schoolSubadminError(schooladmin.message));
    }
  } catch (error) {
    yield put(schoolSubadminError("Something Went Wrong"));
  }
}

// ADD SCHOOL SUB ADMIN
const addSchoolAdminAsync = async (payload) =>
  await axios
    .post(API_PATH_LOCAL + `/school-sub-admins`, payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data.error;
    });

function* addSchoolAdminSaga(payload) {
  try {
    const schooladmin = yield call(addSchoolAdminAsync, payload.payload);
    if (schooladmin.code === 200) {
      yield put(addSchoolAdminSuccess(schooladmin));
    } else {
      yield put(schoolSubadminError(schooladmin.message));
    }
  } catch {
    yield put(schoolSubadminError("Something Went Wrong"));
  }
}

// EDIT SCHOOL SUB ADMIN
const editSchoolAdminAsync = async (payload) =>
  await axios
    .patch(API_PATH_LOCAL + `/school-sub-admins/${payload.id}`, payload.data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data.error;
    });

function* editSchoolAdminSaga(payload) {
  try {
    const schooladmin = yield call(editSchoolAdminAsync, payload.payload);
    if (schooladmin.code === 200) {
      // WHEN STATUS CODE 200
      yield put(editSchoolAdminSuccess(schooladmin));
    } else {
      // WHEN ERROR
      yield put(schoolSubadminError(schooladmin.message));
    }
  } catch {
    // WHEN NETWORK ERROR
    yield put(schoolSubadminError("Something Went Wrong"));
  }
}

// GENERATER CALL FUNCTIONS
export function* watchGetAdminDetails() {
  yield takeEvery(GET_SCHOOL_SUBADMIN_START, fetchSchoolAdminDetails); // (TYPE, FUNCTION)
}
export function* watchAddAdminDetails() {
  yield takeEvery(ADD_SCHOOL_SUBADMIN_START, addSchoolAdminSaga); // (TYPE, FUNCTION)
}
export function* watchEditAdminDetails() {
  yield takeEvery(EDIT_SCHOOL_SUBADMIN_START, editSchoolAdminSaga); // (TYPE, FUNCTION)
}

// ROOT SAGA FUNCTIONS
export default function* rootSaga() {
  yield all([fork(watchGetAdminDetails)]);
  yield all([fork(watchAddAdminDetails)]);
  yield all([fork(watchEditAdminDetails)]);
}
