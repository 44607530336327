import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { GET_BADGES, GET_BADGES_BY_ID } from "../actions";
import { badgesSuccess, badgesByIdSuccess, badgesError } from "./actions";
import { API_PATH_LOCAL } from "../../utils/apisPath";
import axios from "axios";

const getBadgesDetailsAsync = async (payload) =>
  await axios
    .get(API_PATH_LOCAL + `/badges`)
    .then((res) => {
      return res.data.data;
    })
    .catch((error) => {
      return error.response.data.error;
    });

function* fetchBadgesDetails({ payload }) {
  try {
    const allBadges = yield call(getBadgesDetailsAsync, payload);
    yield put(badgesSuccess(allBadges));
  } catch (error) {
    yield put(badgesError(error));
  }
}

//Get Indivisual User Badges By Id
const getBadgesDetailsByIdAsync = async (payload) =>
  await axios
    .get(API_PATH_LOCAL + `/userEarnBadges/${payload}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response.data.error;
    });

function* fetchBadgesDetailsById({ payload }) {
  try {
    const badges = yield call(getBadgesDetailsByIdAsync, payload);
    yield put(badgesByIdSuccess(badges));
  } catch (error) {
    yield put(badgesError(error));
  }
}

export function* addBadges() {
  yield takeEvery(GET_BADGES, fetchBadgesDetails);
}

export function* earnedBadges() {
  yield takeEvery(GET_BADGES_BY_ID, fetchBadgesDetailsById);
}

export default function* rootSaga() {
  yield all([fork(addBadges)]);
  yield all([fork(earnedBadges)]);
}
