import EditIcon from '../../assets/Image/Table/EditIcon.svg';
import {
  commonGeneratePasswordStart,
  commonStatusChangeStart,
} from '../../redux/actions';

export const columns = ({
  dispatch,
  setShow,
  setUserOnEdit,
  setModalPermission,
  setAdminID,
  setDeleteModalOpen,
}) => {
  const columnValues = [
    {
      name: "School ID",
      selector: (row) => row.id,
      width: "100px",
    },
    {
      name: "School Name",
      selector: (row) => row.school_name,
      style: {
        cursor: "default",
      },
      width: "250px",
    },
    {
      name: "School Code",
      selector: (row) => row.school_code,
      style: {
        cursor: "default",
      },
      width: "150px",
    },
    {
      name: "Board",
      selector: (row) => row.board,
      style: {
        cursor: "default",
      },
    },
    {
      name: "City",
      selector: (row) => row.city,
      sortable: true,
      style: {
        cursor: "default",
      },
    },
    {
      name: "State",
      selector: (row) => row.state,
      sortable: true,
      style: {
        cursor: "default",
      },
      width: "150px",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      style: {
        cursor: "default",
      },
      width: "250px",
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
      sortable: true,
      style: {
        cursor: "default",
      },
      width: "120px",
    },
    {
      name: "Generate Reset Password",
      selector: (row) => {
        return (
          <button
            className="border-0 py-2 px-5 bg-opacity-10 table-btn"
            style={{ backgroundColor: "#607f962e", borderRadius: "50px" }}
            onClick={() =>
              dispatch(commonGeneratePasswordStart({ email: row.email }))
            }
          >
            Generate Password
          </button>
        );
      },
      center: true,
      width: "200px",
    },
    {
      name: "Status",
      selector: (row) => {
        return (
          <button
            className="border-0 py-2 px-5 bg-opacity-10 table-btn"
            style={{ backgroundColor: "#607f962e", borderRadius: "50px" }}
            onClick={() => {
              const payload = {
                id: row.id,
                data: {
                  status: row.status === 1 ? 0 : 1,
                  department: "School",
                },
              };
              dispatch(commonStatusChangeStart(payload));
            }}
          >
            {row.status === 1 ? "Active" : "Inactive"}
          </button>
        );
      },
      center: true,
    },

    {
      name: "Action",
      center: true,
      cell: (row) => (
        <>
          <div className="d-flex align-items-center">
            <div
              className="mr-5"
              onClick={() => {
                setShow(true);
                setUserOnEdit(row);
                setModalPermission(true);
              }}
              style={{ cursor: "pointer" }}
            >
              <img src={EditIcon} alt="" />
            </div>
            {/* <div
              onClick={() => {
                setDeleteModalOpen(true);
                setAdminID(row.id);
              }}
              style={{ cursor: "pointer" }}
            >
              <img src={DeleteIcon} alt="" />
            </div> */}
          </div>
        </>
      ),
    },
  ];
  return columnValues;
};
