import React from "react";
import Button from "react-bootstrap/Button";
import DeleteAlertIcon from "../../../assets/Image/Table/DeleteAlertIcon.svg";
import { useDispatch } from "react-redux";
import { deleteStudentDetailsAction } from "../../../redux/studentDetails/actions";
import { commonDeleteStart } from "../../../redux/actions";

function Index({ onHide, userid, adminID }) {
  const dispatch = useDispatch();

  const deleteUser = () => {
    if (adminID) {
      dispatch(commonDeleteStart(adminID));
      onHide();
    } else if (userid) {
      dispatch(deleteStudentDetailsAction(userid));
      onHide();
    }
  };

  return (
    <>
      <div className="p-10 ">
        <div className="d-flex justify-content-end">
          <div onClick={onHide} style={{ cursor: "pointer" }}></div>
        </div>
        <div className="rounded-circle  delete-circle-div  m-auto ">
          {" "}
          <img src={DeleteAlertIcon} alt="" />
        </div>
        <h3 className="text-center my-7 font-weight-bold">Delete?</h3>
        <h5 className="text-center font-weight-bold  my-7">
          Are you sure you want to delete
        </h5>

        <div className="d-flex justify-content-between mt-9 w-100">
          <Button className="mr-4 w-100" onClick={onHide}>
            No
          </Button>

          <Button className=" ml-4 w-100" onClick={() => deleteUser()}>
            Yes
          </Button>
        </div>
      </div>
    </>
  );
}

export default React.memo(Index);
