import {
  COGNITIVE_ASSESSMENT,
  COGNITIVE_ASSESSMENT_SUCCESS,
  COGNITIVE_ASSESSMENT_COUNT,
  COGNITIVE_ASSESSMENT_COUNT_SUCCESS,
  COGNITIVE_ASSESSMENT_ERROR,
  DOWNLOAD_COGNITIVE_DATA_START,
  DOWNLOAD_COGNITIVE_DATA_SUCCESS,
  DOWNLOAD_COGNITIVE_DATA_ERROR,
  COGNITIVE_ASSESSMENT_EMPTY,
} from "../actions";

// GET
export const cognitiveAssessmentsAction = (data) => ({
  type: COGNITIVE_ASSESSMENT,
  payload: data,
});

export const cognitiveAssessmentsSuccess = (data) => ({
  type: COGNITIVE_ASSESSMENT_SUCCESS,
  payload: data,
});

// COUNT
export const cognitiveAssessmentsCountAction = (data) => ({
  type: COGNITIVE_ASSESSMENT_COUNT,
  payload: data,
});

export const cognitiveAssessmentsCountSuccess = (data) => ({
  type: COGNITIVE_ASSESSMENT_COUNT_SUCCESS,
  payload: data,
});

// DOWNLOAD COGNITIVE DATA
export const downloadCognitiveDataStart = (data) => ({
  type: DOWNLOAD_COGNITIVE_DATA_START,
  payload: data,
});

export const downloadCognitiveDataSuccess = (data) => ({
  type: DOWNLOAD_COGNITIVE_DATA_SUCCESS,
  payload: data,
});

// COGNITIVE EMPTY
export const cognitiveEmpty = (data) => ({
  type: COGNITIVE_ASSESSMENT_EMPTY,
  payload: data,
});

// ERROR
export const cognitiveAssessmentsError = (message) => ({
  type: COGNITIVE_ASSESSMENT_ERROR,
  payload: message,
});

export const downloadCognitiveDataError = (message) => ({
  type: DOWNLOAD_COGNITIVE_DATA_ERROR,
  payload: message,
});
