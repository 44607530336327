export const getMinute = () => {
  const milliseconds = new Date().getTime();
  const second = Math.floor(Math.abs(milliseconds) / 1000);
  const minute = Math.floor(second / 60);
  return minute;
};

const getTimeUser = () => {
  const milliseconds = new Date().getTime();
  const second = Math.floor(Math.abs(milliseconds) / 1000);
  const minute = Math.floor(second / 60);
  const user = localStorage.getItem("user");
  const loginTime = localStorage.getItem("last_login");
  const time = minute - loginTime;
  return [time, user];
};

export const setLoginItems = async (data, minute) => {
  localStorage.setItem("user", true);
  localStorage.setItem("username", data.data.name);
  localStorage.setItem("department", data.data.department);
  localStorage.setItem("userId", data.data.id);
  localStorage.setItem("last_login", minute);
  localStorage.setItem("token", data.data.token);
  localStorage.setItem("schoolcode", data.data.school_code);
  localStorage.setItem(
    "loggedIn",
    JSON.stringify({
      user: true,
      username: data.data.name,
      department: data.data.department,
      userId: data.data.id,
      last_login: minute,
    })
  );
  return true;
};

export const isAuth = () => {
  const [time, user] = getTimeUser();
  if (user && time <= 60) {
    return true;
  }
  localStorage.clear();
  return false;
};

export const isRole = () => {
  const role = localStorage.getItem("department");
  return role;
};

export const isUserId = () => {
  const userId = localStorage.getItem("userId");
  return userId;
};

export const isUserName = () => {
  const role = localStorage.getItem("username");
  return role;
};

export const isLoggedInUser = () => {
  const loggedIn = localStorage.getItem("loggedIn");
  return JSON.parse(loggedIn);
};

export const isSchoolCode = () => {
  const schoolCode = localStorage.getItem("schoolcode");
  return schoolCode;
};

export const isToken = () => {
  const token = localStorage.getItem("token");
  return token;
};
