import React, { useEffect, lazy, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { addAdminAction, editAdminAction } from "../../redux/admins/actions";
import { USER_DEPARTMENT, USER_STATUS } from "../../utils/constant";
import {
  adminValidationSchema,
  InitialValues,
  parseAddData,
  parseUpdateData,
} from "./utils";
import Cut from "../../assets/Image/ManageSubAdmins/cut.svg";
import CustomButton from "../Common/CustomButton";
import InputGroup from "../InputGroup";

const AdminForm = ({
  editUserDetails,
  modalpermission,
  handleClose,
  loader,
}) => {
  const dispatch = useDispatch();
  const [para, setPara] = useState({
    title: "Add Sub-Admin",
    button: "Add User",
  });

  const onSubmit = (values, onSubmitProps) => {
    if (!modalpermission) {
      const addAdmin = parseAddData(values);
      dispatch(addAdminAction(addAdmin));
    } else {
      const updateAdmin = parseUpdateData(values, editUserDetails);
      dispatch(editAdminAction(updateAdmin));
    }
    onSubmitProps.resetForm();
  }; // ONSUBMIT FUNCTION

  useEffect(() => {
    // IF MODALPERMISSION TRUE USER WANT TO EDIT || IF MODALPERMISSION FALSE USER WANT TO ADD
    if (modalpermission) {
      // FIELDS
      const field = ["name", "email", "password", "department", "status"];
      // VALUES
      const value = [
        editUserDetails?.name,
        editUserDetails?.email,
        "nullvalue",
        editUserDetails?.department,
        editUserDetails?.status,
      ];
      setPara({
        title: "Update Sub-Admin",
        button: "Update User",
      });
      for (let [index, fields] of field.entries()) {
        formik.setFieldValue(fields, String(value[index]));
      }
    } else {
      // FIELDS
      const field = ["name", "email", "password", "department"];
      setPara({
        title: "Add Sub-Admin",
        button: "Add User",
      });
      for (let fields of field) {
        formik.setFieldValue(fields, "");
      }
    }
  }, [modalpermission]); // CHECK IF USER WANT TO EDIT USER

  const formik = useFormik({
    initialValues: InitialValues,
    onSubmit,
    validationSchema: adminValidationSchema,
  }); // FORMIK

  return (
    <>
      {" "}
      <div className=" p-10 ">
        <div className="d-flex justify-content-end">
          <div onClick={handleClose} style={{ cursor: "pointer" }}>
            <img src={Cut} alt="" />
          </div>
        </div>

        <div className="modal-title-text text-center">{para.title}</div>
        <Form onSubmit={formik.handleSubmit}>
          <InputGroup
            label="Name"
            type="text"
            name="name"
            placeholder="Name"
            {...formik.getFieldProps("name")}
            onChange={formik.handleChange}
            error={formik.touched.name && formik.errors.name}
          />
          <InputGroup
            label="Email"
            type="email"
            name="email"
            placeholder="Email"
            {...formik.getFieldProps("email")}
            onChange={formik.handleChange}
            error={formik.touched.email && formik.errors.email}
          />
          {!modalpermission && (
            <InputGroup
              label="Password"
              type="password"
              name="password"
              placeholder="Password"
              autoComplete="new-password"
              {...formik.getFieldProps("password")}
              onChange={formik.handleChange}
              error={formik.touched.password && formik.errors.password}
            />
          )}

          <Form.Group className="my-5">
            <Form.Label className="font-size-3 mb-1 font-weight-bold text-black flex-grow-1">
              Department
            </Form.Label>
            <Form.Control
              as="select"
              className="bg-light border rounded h-auto py-5 font-size-3 text-black"
              name="department"
              {...formik.getFieldProps("department")}
              onChange={(e) => {
                formik.setFieldValue("department", e.target.value);
              }}
            >
              <option defaultValue>Please Select</option>
              {USER_DEPARTMENT.length &&
                USER_DEPARTMENT?.map((item, key) => {
                  return (
                    <option key={key} value={item.value}>
                      {item.label}
                    </option>
                  );
                })}
            </Form.Control>
            {formik.touched.department && formik.errors.department && (
              <small className="text-danger">{formik.errors.department}</small>
            )}
          </Form.Group>

          <Form.Group className="my-5">
            <Form.Label className="font-size-3 mb-1 font-weight-bold text-black flex-grow-1">
              Status
            </Form.Label>
            <Form.Control
              as="select"
              className="bg-light border rounded h-auto py-5 font-size-3 text-black"
              name="status"
              {...formik.getFieldProps("status")}
              onChange={(e) => {
                formik.setFieldValue("status", e.target.value);
              }}
            >
              {USER_STATUS.length &&
                USER_STATUS?.map((item, key) => {
                  return (
                    <option key={key} value={item.value}>
                      {item.label}
                    </option>
                  );
                })}
            </Form.Control>
            {formik.touched.status && formik.errors.status && (
              <small className="text-danger">{formik.errors.status}</small>
            )}
          </Form.Group>
          <CustomButton
            className="bg-primary border-0 py-4 text-light fw-bolder rounded w-100 my-7"
            type="submit"
            text={loader ? "Loading..." : para.button}
            disabled={loader}
          />
        </Form>
      </div>
    </>
  );
};

export default AdminForm;
