import React, { useEffect, useMemo } from "react";
import CustomButton from "../../components/Common/CustomButton/index";
import { Col, Row } from "react-bootstrap";
import SchoolAdminDashboardCard from "../../components/SchoolAdminDashboardCard/SchoolAdminDashboardCard";
import iconbarchartblue from "../../assets/Image/DashboardPage/icon-bar-chart-blue.svg";
import iconbarchartgreen from "../../assets/Image/DashboardPage/icon-bar-chart-green.svg";
import iconbarchartred from "../../assets/Image/DashboardPage/icon-bar-chart-red.svg";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { schoolDashboardStart } from "../../redux/School/actions";
import { toast } from "react-toastify";
import { isSchoolCode, isUserId } from "../../utils/isLogin";

const SchoolAdminDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userId = isUserId();
  const schoolCode = isSchoolCode();

  const { schoolLoader, school_dash, schoolerror } = useSelector(
    (state) => state.schoolSessions
  );

  const InitialLoad = () => {
    dispatch(
      schoolDashboardStart({
        code: schoolCode,
        filter: "",
      })
    );
  };

  useEffect(() => {
    InitialLoad();
  }, []);

  useMemo(() => {
    if (schoolerror) {
      toast.error(schoolerror);
    }
  }, [schoolerror]);

  const CardItems = [
    {
      id: 1,
      icon: iconbarchartgreen,
      count: school_dash.subscribedStudent?.length || 0,
      bgColor: "#7EBE6B",
      text: "Total Subscribed Students",
      data: school_dash.subscribedStudent,
    },
    {
      id: 2,
      icon: iconbarchartblue,
      count: school_dash.preSubscribedStudent?.length || 0,
      bgColor: "#2F49D1",
      text: "Total Pre Subscribed Students",
      data: school_dash.preSubscribedStudent,
    },
    {
      id: 3,
      icon: iconbarchartblue,
      count: school_dash.postSubscribedStudent?.length || 0,
      bgColor: "#2F49D1",
      text: "Total Post Subscribed Students",
      data: school_dash.postSubscribedStudent,
    },
    {
      id: 4,
      icon: iconbarchartred,
      count: school_dash.unScbscribedStudent?.length || 0,
      bgColor: "#E0565F",
      text: "Total Unsubscribed Students",
      data: school_dash.unScbscribedStudent,
    },
  ];

  return (
    <>
      <div className="text-center">
        <div className="p-7 SchoolAdminDashboard-main-div">
          <div className="d-flex justify-content-between align-item-center">
            <div className="SchoolAdminDashboard-text page-head">Dashboard</div>
            <div>
              <CustomButton
                className="first-button"
                handleClick={() => navigate("/app/upload-student")}
                type="button"
                text="Upload Via Excel"
              />
            </div>
          </div>
          <div className="mt-7"></div>
          <Row>
            {CardItems.map(({ data, text, bgColor, count, icon }, index) => {
              return (
                <Col sx={12} md={6} className="my-6" key={index}>
                  <SchoolAdminDashboardCard
                    index={index}
                    data={data}
                    text={text}
                    bgColor={bgColor}
                    count={count}
                    icon={icon}
                    loader={schoolLoader}
                  />
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
    </>
  );
};

export default SchoolAdminDashboard;
