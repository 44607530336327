import React, {
  useEffect,
  useState,
} from 'react';

import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Pagination } from '@mui/material';

import {
  CustomButton,
  CustomModal,
  CustomTable,
  DeleteModal,
  SchoolForm,
  SearchBox,
} from '../../components';
import { commonEmptySuccess } from '../../redux/actions';
import {
  getSchoolStart,
  schoolEmptySuccess,
} from '../../redux/School/actions';
import { options } from '../../utils/functions/commonFunctions';
import { columns } from './utils';

const Schools = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const [show, setShow] = useState(false); // ADD EDIT MODAL
  const [schoolDetails, setSchoolDetails] = useState([]); // ALL SCHOOL DATA

  const [deleteModalOpen, setDeleteModalOpen] = useState(false); // DELETE MODAL
  const [adminID, setAdminID] = useState(); // SET ID FOR DELETE MODAL

  const [modalpermission, setModalPermission] = useState(false); // MODAL PERMISSION FOR ADD EDIT MODAL
  const [userOnEdit, setUserOnEdit] = useState(); // SINGLE USER DATA FOR EDIT MODAL

  const [filterSchool, setfilterSchool] = useState(""); // FILTER INPUT VALUE

  const [FilterPagination, setFilterPagination] = useState(false); // PAGINATION COUNT CHANGES
  const [x_count, setx_count] = useState(0); // PAGINATION COUNT
  const [pagenumber, setPagenumber] = useState(1); // PAGINATION PAGE NUMBER

  const {
    schoolLoader,
    school_details,
    add_school_details,
    edit_school,
    schoolerror,
  } = useSelector((state) => state.schoolSessions); // GET DATA FROM REDUX STORE

  // COMMON REDUCERS
  const {
    commonloader,
    generate_pass,
    status_change,
    delete_data,
    commonerror,
  } = useSelector((state) => state.commonSession);

  // FILTER FUNCTION
  const handleFilter = (value) => {
    if (value.length > 0) {
      setFilterPagination(true);
    } else {
      setFilterPagination(false);
    }
    setfilterSchool(value);
    setPagenumber(1);
  };

  // DELETE MODAL FUNCTION
  const handleCloseDeleteModal = () => setDeleteModalOpen(false);

  const handleAddStudent = () => {
    navigate(`/app/add-school-student`);
  }
  // MODAL OPEN CLOSE FUNCTION
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setModalPermission(false);
  };
  // PAGINATION FUNCTIONS
  const handlePagination = (event, value) => {
    setPagenumber(value);
  };

  // INITIAL GET API CALL
  const InitialLoad = () => {
    dispatch(
      getSchoolStart({
        page: pagenumber,
        filter: filterSchool,
      })
    );
  };

  // SET SCHOOLS DATA WHEN DATA AVAILIABLE
  useEffect(() => {
    if (school_details) {
      setSchoolDetails(school_details?.data);
      setx_count(school_details?.count);
    }
  }, [school_details]);

  // CALL GET API WHEN PAGE IS RENDER
  useEffect(() => {
    InitialLoad();
  }, [pagenumber, filterSchool]);

  useEffect(() => {
    // SCHOOL ADDED
    if (add_school_details.code === 200) {
      toast(add_school_details.message, options);
      InitialLoad();
      handleClose();
      dispatch(schoolEmptySuccess());
    }
    // SCHOOL UPDATE
    if (edit_school.code === 200) {
      toast(edit_school.message, options);
      InitialLoad();
      handleClose();
      dispatch(schoolEmptySuccess());
    }
    // SCHOOL DELETE
    if (delete_data.code === 200) {
      toast(delete_data.message, options);
      InitialLoad();
      dispatch(commonEmptySuccess());
    }
    // GENERATE PASSWORD
    if (generate_pass.code === 200) {
      toast(generate_pass.message, options);
      InitialLoad();
      dispatch(commonEmptySuccess());
    }
    // STATUS CHANGE
    if (status_change.code === 200) {
      toast(status_change.message, options);
      InitialLoad();
      dispatch(commonEmptySuccess());
    }
    // IF ANY ERROR
    if (commonerror) {
      toast.error(commonerror, options);
      dispatch(commonEmptySuccess());
    }
    if (schoolerror) {
      toast.error(schoolerror, options);
      dispatch(schoolEmptySuccess());
    }
  }, [
    add_school_details,
    edit_school,
    delete_data,
    status_change,
    generate_pass,
    commonerror,
    schoolerror,
  ]);

  return (
    <>
      <div className="text-center">
        <div className="p-7 school-main-div">
          <div className="d-flex justify-content-between align-item-center">
            <div className="schools-text page-head">Manage Schools</div>
            <div>
            <CustomButton
                className="first-button mr-10"
                handleClick={handleAddStudent}
                type="button"
                text="Add School Student"
              />
              <CustomButton
                className="first-button"
                handleClick={handleShow}
                type="button"
                text="Add School"
              />
            </div>
          </div>
          <div className="mt-7">
            <SearchBox
              InputplaceHolder="Search School"
              DropDown={false}
              handleFilter={handleFilter}
            />
          </div>
          <CustomTable
            data={schoolDetails}
            columns={columns({
              navigate,
              setShow,
              setUserOnEdit,
              setModalPermission,
              dispatch,
              setAdminID,
              setDeleteModalOpen,
            })}
            loader={schoolLoader || commonloader}
          />
          <div
            style={{
              height: "100px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Pagination
              count={
                FilterPagination
                  ? 1
                  : x_count > 10
                  ? Math.ceil(x_count / 10)
                  : 1
              }
              variant="outlined"
              shape="rounded"
              onChange={handlePagination}
              disabled={schoolLoader}
            />
          </div>
          <div className="modal-main-div">
            <CustomModal show={show} handleClose={handleClose}>
              <SchoolForm
                editUserDetails={userOnEdit}
                handleClose={handleClose}
                modalpermission={modalpermission}
                loader={schoolLoader}
              />
            </CustomModal>
            <CustomModal
              show={deleteModalOpen}
              handleClose={handleCloseDeleteModal}
            >
              <DeleteModal onHide={handleCloseDeleteModal} adminID={adminID} />
            </CustomModal>
          </div>
        </div>
      </div>
    </>
  );
};

export default Schools;
