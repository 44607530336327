import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import {
  COMMON_GENERATE_PASSWORD_START,
  COMMON_STATUS_CHANGE_START,
  COMMON_DELETE_START,
} from "../actions";
import {
  commonGeneratePasswordSuccess,
  commonGeneratePasswordSError,
  commonStatusChangeSuccess,
  commonStatusChangeError,
  commonDeleteSuccess,
  commonDeleteError,
} from "./action";
import { API_PATH_LOCAL } from "../../utils/apisPath";

// GENERATE EMAIL ADMIN PASSWORD CHANGE
const commonGeneratePassAsync = async (payload) =>
  await axios
    .post(API_PATH_LOCAL + `/sendAdminPasswordMail`, payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data.error;
    });

function* fetchCommonGeneratePass(payload) {
  try {
    const generate = yield call(commonGeneratePassAsync, payload.payload);
    if (generate.code === 200) {
      yield put(commonGeneratePasswordSuccess(generate));
    } else {
      yield put(commonGeneratePasswordSError(generate.message));
    }
  } catch (error) {
    yield put(commonGeneratePasswordSError("Something Went Wrong"));
  }
}

// GENERATE EMAIL ADMIN STATUS CHANGE
const commonStatusChangeAsync = async (payload) =>
  await axios
    .patch(API_PATH_LOCAL + `/inactiveAdminUser/${payload.id}`, payload.data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data.error;
    });

function* fetchCommonStatusChange(payload) {
  try {
    const status = yield call(commonStatusChangeAsync, payload.payload);
    if (status.code === 200) {
      yield put(commonStatusChangeSuccess(status));
    } else {
      yield put(commonStatusChangeError(status.message));
    }
  } catch (error) {
    yield put(commonStatusChangeError("Something Went Wrong"));
  }
}

// DELETE
const commonDeleteAsync = async (payload) =>
  await axios
    .delete(API_PATH_LOCAL + `/admin-users/${payload}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data.error;
    });

function* fetchDeleteChange(payload) {
  try {
    const deletecall = yield call(commonDeleteAsync, payload.payload);
    if (deletecall.code === 200) {
      yield put(commonDeleteSuccess(deletecall));
    } else {
      yield put(commonDeleteError(deletecall.message));
    }
  } catch (error) {
    yield put(commonDeleteError("Something Went Wrong"));
  }
}

export function* commonGeneratPassEvery() {
  yield takeEvery(COMMON_GENERATE_PASSWORD_START, fetchCommonGeneratePass);
}
export function* commonStatusChangeEvery() {
  yield takeEvery(COMMON_STATUS_CHANGE_START, fetchCommonStatusChange);
}
export function* commonDeleteEvery() {
  yield takeEvery(COMMON_DELETE_START, fetchDeleteChange);
}

export default function* rootSaga() {
  yield all([fork(commonGeneratPassEvery)]);
  yield all([fork(commonStatusChangeEvery)]);
  yield all([fork(commonDeleteEvery)]);
}
