import React from "react";
import { useNavigate } from "react-router-dom";
import "./AccessDenied.scss";

const AccessDenied = () => {
  const navigate = useNavigate();
  return (
    <div className="w-100 h-80 d-flex justify-content-center align-items-center">
      <div>
        <p className="Not404">Access Denied</p>
        <button className="btn-not" onClick={() => navigate("/")}>
          Dashboard
        </button>
      </div>
    </div>
  );
};

export default AccessDenied;
