import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { STUDENT_RESET_PASSWORD_START } from "../actions";
import { sendStudentPwdMailSuccess, sendStudentPwdMailError } from "./actions";
import { API_PATH_LOCAL } from "../../utils/apisPath";

// ADD NEW ADMIN USER
const studentResetPasswordDetailsAsync = async (payload) =>
    await axios
        .post(API_PATH_LOCAL + `/sendPasswordMail`, payload)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return error.response.data.error;
        });

function* fetchStudentResetPasswordDetails({ payload }) {
    try {
        const pwd = yield call(studentResetPasswordDetailsAsync, payload);
        yield put(sendStudentPwdMailSuccess(pwd));
    } catch (error) {
        yield put(sendStudentPwdMailError(error));
    }
}

export function* watchStudentPasswordReset() {
    yield takeEvery(STUDENT_RESET_PASSWORD_START, fetchStudentResetPasswordDetails);
}

export default function* rootSaga() {
    yield all([fork(watchStudentPasswordReset)]);
}