import axios from 'axios';
import {
  all,
  call,
  fork,
  put,
  takeEvery,
} from 'redux-saga/effects';

import { API_PATH_LOCAL } from '../../utils/apisPath';
import {
  ADD_SCHOOLS_START,
  EDIT_SCHOOLS_START,
  GET_SCHOOL_LIST,
  GET_SCHOOLS_START,
  SCHOOL_DASHBOARD_START,
  SCHOOL_STUDENT_START,
} from '../actions';
import {
  addSchoolSuccess,
  editSchoolSuccess,
  getSchoolListSuccess,
  getSchoolSuccess,
  schoolDashboardSuccess,
  schoolError,
  schoolStudentSuccess,
} from './actions';

//GET ALL SCHOOL LIST
const getSchoolListAsync = async() => 
  await axios.get(
    API_PATH_LOCAL+`/schoolsIdName`
  )
  .then((response) => {
    return response.data;
  })
  .catch((error) => {
    return error.response.data.error;
  })

function* fetchSchoolList(){
  try{
    const schools = yield call(getSchoolListAsync);
    if(schools.code === 200) {
      yield put(getSchoolListSuccess(schools));
    } else {
      yield put(schoolError(schools.message));
    }
  } catch (error) {
    yield put(schoolError("Something Went Wrong"));
  }
}

// GET ALL SCHOOL
const getSchoolDetailsAsync = async (payload) =>
  await axios
    .get(
      API_PATH_LOCAL + `/schools?page=${payload.page}&filter=${payload.filter}`
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data.error;
    });

function* fetchSchoolDetails(payload) {
  try {
    const schools = yield call(getSchoolDetailsAsync, payload.payload);
    if (schools.code === 200) {
      yield put(getSchoolSuccess(schools));
    } else {
      yield put(schoolError(schools.message));
    }
  } catch (error) {
    yield put(schoolError("Something Went Wrong"));
  }
}

// ADD SCHOOL
const addSchoolDetailsAsync = async (payload) =>
  await axios
    .post(API_PATH_LOCAL + `/schools`, payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data.error;
    });

function* fetchAddSchoolDetails(payload) {
  try {
    const addschools = yield call(addSchoolDetailsAsync, payload.payload);
    if (addschools.code === 200) {
      yield put(addSchoolSuccess(addschools));
    } else {
      yield put(schoolError(addschools.message));
    }
  } catch (error) {
    yield put(schoolError("Something Went Wrong"));
  }
}

// EDIT SCHOOL
const editSchoolDetailsAsync = async (payload) =>
  await axios
    .patch(API_PATH_LOCAL + `/schools/${payload.id}`, payload.data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data.error;
    });

function* fetcheditSchoolDetails(payload) {
  try {
    const editschool = yield call(editSchoolDetailsAsync, payload.payload);
    if (editschool.code === 200) {
      yield put(editSchoolSuccess(editschool));
    } else {
      yield put(schoolError(editschool.message));
    }
  } catch (error) {
    yield put(schoolError("Something Went Wrong"));
  }
}

// SCHOOL DASHBOARD
const schoolDashboardAsync = async (payload) =>
  await axios
    .get(
      API_PATH_LOCAL +
        `/totalstudents?schoolCode=${payload.code}&filter=${payload.filter}`
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data.error;
    });

function* fetchSchoolDashDetails({ payload }) {
  try {
    const schooldash = yield call(schoolDashboardAsync, payload);
    if (schooldash.code === 200) {
      yield put(schoolDashboardSuccess(schooldash));
    } else {
      yield put(schoolError(schooldash.message));
    }
  } catch (error) {
    yield put(schoolError("Something Went Wrong"));
  }
}

// GET SCHOOL STUDENT
const getSchoolStudentAsync = async (payload) =>
  await axios
    .get(
      API_PATH_LOCAL +
        `/schoolStudent?schoolCode=${payload.schoolcode}&page=${payload.page}&filter=${payload.filter}&type=${payload.type}`
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data.error;
    });

function* fetchSchoolStudents({ payload }) {
  try {
    const schools = yield call(getSchoolStudentAsync, payload);
    if (schools.code === 200) {
      yield put(schoolStudentSuccess(schools));
    } else {
      yield put(schoolError(schools.message));
    }
  } catch (error) {
    yield put(schoolError("Something Went Wrong"));
  }
}

export function* getSchoolDetails() {
  yield takeEvery(GET_SCHOOLS_START, fetchSchoolDetails);
}
export function* getSchoolList() {
  yield takeEvery(GET_SCHOOL_LIST, fetchSchoolList);
}
export function* addSchoolDetails() {
  yield takeEvery(ADD_SCHOOLS_START, fetchAddSchoolDetails);
}
export function* editSchoolDetails() {
  yield takeEvery(EDIT_SCHOOLS_START, fetcheditSchoolDetails);
}
export function* schoolDashDetails() {
  yield takeEvery(SCHOOL_DASHBOARD_START, fetchSchoolDashDetails);
}
export function* schoolStudentDetails() {
  yield takeEvery(SCHOOL_STUDENT_START, fetchSchoolStudents);
}

export default function* rootSaga() {
  yield all([fork(getSchoolDetails)]);
  yield all([fork(getSchoolList)]);
  yield all([fork(addSchoolDetails)]);
  yield all([fork(editSchoolDetails)]);
  yield all([fork(schoolDashDetails)]);
  yield all([fork(schoolStudentDetails)]);
}
