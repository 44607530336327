import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CounterCard from "../../components/CounterCard";
import { _counterCardData } from "./constant";
import {_dashboard_details} from "../../utils/dummyData";

function DashboardPage() {
  return (
    <div className=" p-7  dashboard-page ">
      <h5 className="fw-bold mb-7 dashboard-text page-head">Dashboard</h5>
      <Row>
        {_counterCardData.map((text, index) => {
          return (
            <Col
              xs={12}
              md={6}
              lg={4}
              xl={3}
              className="mt-10 mt-xl-0"
              key={index}
            >
              <CounterCard cardDetails={text} />
            </Col>
          );
        })}
      </Row>
      <Row className="my-10">
        {_dashboard_details.map((text, index) => {
          return (
            <Col xs={12} md={6} xl={4} className="mt-10 mt-xl-0" key={index}>
              <div className="second-card">
                <div className="title">{text.title}</div>

                {text.numAndText.map((item, index) => {
                  return (
                    <div className="num-and-text my-7" key={index}>
                      <div className="d-flex align-items-center">
                        <div className="num">{item.num}</div>
                        <div className="percent mx-4">{item.percent}</div>
                      </div>
                      <div className="text">{item.text}</div>
                    </div>
                  );
                })}
              </div>
            </Col>
          );
        })}
      </Row>
    </div>
  );
}

export default DashboardPage;
