import React, { useEffect, useState } from "react";
import IconSearch from "../../assets/Image/SearchBox/IconSearch.svg";
import { Col, Row } from "react-bootstrap";

const SearchBox = ({
  InputplaceHolder,
  DropDown,
  API,
  handleFilter,
  handleFilterdata,
}) => {
  const [search, setSearch] = useState("");

  useEffect(() => {
    handleFilter(search);
  }, [search]);

  useEffect(() => {
    if (API) {
      if (API.length !== null) {
        const filterData = API.filter((item) => {
          return item.name?.toLowerCase().includes(search.toLowerCase());
        }); // FOR SPECIFIC KEYS IN API
        handleFilterdata(filterData);
      }
    }
  }, [search]);

  return (
    <div className="bg-white " style={{ borderRadius: "10px 10px 0px 0px" }}>
      <div className="search-box-main-div">
        <div className="px-10">
          <Row>
            <Col className="d-flex align-items-center justify-content-between search-box">
              <input
                type="text"
                placeholder={InputplaceHolder}
                className="w-100"
                onChange={(e) => setSearch(e.target.value)}
              />
              <div className="mr-4">
                <img src={IconSearch} alt="" />
              </div>
            </Col>
            {DropDown && (
              <Col className="d-flex justify-content-end align-items-center">
                <button className="send-Payment-button">
                  Send Payment Reminder
                </button>
                <div className="ml-5">
                  <select name="" id="">
                    <option value="" selected disabled>
                      Class
                    </option>
                    <option value="">12</option>
                    <option value="">11</option>
                    <option value="">10</option>
                    <option value="">9</option>
                  </select>
                </div>
              </Col>
            )}
          </Row>
        </div>
      </div>
    </div>
  );
};

export default SearchBox;
