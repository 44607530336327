import axios from 'axios';
import {
  all,
  call,
  fork,
  put,
  takeEvery,
} from 'redux-saga/effects';

import { API_PATH_LOCAL } from '../../utils/apisPath';
import {
  ADD_STUDENT_START,
  DELETE_STUDENT_DETAILS_START,
  REGISTER_STUDENT_START,
  STUDENT_BY_ID_START,
  STUDENT_COMPLETED_ASSESSMENT_START,
  STUDENT_DETAILS,
  STUDENT_DOWNLOAD_START,
  STUDENT_TRAINING_START,
  STUDENT_FEEBACK_START,
  STUDENT_STATUS_UPDATE,
  STUDENT_UNBLOCK_ASSESSMENT_START,
  STUDENT_UPDATE_START,
} from '../actions';
import {
  addStudentSuccess,
  deleteStudentDetailsSuccess,
  downloadStudentDataSuccess,
  downloadTrainingDataSuccess,
  getStudentByIdSuccess,
  getStudentDeatilsSuccess,
  registerStudentSuccess,
  StudentCompletedAssessmentSuccess,
  studentError,
  studentFeedbackUpdateSuccess,
  studentStatusUpdateSuccess,
  StudentUnblockAssessmentSuccess,
  studentUpdateSuccess,
} from './actions';

// GET STUDENTS
const GET_STUDENT_DETAILS_ASYNC = async (payload) =>
  await axios
    .get(
      API_PATH_LOCAL + `/students?page=${payload.page}&filter=${payload.filter}`
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data.error;
    });

function* fetchStudentDetails(payload) {
  try {
    const student = yield call(GET_STUDENT_DETAILS_ASYNC, payload.payload);
    if (student.code === 200) {
      yield put(getStudentDeatilsSuccess(student));
    } else {
      yield put(studentError(student.message));
    }
  } catch {
    yield put(studentError("Something Went Wrong"));
  }
}

// GET STUDENT BY ID
const getStudentByIdAsync = async (ID) =>
  await axios
    .get(API_PATH_LOCAL + `/students/${ID}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data.error;
    });

function* fetchStudentDetailsID(payload) {
  try {
    const student = yield call(getStudentByIdAsync, payload.payload);
    if (student.code === 200) {
      yield put(getStudentByIdSuccess(student.data));
    } else {
      yield put(studentError(student.message));
    }
  } catch {
    yield put(studentError("Something Went Wrong"));
  }
}

// REGISTER STUDENT
const registerStudentAsync = async (payload) =>
  await axios
    .post(API_PATH_LOCAL + `/registerSchoolStudent`, payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data.error;
    });

function* registerStudentSaga(payload) {
  try {
    const student = yield call(registerStudentAsync, payload.payload);
    if (student.code === 200) {
      yield put(registerStudentSuccess(student));
    } else {
      yield put(studentError(student.message));
    }
  } catch {
    yield put(studentError("Something Went Wrong"));
  }
}

// ADD STUDENT
const addStudentAsync = async (payload) =>
  await axios
    .post(API_PATH_LOCAL + `/students`, payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data.error;
    });

function* addStudentSaga(payload) {
  try {
    const student = yield call(addStudentAsync, payload.payload);
    if (student.code === 200) {
      yield put(addStudentSuccess(student));
    } else {
      yield put(studentError(student.message));
    }
  } catch {
    yield put(studentError("Something Went Wrong"));
  }
}

// UPDATE STUDENT STATUS BY ID
const updateStudentStatusAsync = async (payload) =>
  await axios
    .patch(
      API_PATH_LOCAL + `/updateStudentStatus/${payload.student_details.id}`,
      payload
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data.error;
    });

function* fetchupdStudStatusDetail({ payload }) {
  try {
    const status = yield call(updateStudentStatusAsync, payload);
    if (student.code === 200) {
      yield put(studentStatusUpdateSuccess(status));
    } else {
      yield put(studentError(status.message));
    }
  } catch {
    yield put(studentError("Something Went Wrong"));
  }
}

// UPDATE STUDENT BY ID
const updateStudenAsync = async (payload) =>
  await axios
    .put(API_PATH_LOCAL + `/updatestudentProfile/${payload.id}`, payload.data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data.error;
    });

function* fetchUpdateStudentDetail(payload) {
  try {
    const update = yield call(updateStudenAsync, payload.payload);
    if (update.code === 200) {
      yield put(studentUpdateSuccess(update));
    } else {
      yield put(studentError(update.message));
    }
  } catch {
    yield put(studentError("Something Went Wrong"));
  }
}

// FEEDBACK UPDATE STUDENT
const feedbackUpdateStudenAsync = async (payload) =>
  await axios
    .patch(
      API_PATH_LOCAL + `/updateDetails/${payload.payload.userid}`,
      payload.payload.data
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data.error;
    });

function* fetchFeedbackUpdateStudentDetail(payload) {
  try {
    const feedback = yield call(feedbackUpdateStudenAsync, payload);
    if (feedback.code === 200) {
      yield put(studentFeedbackUpdateSuccess(feedback));
    } else {
      yield put(studentError(feedback.message));
    }
  } catch {
    yield put(studentError("Something Went Wrong"));
  }
}

// DELETE STUDENT
const deleteStudentDetailAsync = async (payload) =>
  await axios
    .delete(API_PATH_LOCAL + `/students/${payload}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data.error;
    });

function* fetchDeleteStudent(payload) {
  try {
    const deletestudent = yield call(deleteStudentDetailAsync, payload.payload);
    if (deletestudent.code === 200) {
      yield put(deleteStudentDetailsSuccess(deletestudent));
    } else {
      yield put(studentError(deletestudent.message));
    }
  } catch {
    yield put(studentError("Something Went Wrong"));
  }
}

// DOWNLOAD STUDENT
const downloadStudentDataAsync = async (payload) =>
  await axios
    .get(
      API_PATH_LOCAL +
        `/getExpDataByUserId?userid=${payload.userid}&startDate=${payload.start}&endDate=${payload.end}`
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data.error;
    });

function* fetchDownloadStudent(payload) {
  try {
    const download = yield call(downloadStudentDataAsync, payload.payload);
    if (download.code === 200) {
      yield put(downloadStudentDataSuccess(download));
    } else {
      yield put(studentError(download.message));
    }
  } catch {
    yield put(studentError("Something Went Wrong"));
  }
}

//DOWNLOAD TRAINING DATA
const downloadTrainingDataAsync = async (payload) =>
  await axios
    .get(
      API_PATH_LOCAL +
        `/getTrainingDataByUserId?userid=${payload.userid}&startDate=${payload.start}&endDate=${payload.end}`
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data.error;
    });


function* fetchTrainingStudent(payload) {
  try{
    const download = yield call(downloadTrainingDataAsync, payload.payload);
    if(download.code === 200){
      yield put(downloadTrainingDataSuccess(download));
    } else {
      yield put(studentError(download.message));
    }
  } catch {
    yield put(studentError("Something Went Wrong"));
  }
}

// COMPLETED ASSESSMENTS
const StudentCompletdAssessmentDataAsync = async (payload) =>
  await axios
    .get(API_PATH_LOCAL + `/getCompAssByUser?userId=${payload}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data.error;
    });

function* fetchCompletedAssessmentStudent(payload) {
  try {
    const completed = yield call(
      StudentCompletdAssessmentDataAsync,
      payload.payload
    );
    if (completed.code === 200) {
      yield put(StudentCompletedAssessmentSuccess(completed.data));
    } else {
      yield put(studentError(completed.message));
    }
  } catch {
    yield put(studentError("Something Went Wrong"));
  }
}

// UNBLOCK ASSESSMENTS
const StudentUnblockAssessmentDataAsync = async (payload) =>
  await axios
    .get(
      API_PATH_LOCAL +
        `/resetUserExpData?userId=${payload.userid}&assessmentId=${payload.assId}`
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data.error;
    });

function* fetchUnblockAssessmentStudent({payload}) {
  try {
    const unblock = yield call(
      StudentUnblockAssessmentDataAsync,
      payload
    );
    if (unblock.code === 200) {
      yield put(StudentUnblockAssessmentSuccess(unblock));
    } else {
      yield put(studentError(unblock.message));
    }
  } catch {
    yield put(studentError("Something Went Wrong"));
  }
}

export function* watchStudentDetails() {
  yield takeEvery(STUDENT_DETAILS, fetchStudentDetails);
}
export function* addStudentDetailsID() {
  yield takeEvery(STUDENT_BY_ID_START, fetchStudentDetailsID);
}
export function* addStudentDetails() {
  yield takeEvery(ADD_STUDENT_START, addStudentSaga);
}
export function* registerSchoolStudent(){
  yield takeEvery(REGISTER_STUDENT_START, registerStudentSaga);
}
export function* watchChangeStatus() {
  yield takeEvery(STUDENT_STATUS_UPDATE, fetchupdStudStatusDetail);
}
export function* watchUpdateStudent() {
  yield takeEvery(STUDENT_UPDATE_START, fetchUpdateStudentDetail);
}
export function* watchFeedbackUpdateStudent() {
  yield takeEvery(STUDENT_FEEBACK_START, fetchFeedbackUpdateStudentDetail);
}
export function* watchDeleteStudent() {
  yield takeEvery(DELETE_STUDENT_DETAILS_START, fetchDeleteStudent);
}
export function* watchDownloadStudent() {
  yield takeEvery(STUDENT_DOWNLOAD_START, fetchDownloadStudent);
}
export function* watchTrainingStudent() {
  yield takeEvery(STUDENT_TRAINING_START, fetchTrainingStudent);
}
export function* watchCompletedStudent() {
  yield takeEvery(
    STUDENT_COMPLETED_ASSESSMENT_START,
    fetchCompletedAssessmentStudent
  );
}
export function* watchUnblockStudent() {
  yield takeEvery(
    STUDENT_UNBLOCK_ASSESSMENT_START,
    fetchUnblockAssessmentStudent
  );
}
export default function* rootSaga() {
  yield all([fork(watchStudentDetails)]);
  yield all([fork(addStudentDetailsID)]);
  yield all([fork(watchChangeStatus)]);
  yield all([fork(addStudentDetails)]);
  yield all([fork(registerSchoolStudent)]);
  yield all([fork(watchUpdateStudent)]);
  yield all([fork(watchFeedbackUpdateStudent)]);
  yield all([fork(watchDeleteStudent)]);
  yield all([fork(watchDownloadStudent)]);
  yield all([fork(watchTrainingStudent)]);
  yield all([fork(watchCompletedStudent)]);
  yield all([fork(watchUnblockStudent)]);
}
