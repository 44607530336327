import * as yup from "yup";

export const schoolAdminInitialValue = {
  name: "",
  email: "",
  password: "",
  subadmin_dep: "",
  phone: "",
};

// SCHOOL VALIDATION
export const schoolAdminValidationSchema = yup.object({
  name: yup.string().required("Field is required"),
  email: yup
    .string()
    .email("Invalid Email Address")
    .required("Field is required"),
  password: yup
    .string()
    .required("Field is required")
    .min(8, "Must be Minimum 8 characters"),
  subadmin_dep: yup.string().required("Field is required"),
  phone: yup
    .string()
    .required("Field is required")
    .min(10, "Must be Minimum 10 digits"),
});

export const parseAddSubadmin = (values, userId) => {
  return {
    name: values.name,
    email: values.email,
    password: values.password,
    subadmin_dep: values.subadmin_dep,
    phone: Number(values.phone),
    school_code: Number(userId),
  };
};

export const parseEditSubadmin = (values, data) => {
  return {
    id: data.id,
    data: {
      name: values.name,
      email: values.email,
      subadmin_dep: values.subadmin_dep,
      phone: Number(values.phone),
    },
  };
};
