import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import {
  ADMIN_USER,
  ADD_ADMIN_USER,
  EDIT_ADMIN_USER,
  createAdminPassSuccess,
  CREATE_NEW_PASSWORD_START,
} from "../actions";
import {
  getAdminSuccess,
  addAdminSuccess,
  editAdminSuccess,
  AdminErrorAction,
} from "./actions";
import { API_PATH_LOCAL } from "../../utils/apisPath";

// GET ALL ADMIN USERS
const getAdminDetailsAsync = async (payload) =>
  await axios
    .get(
      API_PATH_LOCAL +
        `/admin-users?page=${payload.page}&filter=${payload.filter}`
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data.error;
    });

function* fetchAdminDetails(payload) {
  try {
    const users = yield call(getAdminDetailsAsync, payload.payload);
    if (users.code === 200) {
      yield put(getAdminSuccess(users));
    } else {
      yield put(AdminErrorAction(users.message));
    }
  } catch (error) {
    yield put(AdminErrorAction("Something Went Wrong"));
  }
}

// ADD NEW ADMIN USER
const addAdminDetailsAsync = async (payload) =>
  await axios
    .post(API_PATH_LOCAL + `/admin-users`, payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data.error;
    });

function* fetchAddAdminDetails({ payload }) {
  try {
    const addUsers = yield call(addAdminDetailsAsync, payload);
    if (addUsers.code === 200) {
      yield put(addAdminSuccess(addUsers));
    } else {
      yield put(AdminErrorAction(addUsers.message));
    }
  } catch {
    yield put(AdminErrorAction("Something Went Wrong"));
  }
}

// UPDATE ADMIN USER
const updateAdminDetailsAsync = async (payload) =>
  await axios
    .patch(API_PATH_LOCAL + `/admin-users/${payload.id}`, payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data.error;
    });

function* fetchUpdateAdminDetails({ payload }) {
  try {
    const updateUsers = yield call(updateAdminDetailsAsync, payload);
    if (updateUsers.code === 200) {
      yield put(editAdminSuccess(updateUsers));
    } else {
      yield put(AdminErrorAction(updateUsers.message));
    }
  } catch (error) {
    yield put(AdminErrorAction("Something Went Wrong"));
  }
}

// CREATE NEW PASSWOD
const createPassAdminDetailsAsync = async (payload) =>
  await axios
    .patch(API_PATH_LOCAL + `/reset-password-admin`, payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data.error;
    });

function* fetchCreatePassAdminDetails({ payload }) {
  try {
    const createpass = yield call(createPassAdminDetailsAsync, payload);
    if (createpass.code === 200) {
      yield put(createAdminPassSuccess(createpass));
    } else {
      yield put(AdminErrorAction(createpass.message));
    }
  } catch (error) {
    yield put(AdminErrorAction(error));
  }
}

export function* getAdminDetails() {
  yield takeEvery(ADMIN_USER, fetchAdminDetails);
}
export function* addNewAdminDetails() {
  yield takeEvery(ADD_ADMIN_USER, fetchAddAdminDetails);
}
export function* updateAdminDetails() {
  yield takeEvery(EDIT_ADMIN_USER, fetchUpdateAdminDetails);
}
export function* createpassAdminDetails() {
  yield takeEvery(CREATE_NEW_PASSWORD_START, fetchCreatePassAdminDetails);
}

export default function* rootSaga() {
  yield all([fork(getAdminDetails)]);
  yield all([fork(addNewAdminDetails)]);
  yield all([fork(updateAdminDetails)]);
  yield all([fork(createpassAdminDetails)]);
}
