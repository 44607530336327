import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Pagination } from "@mui/material";
import { options } from "../../utils/functions/commonFunctions";
import { columns } from "./utils";
import {
  SearchBox,
  CustomTable,
  CustomModal,
  CustomButton,
  FeedBack,
} from "../../components";
import {
  downloadStudentDataStart,
  downloadTrainingDataStart,
  getStudentDeatilsStart,
  studentEmpty,
} from "../../redux/studentDetails/actions";
import {
  sendStudentPwdMailAction,
  studentResetPasswordEmpty,
} from "../../redux/studentResetPassword/actions";
import { isRole, isUserId, isSchoolCode } from "../../utils/isLogin";
import { schoolStudentStart } from "../../redux/School/actions";
import OverlayTriggerTooltip from "../../components/OverlayTriggerTooltip";
import AssementDownloadModal from "../../components/AssementDownloadModal/AssementDownloadModal";

const Students = () => {
  const role = isRole();
  const userId = isUserId();
  const schoolCode = isSchoolCode();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [allStudentData, setAllStudentData] = useState([]); // ALL STUDENT DATA
  const [activeInactiveModalshow, setActiveInactiveModalshow] = useState(false); // FEEDBACK MODAL
  const [department, setDepartment] = useState(0); // DEPARTMENT
  const [filterStudent, setFilterStudent] = useState(""); // FILTER INPUT VALUES
  const [x_count, setx_count] = useState(0); // PAGINATION COUNT
  const [pagenumber, setPagenumber] = useState(1); // PAGINATION COUNT
  const [FilterPagination, setFilterPagination] = useState(false); // PAGINATION COUNT CHANGES
  const [studentFeedbackID, setStudentFeedbackID] = useState({
    userfeedback: "",
    userID: "",
  });
  const [FilterStudentSchool, setFilterStudentSchool] = useState([]);

  const [downloadAssementModal, setDownloadAssementModal] = useState(false); // DOWNLOAD ASSEMENT MODAL
  const [downloadTrainingModal, setDownloadTrainingModal] = useState(false); //DOWNLOAD TRAINING MODAL
  const [StudentUserId, setStudentUserId] = useState();

  // REDUX STATES
  const {
    studentLoader,
    allStudents,
    feedbackUpdate,
    deleteStudentdata,
    downloadStudentData,
    downloadTraningData,
    studenterror,
  } = useSelector((state) => state.studentDetailSession);
  const { schoolLoader, school_student, schoolerror } = useSelector(
    (state) => state.schoolSessions
  );
  // REDUX STATE RESET PASSWORD
  const { StudentResetPassword } = useSelector(
    (state) => state.studentResetPasswordSession
  );

  // MODAL OPEN CLOSE FUNCTION
  const studentFeedback = (row) => {
    setStudentFeedbackID({
      userfeedback: row.feedback,
      userID: row.id,
    });
    setActiveInactiveModalshow(true);
  };

  // CLOSE FEEDBACK MODAL
  const handleCloseActiveInactiveModal = () => {
    setActiveInactiveModalshow(false);
  };

  // INITIAL LOAD STUDENT
  const initialLoad = () => {
    if (role === "School" || role === "SchoolAdmin") {
      // SCHOOL STUDENTS
      dispatch(
        schoolStudentStart({
          page: 1,
          filter: "",
          schoolcode: schoolCode,
          type: location.state ? location?.state?.param : "all",
        })
      );
    } else {
      dispatch(
        getStudentDeatilsStart({
          page: pagenumber,
          filter: filterStudent,
        })
      );
    }
  };
  // PAGINATION CHANGE PAGE NUMBER
  const handlepagination = (event, value) => {
    setPagenumber(value);
  };

  // FILTER FUNCTION
  const handleFilter = (value) => {
    if (value.length > 0) {
      setFilterPagination(true);
    } else {
      setFilterPagination(false);
    }
    setFilterStudent(value);
    setPagenumber(1);
  };

  useEffect(() => {
    initialLoad();
  }, [pagenumber, filterStudent]);

  // SET NEW STUDENT DATA TO STATE
  useEffect(() => {
    if (role === "School" || role === "SchoolAdmin") {
      setAllStudentData(school_student?.data);
      setx_count(10);
    } else {
      if (allStudents) {
        setAllStudentData(allStudents?.data);
        setx_count(allStudents?.count);
      }
    }
  }, [allStudents, school_student]);

  useEffect(() => {
    // STUDENT PASSWORD RESET
    if (StudentResetPassword.code === 200) {
      toast("Email Sent to Registered Email", options);
      dispatch(studentResetPasswordEmpty());
    }
    // STUDENT FEEDBACK UPDATE
    if (feedbackUpdate.code === 200) {
      toast(feedbackUpdate.message, options);
      handleCloseActiveInactiveModal();
      dispatch(studentEmpty());
      initialLoad();
    }
    // STUDENT FEEDBACK UPDATE
    if (deleteStudentdata.code === 200) {
      toast(deleteStudentdata.message, options);
      dispatch(studentEmpty());
      initialLoad();
    }
    // STUDENT DATA DOWNLOAD
    if (downloadStudentData.code === 200) {
      toast(downloadStudentData.mesg, options);
      setDownloadAssementModal(false);
      if (downloadStudentData.data?.location) {
        window.location.href = downloadStudentData.data?.location;
      }
      dispatch(studentEmpty());
    }
    //STUDENT TRAINING DOWNLOAD
    if(downloadTraningData.code === 200) {
      toast(downloadTraningData.mesg, options);
      setDownloadTrainingModal(false);
      if(downloadTrainingModal.data?.location){
        window.location.href = downloadTraningData.data?.location;
      }
    }

    if (studenterror) {
      toast.error(studenterror, options);
      dispatch(studentEmpty());
    }
  }, [
    StudentResetPassword,
    feedbackUpdate,
    deleteStudentdata,
    downloadStudentData,
    downloadTraningData
  ]); // SUCCESS POPUP WHEN EMAIL SENT DONE FOR PASSWORD RESET

  useEffect(() => {
    const userdepartment = localStorage.getItem("department");
    const department = {
      SuperAdmin: 1,
      HelpDesk: 2,
      Scientist: 3,
      School: 4,
      SchoolAdmin: 5,
    };

    setDepartment(department[userdepartment] || null);
  }, []);

  const handleFilterdataschool = (value) => {
    setFilterStudentSchool(value);
  };

  const handleFilterSchool = (value) => {};

  return (
    <div className="p-7">
      <div className="d-flex justify-content-between align-items-center students-main-div">
        <h4 className="page-head">Students</h4>
        {department === 1 || 4 || 5 ? (
          <div className="d-flex justify-content-between align-items-center">
            {department !== 1 ? (
              <CustomButton
                className="first-button mr-2"
                type="button"
                handleClick={() => navigate("/app/upload-student")}
                text="Upload Via Excel"
              />
            ) : null}
            <CustomButton
              className="second-button mr-2"
              type="button"
              handleClick={() => initialLoad()}
              text="Refresh"
              disabled={studentLoader}
            />
            <CustomButton
              className="second-button"
              type="button"
              handleClick={() => navigate("/app/add-new-student")}
              text="Add Student"
            />
          </div>
        ) : (
          <div className="d-flex justify-content-between align-items-center">
            <OverlayTriggerTooltip content="You didn't have a permission to use this feature">
              <button className="first-button mr-5">Upload Via Excel</button>
            </OverlayTriggerTooltip>
            <OverlayTriggerTooltip content="You didn't have a permission to use this feature">
              <button className="second-button" type="button">
                Add Student
              </button>
            </OverlayTriggerTooltip>
          </div>
        )}
      </div>
      <div className="mt-7">
        {role === "School" ? (
          <SearchBox
            InputplaceHolder="Search School Student"
            DropDown={false}
            API={allStudentData}
            handleFilter={handleFilterSchool}
            handleFilterdata={handleFilterdataschool}
          />
        ) : (
          <SearchBox
            InputplaceHolder="Search Student"
            DropDown={false}
            handleFilter={handleFilter}
          />
        )}
      </div>
      <CustomTable
        data={
          FilterStudentSchool.length === 0
            ? allStudentData !== []
              ? allStudentData
              : []
            : FilterStudentSchool
        }
        // data={allStudentData}
        columns={columns({
          department,
          navigate,
          dispatch,
          sendStudentPwdMailAction,
          studentFeedback,
          downloadStudentDataStart,
          downloadTrainingDataStart,
          pagenumber,
          setDownloadAssementModal,
          setDownloadTrainingModal,
          setStudentUserId,
        })}
        loader={studentLoader || schoolLoader}
      />
      <div
        style={{
          height: "100px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Pagination
          count={
            role === "School"
              ? 1
              : FilterPagination
              ? 1
              : x_count > 10
              ? Math.ceil(x_count / 10)
              : 1
          }
          variant="outlined"
          shape="rounded"
          onChange={handlepagination}
          disabled={studentLoader}
        />
      </div>
      <CustomModal
        show={activeInactiveModalshow}
        handleClose={handleCloseActiveInactiveModal}
      >
        <FeedBack
          hide={handleCloseActiveInactiveModal}
          userid={studentFeedbackID}
          loader={studentLoader}
        />
      </CustomModal>
      <CustomModal
        show={downloadAssementModal}
        handleClose={() => setDownloadAssementModal(false)}
      >
        <AssementDownloadModal
          onHide={() => setDownloadAssementModal(false)}
          condition={{
            type: "STUDENT",
            payload: StudentUserId,
          }}
        />
      </CustomModal>
      <CustomModal
        show={downloadTrainingModal}
        handleClose={() => setDownloadTrainingModal(false)}
      >
        <AssementDownloadModal
          onHide={() => setDownloadTrainingModal(false)}
          condition={{
            type: "TRAINING",
            payload: StudentUserId,
          }}
        />
      </CustomModal>
    </div>
  );
};

export default Students;
