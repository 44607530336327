import {
  ADD_STUDENT_START,
  ADD_STUDENT_SUCCESS,
  DELETE_STUDENT_DETAILS_START,
  DELETE_STUDENT_DETAILS_SUCCESS,
  REGISTER_STUDENT_START,
  REGISTER_STUDENT_SUCCESS,
  STUDENT_BY_ID_START,
  STUDENT_BY_ID_SUCCESS,
  STUDENT_COMPLETED_ASSESSMENT_START,
  STUDENT_COMPLETED_ASSESSMENT_SUCCESS,
  STUDENT_DETAILS,
  STUDENT_DETAILS_SUCCESS,
  STUDENT_DOWNLOAD_START,
  STUDENT_TRAINING_START,
  STUDENT_DOWNLOAD_SUCCESS,
  STUDENT_TRAINING_SUCCESS,
  STUDENT_EMPTY,
  STUDENT_ERROR,
  STUDENT_FEEBACK_START,
  STUDENT_FEEBACK_SUCCESS,
  STUDENT_STATUS_UPDATE,
  STUDENT_STATUS_UPDATE_SUCCESS,
  STUDENT_UNBLOCK_ASSESSMENT_START,
  STUDENT_UNBLOCK_ASSESSMENT_SUCCESS,
  STUDENT_UPDATE_START,
  STUDENT_UPDATE_SUCCESS,
} from '../actions';

const INIT_STATE = {
  studentLoader: false,
  studenterror: "",
  studentById: [],
  allStudents: [],
  statusUpdate: {},
  studentUpdate: [],
  feedbackUpdate: [],
  deleteStudentdata: [],
  new_users: [],
  downloadStudentData: [],
  downloadTraningData: [],
  completed_assessment: [],
  unblock_assessment: [],
  registerStudent: []
};

const studentDetailSession = (state = INIT_STATE, action) => {
  switch (action.type) {
    case STUDENT_DETAILS:
    case STUDENT_BY_ID_START:
    case ADD_STUDENT_START:
    case STUDENT_STATUS_UPDATE:
    case STUDENT_UPDATE_START:
    case STUDENT_FEEBACK_START:
    case DELETE_STUDENT_DETAILS_START:
    case STUDENT_DOWNLOAD_START:
    case STUDENT_TRAINING_START:
    case STUDENT_COMPLETED_ASSESSMENT_START:
    case STUDENT_UNBLOCK_ASSESSMENT_START:
      return {
        ...state,
        studentLoader: true,
      };
    case STUDENT_DETAILS_SUCCESS:
      return {
        ...state,
        studentLoader: false,
        allStudents: action.payload,
        error: "",
      };
    case STUDENT_BY_ID_SUCCESS:
      return {
        ...state,
        studentLoader: false,
        studentById: action.payload,
        error: "",
      };
    case ADD_STUDENT_SUCCESS:
      return {
        ...state,
        studentLoader: false,
        new_users: action.payload,
        error: "",
      };
    case STUDENT_STATUS_UPDATE_SUCCESS:
      return {
        ...state,
        studentLoader: false,
        statusUpdate: action.payload,
        error: "",
      };
      case REGISTER_STUDENT_START:
        return {
          ...state,
          studentLoader:true,
          registerStudent: undefined,
          studenterror:"",
          error:""
        };
    case REGISTER_STUDENT_SUCCESS:
      return {
        ...state,
        studentLoader: false,
        registerStudent: action.payload,
        studenterror:"",
        error:""
      }
    case STUDENT_UPDATE_SUCCESS:
      return {
        ...state,
        studentLoader: false,
        studentUpdate: action.payload,
        error: "",
      };
    case STUDENT_FEEBACK_SUCCESS:
      return {
        ...state,
        studentLoader: false,
        feedbackUpdate: action.payload,
        error: "",
      };
    case DELETE_STUDENT_DETAILS_SUCCESS:
      return {
        ...state,
        studentLoader: false,
        deleteStudentdata: action.payload,
        error: "",
      };
    case STUDENT_DOWNLOAD_SUCCESS:
      return {
        ...state,
        studentLoader: false,
        downloadStudentData: action.payload,
        error: "",
      };
    case STUDENT_TRAINING_SUCCESS:
      return {
        ...state,
        studentLoader: false,
        downloadTraningData: action.payload,
        error: "",
      }
    case STUDENT_COMPLETED_ASSESSMENT_SUCCESS:
      return {
        ...state,
        studentLoader: false,
        completed_assessment: action.payload,
        error: "",
      };
    case STUDENT_UNBLOCK_ASSESSMENT_SUCCESS:
      return {
        ...state,
        studentLoader: false,
        unblock_assessment: action.payload,
        error: "",
      };
    case STUDENT_ERROR:
      return {
        ...state,
        studentLoader: false,
        studenterror: action.payload,
      };
    case STUDENT_EMPTY:
      return {
        ...state,
        studentLoader: false,
        feedbackUpdate: [],
        deleteStudentdata: [],
        studentUpdate: [],
        new_users: [],
        registerStudent: [],
        downloadStudentData: [],
        // studentById: [],
        unblock_assessment: [],
        studenterror: "",
      };
    default:
      return { ...state };
  }
};

export default studentDetailSession;
