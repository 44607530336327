import React, { useState, useEffect } from "react";
import menuToggleRight from "../../assets/Image/Sidebar/menuToggleRight.svg";
import menuToggleLeft from "../../assets/Image/Sidebar/menuToggleLeft.svg";
import CogmentorSmallLogo from "../../assets/Image/Sidebar/Cogmentor small Logo.png";
import CogmentorLogo from "../../assets/Image/Sidebar/Cogmentor Logo.png";
import { Link } from "react-router-dom";
import { isAuth, isRole, isUserName } from "../../utils/isLogin";
import { dynamicIcon, linkdashboard, RolesMenus } from "./utils";

export default function Sidebar({ openSidebar }) {
  const auth = isAuth();
  const role = isRole();
  const username = isUserName();
  const [open, setopen] = useState(false);
  const [menus, setMenus] = useState([]);
  const [userDetails, setUserDetails] = useState({
    name: "",
    department: "",
  });

  const OpenHandler = () => {
    if (window.innerWidth <= 1024) {
      setopen(false);
    } else {
      setopen(true);
      openSidebar(true);
    }
  };

  const closeHandler = () => {
    setopen(false);
    openSidebar(false);
  };

  useEffect(() => {
    if (role === "SuperAdmin") {
      setMenus(RolesMenus.SuperAdmin);
      setUserDetails({
        name: username,
        department: "Super Admin",
      });
    } else if (role === "HelpDesk") {
      setMenus(RolesMenus.HelpDesk);
      setUserDetails({
        name: username,
        department: "Help Desk",
      });
    } else if (role === "Scientist") {
      setMenus(RolesMenus.Scientist);
      setUserDetails({
        name: username,
        department: "Scientist",
      });
    } else if (role === "School") {
      setMenus(RolesMenus.School);
      setUserDetails({
        name: username,
        department: "School",
      });
    } else if (role === "SchoolAdmin") {
      setMenus(RolesMenus.SchoolAdmin);
      setUserDetails({
        name: username,
        department: "School Admin",
      });
    }
  }, []);

  return (
    <>
      <div
        className={`${
          open ? "sidebar-main-div" : "sidebar-main-div-small"
        } bg-primary h-100vh`}
      >
        <div style={{ padding: open ? "20px" : "11px" }}>
          <div className="logo-div text-center py-5">
            <img
              src={open ? CogmentorLogo : CogmentorSmallLogo}
              alt=""
              className={open ? " logo-size-two" : "logo-size-one "}
            />
          </div>

          <div className="w-100">
            <div
              className={`${
                open
                  ? "d-flex align-items-center"
                  : " d-flex position-relative align-items-center"
              } w-100`}
            >
              <div className=" w-100">
                <div className="small-line"></div>
              </div>
              <div className={`${open ? "" : "position-absolute"} `}>
                <button
                  onClick={open ? closeHandler : OpenHandler}
                  className="toogle-button m-auto"
                >
                  <img src={open ? menuToggleRight : menuToggleLeft} alt="" />
                </button>
              </div>
            </div>
          </div>

          <div className="py-10">
            {menus.map((text, index) => {
              return (
                <div
                  className={`${open ? "" : "text-center"} link-and-icon `}
                  key={index}
                >
                  <Link to={linkdashboard[text]}>
                    <div
                      className={`${
                        open ? "d-flex align-items-center" : ""
                      } mb-9 `}
                      style={{ cursor: "pointer" }}
                      key={index}
                    >
                      <div className="text-center">
                        <img
                          src={dynamicIcon[text]}
                          alt=""
                          className="menu-logo"
                        />
                      </div>
                      <div className="ml-5">
                        <span
                          className="sidebar_text_inactive nav-text"
                          style={{ display: open ? "block" : "none" }}
                        >
                          {text}
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>

        <div
          className={`${
            open ? "profile-div-open" : "profile-div-close"
          } m-auto`}
        >
          <div className="d-flex align-items-center">
            <div className="profile-img-div overflow-hidden">
              {/* <img src="" style={{ height: "40px", color: "#ffff", width: "40px" }} alt="" /> */}
            </div>
            <div style={{ display: open ? "block" : "none" }} className="ml-4">
              <div
                style={{ fontSize: "15px", color: "#ffff", lineHeight: "10px" }}
                className="font-weight-bold mt-2"
              >
                {userDetails.name}
              </div>
              <div
                style={{ fontSize: "12px", color: "rgba(255, 255, 255, 0.5)" }}
                className="pt-1"
              >
                {userDetails.department}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
