import React from "react";
import Accordion from "react-bootstrap/Accordion";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./ParentAccordion.css";

const ParentAccordion = ({ value }) => {
  return (
    <>
      <Accordion flush className="mb-5">
        <Accordion.Item eventKey="0" className="accordion-item-value">
          <Accordion.Header>
            <div className="p-0">
              <div className="all-cards-names text-start-custom">
                {value?.name ? value?.name : "N/A"} -{" "}
                <span>
                  {value?.relationship ? value?.relationship : "N/A"}
                </span>
              </div>
              <div className="all-cards-email accordian-email">
                {value?.email}
              </div>
            </div>
          </Accordion.Header>
          <Accordion.Body className="accordion-body-value">
            <Row className="align-items-center ">
              <Col lg={6} xl={3} className="mt-10 ">
                <div className=" all-texts-first">
                  {value?.extras?.qualification
                    ? value?.extras?.qualification
                    : "N/A"}
                </div>
                <div className="all-texts-second">Highest Qualification</div>
              </Col>
              <Col lg={6} xl={4} className="mt-10 ">
                <div className="text-primary font-weight-bold  ml-md-5">
                  {value?.phone_verification === 1
                    ? "Verified"
                    : "Not Verified"}
                </div>
                <div className=" all-texts-first">
                  {value?.phone ? value?.phone : "N/A"}
                </div>
                <div className="all-texts-second">Phone Number</div>
              </Col>
              <Col lg={6} xl={5} className="mt-10 ">
                <div className=" all-texts-first">
                  {value?.extras?.income ? value?.extras?.income : "N/A"}
                </div>
                <div className="all-texts-second">Income</div>
              </Col>
              <Col lg={6} xl={3} className="mt-10 ">
                <div className=" all-texts-first">
                  {value?.extras?.profession
                    ? value?.extras?.profession
                    : "N/A"}
                </div>
                <div className="all-texts-second">Profession</div>
              </Col>
              <Col lg={6} xl={3} className="mt-10">
                <div className=" all-texts-first">
                  {value?.extras?.organization
                    ? value?.extras?.organization
                    : "N/A"}
                </div>
                <div className="all-texts-second">Organization</div>
              </Col>
              <Col lg={6} xl={3} className="mt-10 ">
                <div className=" all-texts-first">
                  {value?.extras?.designation
                    ? value?.extras?.designation
                    : "N/A"}
                </div>
                <div className="all-texts-second">Designation</div>
              </Col>
              <Col lg={6} xl={3} className="mt-10">
                <div className=" all-texts-first">
                  {value?.extras?.employment_category
                    ? value?.extras?.employment_category
                    : "N/A"}
                </div>
                <div className="all-texts-second">Employment Category</div>
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

export default React.memo(ParentAccordion);
