import {
  ADD_SCHOOLS_START,
  ADD_SCHOOLS_SUCCESS,
  EDIT_SCHOOLS_START,
  EDIT_SCHOOLS_SUCCESS,
  GET_SCHOOLS_START,
  GET_SCHOOLS_SUCCESS,
  SCHOOL_DASHBOARD_START,
  SCHOOL_DASHBOARD_SUCCESS,
  SCHOOL_EMPTY,
  SCHOOL_STUDENT_START,
  SCHOOL_STUDENT_SUCCESS,
  SCHOOLS_ERROR,
} from '../actions';

const INIT_STATE = {
  schoolLoader: false,
  schoolerror: "",
  school_details: [],
  add_school_details: [],
  edit_school: [],
  school_dash: [],
  school_student: [],
};

const schoolSessions = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SCHOOLS_SUCCESS:
      return {
        ...state,
        schoolLoader: false,
        school_details: action.payload,
      };
    case GET_SCHOOLS_START:
    case ADD_SCHOOLS_START:
    case EDIT_SCHOOLS_START:
    case SCHOOL_DASHBOARD_START:
    case SCHOOL_STUDENT_START:
      return {
        ...state,
        schoolLoader: true,
      };
    case GET_SCHOOLS_SUCCESS:
      return {
        ...state,
        schoolLoader: false,
        school_details: action.payload,
      };
    case ADD_SCHOOLS_SUCCESS:
      return {
        ...state,
        schoolLoader: false,
        add_school_details: action.payload,
      };
    case EDIT_SCHOOLS_SUCCESS:
      return {
        ...state,
        schoolLoader: false,
        edit_school: action.payload,
      };
    case SCHOOL_DASHBOARD_SUCCESS:
      return {
        ...state,
        schoolLoader: false,
        school_dash: action.payload,
      };
    case SCHOOL_STUDENT_SUCCESS:
      return {
        ...state,
        schoolLoader: false,
        school_student: action.payload,
      };
    case SCHOOLS_ERROR:
      return {
        ...state,
        schoolLoader: false,
        schoolerror: action.payload,
      };
    case SCHOOL_EMPTY:
      return {
        ...state,
        schoolLoader: false,
        add_school_details: [],
        edit_school: [],
        schoolerror: ""
      };
    default:
      return { ...state };
  }
};

export default schoolSessions;
