import {
  ADD_SCHOOLS_START,
  ADD_SCHOOLS_SUCCESS,
  EDIT_SCHOOLS_START,
  EDIT_SCHOOLS_SUCCESS,
  GET_SCHOOL_LIST,
  GET_SCHOOL_LIST_SUCCESS,
  GET_SCHOOLS_START,
  GET_SCHOOLS_SUCCESS,
  SCHOOL_DASHBOARD_START,
  SCHOOL_DASHBOARD_SUCCESS,
  SCHOOL_EMPTY,
  SCHOOL_STUDENT_START,
  SCHOOL_STUDENT_SUCCESS,
  SCHOOLS_ERROR,
} from '../actions';

//GET SCHOOL LIST
export const getSchoolListStart = () => ({
  type: GET_SCHOOL_LIST
});
export const getSchoolListSuccess = () => ({
  type: GET_SCHOOL_LIST_SUCCESS
})

// GET SCHOOL DEATILS
export const getSchoolStart = (data) => ({
  type: GET_SCHOOLS_START,
  payload: data,
});
export const getSchoolSuccess = (data) => ({
  type: GET_SCHOOLS_SUCCESS,
  payload: data,
});

// ADD SCHOOL DEATILS
export const addSchoolStart = (data) => ({
  type: ADD_SCHOOLS_START,
  payload: data,
});
export const addSchoolSuccess = (data) => ({
  type: ADD_SCHOOLS_SUCCESS,
  payload: data,
});

// EDIT SCHOOL DEATILS
export const editSchoolStart = (data) => ({
  type: EDIT_SCHOOLS_START,
  payload: data,
});
export const editSchoolSuccess = (data) => ({
  type: EDIT_SCHOOLS_SUCCESS,
  payload: data,
});

// SCHOOL DASHBOARD
export const schoolDashboardStart = (data) => ({
  type: SCHOOL_DASHBOARD_START,
  payload: data,
});
export const schoolDashboardSuccess = (data) => ({
  type: SCHOOL_DASHBOARD_SUCCESS,
  payload: data,
});

// SCHOOL STUDENT
export const schoolStudentStart = (data) => ({
  type: SCHOOL_STUDENT_START,
  payload: data,
});
export const schoolStudentSuccess = (data) => ({
  type: SCHOOL_STUDENT_SUCCESS,
  payload: data,
});

// ERROR
export const schoolError = (message) => ({
  type: SCHOOLS_ERROR,
  payload: message,
});

// EMPTY
export const schoolEmptySuccess = () => ({
  type: SCHOOL_EMPTY,
  payload: [],
});
