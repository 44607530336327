import React, {
  useEffect,
  useRef,
  useState,
} from 'react';

import { MD5 } from 'crypto-js';
import { useFormik } from 'formik';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { CustomButton } from '../../../components';
import {
  BackArrow,
  LoaderSpin,
} from '../../../components/Global/ImageGlobal';
import { getSchoolListStart } from '../../../redux/School/actions';
import {
  registerStudentStart,
  studentEmpty,
} from '../../../redux/studentDetails/actions';
import { options } from '../../../utils/functions/commonFunctions';
import { isSchoolCode } from '../../../utils/isLogin';
import {
  _board_values,
  _class_values,
  _core_subjects,
  _mentor_type,
  _native_language,
  _parent_gender,
  _relationship,
  _student_category,
  _study_medium,
  initialValues,
} from './constant';
import { addStudentValidationSchema } from './utils';

export default function AddNewSchoolStudent() {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const schoolCode = isSchoolCode();
  const [schoolList, setSchoolList] = useState([]);
  const [generatedPassword, setGeneratedPassword] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const [userGender, setUserGender] = useState("");
  const [text, setText] = useState({
    save: "Save",
    title: "Add Student",
  });

  const { studentLoader, studentById, new_users, registerStudent, studenterror } =
    useSelector((state) => state.studentDetailSession);

  const handleClear = () => {
    formik.setFieldValue("student_name","")
  }
  const {
    school_details,
  } = useSelector((state) => state.schoolSessions);

  const onSubmit = async (values) => {
    // WHEN WE NEED TO CREATE A STUDENT
    console.log(values);
    let chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let passwordLength = 8;
    let g_password = "";
    setShowPassword(false);
    for (let i = 0; i <= passwordLength; i++) {
      let randomNumber = Math.floor(Math.random() * chars.length);
      g_password += chars.substring(randomNumber, randomNumber +1);
    }
    setGeneratedPassword(g_password);

    const payload = {
        name: values.student_name,
        phone: values.student_phone,
        email: values.student_email,
        gender: userGender,
        school_id: values.student_school,
        class: values.student_class,
        dob: `${values.student_dob}T06:12:59.541Z`,
        type:"student",
        password: MD5(g_password).toString(),
        verification_status: 1
    }

      dispatch(registerStudentStart(payload)); // ADD STUDENT
  };// FORM SUBMIT

  function resetForm(){
    student_form.current.reset();

    formik.setFieldValue(
      "student_name",""
    );
    formik.setFieldValue(
      "student_phone",""
    );
    formik.setFieldValue(
      "student_email",""
    );
    formik.setFieldValue(
      "student_gender",""
    );
    formik.setFieldValue(
      "student_dob",""
    );
    formik.setFieldValue(
      "student_category",""
    );
    formik.setFieldValue(
      "school_name",""
    );
    formik.setFieldValue(
      "student_school",""
    );
    formik.setFieldValue(
      "student_class",""
    );
    formik.setTouched({}, false);

  }
  useEffect(() => {
    
    if (registerStudent?.code === 200) {
      setShowPassword(true);
      toast(registerStudent.mesg, options);
      resetForm(); 
    }else{
      if (studenterror) {
      toast.error(studenterror);
      }
    }
  }, [studenterror, registerStudent])

  useEffect(() => {
    if (school_details) {
      setSchoolList(school_details?.data);
    }
  }, [school_details]);
  useEffect(() => {
    dispatch(
      getSchoolListStart()
    ); 
    dispatch(studentEmpty());
  }, []); // GET STUDENT BY ID AND CHANGE TEXT

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit,
    validationSchema: addStudentValidationSchema,
  }); // FORMIK

  // VALIDATION SCROLLING
  const student_name = useRef(null);
  const student_email = useRef(null);
  const student_gender = useRef(null);
  const student_dob = useRef(null);
  const student_phone = useRef(null);
  const student_category = useRef(null);
  const student_school = useRef(null);
  const student_class = useRef(null);
  const student_form = useRef(null);
  const name = useRef(null);
  const phone = useRef(null);
  const email = useRef(null);
  const gender = useRef(null);
  const relationship = useRef(null);

  const executeScroll = (e) => {
    e.preventDefault();
    const key = Object.keys(formik.errors)[0];
    var keyvar = eval(key);
    keyvar.current.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  };

  return (
    <div className="p-7 AddNewStudent-main-div">
      <div className="back-icon d-flex text-center">
        <img
          src={BackArrow}
          alt=""
          onClick={() => navigate(-1)}
          className="custom-click"
        />
        <p className="add-new-stu-text mb-0 ml-10">{text.title}</p>
      </div>
      {studentLoader ? (
        <>
          <div className="d-flex justify-content-center">
            <img src={LoaderSpin} alt="loader" />
          </div>
        </>
      ) : (
        <>
          <form
            onSubmit={(e) => {
              formik.handleSubmit(e);
              executeScroll(e);
            }}
            ref={student_form}
          >
           

            <div className="form-div mt-10">
              <Row>
                <Col xs={6}>
                  <h4 className=" font-weight-bold">Student Details</h4>
                  <Form.Group>
                    <div className="d-flex align-items-center">
                      <Form.Label
                        className="font-size-3 mb-1 font-weight-bold text-black flex-grow-1"
                        ref={student_name}
                      >
                        Name*
                      </Form.Label>
                    </div>
                    <Form.Control
                      className="bg-light border rounded h-auto pb-2 pt-2 font-size-3 text-black"
                      name="student_name"
                      type="text"
                      placeholder="Enter Here..."
                      {...formik.getFieldProps("student_name")}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.student_name &&
                      formik.errors.student_name && (
                        <small className="text-danger">
                          {formik.errors.student_name}
                        </small>
                      )}
                  </Form.Group>

                  <Form.Group className="position-relative my-5">
                    <Form.Label
                      className="font-size-3 mb-1 font-weight-bold text-black"
                      ref={student_email}
                    >
                      Email*
                    </Form.Label>
                    <Form.Control
                      className="bg-light border rounded h-auto pb-2 pt-2 font-size-3 text-black"
                      type="email"
                      name="student_email"
                      placeholder="Enter Here..."
                      {...formik.getFieldProps("student_email")}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.student_email &&
                      formik.errors.student_email && (
                        <small className="text-danger">
                          {formik.errors.student_email}
                        </small>
                      )}
                  </Form.Group>

                  <div>
                    <h6
                      className="font-size-3 mb-1 font-weight-bold text-black"
                      ref={student_gender}
                    >
                      Gender*
                    </h6>
                    <ToggleButtonGroup
                      name="student_gender"
                      type="radio"
                      className="w-100 bg-light border rounded toggle-button-group"
                      {...formik.getFieldProps("student_gender")}
                      onChange={(value) => {
                        setUserGender(value);
                        formik.setFieldValue("student_gender", value);
                      }}
                    >
                      <ToggleButton
                        value="Male"
                        id="malecheckbox"
                        htmlFor="malecheckbox"
                        className={`${
                          userGender === "Male"
                            ? "border-primary bg-blue-grey-10 text-primary border-width-2  "
                            : "bg-light text-gray border-0"
                        } font-weight-bold rounded font-size-3 mr-5 my-0`}
                      >
                        Male
                      </ToggleButton>
                      <ToggleButton
                        value="Female"
                        id="femalecheckbox"
                        htmlFor="femalecheckbox"
                        className={`${
                          userGender === "Female"
                            ? "border-primary bg-blue-grey-10 text-primary border-width-2"
                            : "bg-light text-gray border-0"
                        } font-weight-bold rounded font-size-3 mr-3 my-0`}
                      >
                        Female
                      </ToggleButton>
                      <ToggleButton
                        value="Others"
                        id="otherscheckbox"
                        htmlFor="otherscheckbox"
                        className={`${
                          userGender === "Others"
                            ? "border-primary bg-blue-grey-10 text-primary border-width-2"
                            : "bg-light text-gray border-0 "
                        } font-weight-bold rounded font-size-3 ml-2 my-0`}
                      >
                        Others
                      </ToggleButton>
                    </ToggleButtonGroup>
                    {formik.touched.student_gender &&
                      formik.errors.student_gender && (
                        <small className="text-danger">
                          {formik.errors.student_gender}
                        </small>
                      )}
                  </div>

                  <Form.Group className=" my-5">
                    <Form.Label
                      className="font-size-3 mb-1 font-weight-bold text-black"
                      ref={student_dob}
                    >
                      Date of Birth*
                    </Form.Label>
                    <Form.Control
                      className="bg-light border rounded h-auto pb-2 pt-2 font-size-3 text-black"
                      type="date"
                      name="student_dob"
                      placeholder="Enter Here..."
                      {...formik.getFieldProps("student_dob")}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.student_dob &&
                      formik.errors.student_dob && (
                        <small className="text-danger">
                          {formik.errors.student_dob}
                        </small>
                      )}
                  </Form.Group>

                  <Form.Group>
                    <Form.Label
                      className="font-size-3 mb-1 font-weight-bold text-black"
                      ref={student_phone}
                    >
                      Phone Number*
                    </Form.Label>
                    <Form.Group>
                      <div className="d-flex">
                        <Form.Control
                          as="select"
                          name="countryCode"
                          className="mr-4 rounded bg-light border w-auto pt-2 pb-2 h-auto"
                        >
                          <option defaultValue value="+91">
                            +91
                          </option>
                        </Form.Control>
                        <Form.Control
                          className="bg-light border rounded h-auto pb-2 pt-2 font-size-3 text-black"
                          pattern="[0-9]{10}"
                          type="tel"
                          name="student_phone"
                          placeholder="Enter Here..."
                          {...formik.getFieldProps("student_phone")}
                          onChange={formik.handleChange}
                        />
                      </div>
                      {formik.touched.student_phone &&
                      formik.errors.student_phone && (
                        <small className="text-danger">
                          {formik.errors.student_phone}
                        </small>
                      )}
                    </Form.Group>
                  </Form.Group>

                  <Form.Group className="my-5">
                    <div className="d-flex align-items-center">
                      <Form.Label
                        className="font-size-3 mb-1 font-weight-bold text-black flex-grow-1"
                        ref={student_category}
                      >
                        Category*
                      </Form.Label>
                    </div>
                    <Form.Control
                      as="select"
                      name="student_category"
                      className="rounded bg-light border h-auto pb-4 pt-4 font-size-3 pr-2"
                      {...formik.getFieldProps("student_category")}
                      onChange={formik.handleChange}
                    >
                      <option defaultValue value="">
                        Choose a category
                      </option>
                      {_student_category.map((ele, index) => {
                        return (
                          <option value={ele.value} key={index}>
                            {ele.lable}
                          </option>
                        );
                      })}
                    </Form.Control>
                    {formik.touched.student_category &&
                      formik.errors.student_category && (
                        <small className="text-danger">
                          {formik.errors.student_category}
                        </small>
                      )}
                  </Form.Group>

                  <Form.Group className="my-5">
                    <div className="d-flex align-items-center">
                      <Form.Label
                        className="font-size-3 mb-1 font-weight-bold text-black flex-grow-1"
                        ref={student_class}
                      >
                        Class*
                      </Form.Label>
                    </div>
                    <Form.Control
                      as="select"
                      name="student_class"
                      className="rounded bg-light border h-auto pb-4 pt-4 font-size-3 pr-2"
                      {...formik.getFieldProps("student_class")}
                      onChange={formik.handleChange}
                    >
                      <option defaultValue value="">
                        Choose a Class
                      </option>
                      {_class_values.map((ele, index) => {
                        return (
                          <option value={ele.value} key={index}>
                            {ele.lable}
                          </option>
                        );
                      })}
                    </Form.Control>
                    {formik.touched.student_class &&
                      formik.errors.student_class && (
                        <small className="text-danger">
                          {formik.errors.student_class}
                        </small>
                      )}
                  </Form.Group>

                  <Form.Group className="my-5">
                    <div className="d-flex align-items-center">
                      <Form.Label
                        className="font-size-3 mb-1 font-weight-bold text-black flex-grow-1"
                        ref={student_school}
                      >
                        School*
                      </Form.Label>
                    </div>
                    <Form.Control
                      as="select"
                      name="student_school"
                      className="rounded bg-light border h-auto pb-4 pt-4 font-size-3 pr-2"
                      {...formik.getFieldProps("student_school")}
                      onChange={formik.handleChange}
                    >
                      <option defaultValue value="">
                        Choose a School
                      </option>
                      {schoolList.map((ele, index) => {
                        return (
                          <option value={ele.school_id} key={index}>
                            {ele.school_name}
                          </option>
                        );
                      })}
                    </Form.Control>
                    {formik.touched.student_school &&
                      formik.errors.student_school && (
                        <small className="text-danger">
                          {formik.errors.student_school}
                        </small>
                      )}
                  </Form.Group>
                  <div className='my-10'>
                    <label className="font-weight-bold text-black">{showPassword? `Passsword: ${generatedPassword}` : ''}</label>
                  </div>
                  <div className="d-flex justify-content-center align-items-center mr-15 mb-15">
                    <div>
                      
                      <CustomButton
                        className="second-button"
                        type="submit"
                        text={text.save}
                      />
                    </div>
                  </div>
                </Col>
                <Col xs={6}>
                  <img
                    src="https://res.cloudinary.com/cogmentor/image/upload/v1635449735/ILLUSTRATION%20IMAGES/PROFILE%20PAGE/cogmentor_illustrations-03_1_unmvpw.png"
                    alt=""
                  />
                </Col>
              </Row>
            </div>
          </form>
        </>
      )}
    </div>
  );
}
