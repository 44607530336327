import * as yup from 'yup';

export const addSchoolDetails = (values) => {
  const schoolDetails = {
    school_name: values.school_name,
    board: values.school_board,
    school_code: values.school_code,
    class: values.school_class,
    section: values.school_section,
    core_subjects: values.school_core_subjects,
    study_medium: values.school_study_medium,
    city: values.school_city,
    state: values.school_state,
    pincode: values.school_pin_code,
    country: values.school_country,
  }; // SCHOOL DETAILS

  return schoolDetails;
};

export const addMentorDetails = (values) => {
  const mentorDetails = {
    name: values.mentor_name,
    type: values.mentor_type,
    email: values.mentor_email,
    phone: values.mentor_phone,
  }; // MENTOR DETAILS

  return mentorDetails;
};

export const addParentExtra = (values) => {
  const parentExtra = {
    nativeLanguage: values.parent_nativeLanguage,
    qualification: values.parent_qualification,
    income: values.parent_income,
    profession: values.parent_profession,
    organization: values.parent_organization,
    designation: values.parent_designation,
    employment_category: values.parent_employment_category,
  }; // PARENT EXTRA DETAILS

  return parentExtra;
};

export const addStudentValidationSchema = yup.object().shape({
  student_name: yup.string().required("Field is required"),
  student_phone: yup
    .number()
    .required("Field is required")
    .min(10, "Must be Minimum 10 digits"),
  student_email: yup
    .string()
    .email("Invalid Email Address")
    .required("Field is required"),
  student_dob: yup.string().required("Field is required"),
  student_category: yup.string().required("Field is required"),
  student_gender: yup.string().required("Field is required"),

  // FOR NAME
  relationship: yup.string().when("name", {
    is: (parentname) => parentname && parentname.length > 0,
    then: yup.string().required("Field is required"),
  }),
  gender: yup.string().when("name", {
    is: (parentname) => parentname && parentname.length > 0,
    then: yup.string().required("Field is required"),
  }),
  email: yup.string().when("name", {
    is: (parentname) => parentname && parentname.length > 0,
    then: yup.string().email("Invalid Email Address").required("Field is required"),
  }),
  phone: yup
    .number()
    .when("name", {
      is: (parentname) => parentname && parentname.length > 0,
      then: yup.number().required("Field is required"),
    })
    .min(10, "Must be Minimum 10 digits"),
});

// SETFILEDLS
export const setFieldsValues = [
  "student_name",
  "student_phone",
  "student_email",
  "student_gender",
  "student_dob",
  "student_category",
  "school_name",
  "school_board",
  "school_code",
  "school_class",
  "school_section",
  "school_core_subjects",
  "school_city",
  "school_state",
  "school_pin_code",
  "school_country",
  "school_study_medium",
  "mentor_name",
  "mentor_type",
  "mentor_email",
  "mentor_phone",
];
